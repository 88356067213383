/**
 * Converts an ArrayBuffer to a hex string.
 * @param buffer - The ArrayBuffer to convert
 * @returns A hex string representation
 */
export function bufferToHex(buffer: Uint8Array | ArrayBuffer): string {
    return Array.from(new Uint8Array(buffer))
        .map((byte) => byte.toString(16).padStart(2, '0'))
        .join('')
}

/**
 * Converts a hex string to an ArrayBuffer.
 * @param hex - The hex string to convert
 * @returns An ArrayBuffer
 */
export function hexToBuffer(hex: string): ArrayBuffer {
    return new Uint8Array(
        hex.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16))
    ).buffer
}
