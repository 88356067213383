import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import BookmarkLlamaDesktopIcon from '../helpers/customComponents/BookmarkLlamaDesktopIcon'
import { AlertType, ButtonTheme } from '../helpers/constants/enum'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import Modal from '../helpers/commonComponents/Modal'
import InputPassword from '../helpers/customComponents/InputPassword'
import Alert from '../helpers/customComponents/Alert'
import { XMarkIcon } from '@heroicons/react/24/outline'
import apiHelper from '../apiClient/defaultApiClient'
import {
    deriveKeys,
    generatePasswordVerifier,
} from '../helpers/encryption/encryption'
import { encryptionKeyStorage } from '../helpers/encryption/encryptionKeyPair'
import {
    runSyncEngine,
    setEncryptionPasswordMessage,
} from '../extensionCommunication/messager'
import { hexToBuffer } from '../helpers/encryption/bufferHexHelpers'
import { useNavigate } from 'react-router-dom'

interface OrganisationEncryptionInput {
    password: string
}

export default function OrganisationEncryption() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<OrganisationEncryptionInput>()

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [openConfirmationModal, setOpenConfirmationModal] =
        useState<boolean>(false)

    const onSubmit = () => {
        setOpenConfirmationModal(true)
    }

    const onSubmitModal: SubmitHandler<OrganisationEncryptionInput> = async (
        data
    ) => {
        setIsLoading(true)
        const serverSaltHex = await apiHelper.getEncryptionSetup()
        if (!serverSaltHex.serverSalt) {
            throw new Error('Server salt not found')
        }
        const serverSalt = hexToBuffer(serverSaltHex.serverSalt)
        const verifier = await generatePasswordVerifier(
            data.password,
            serverSalt
        )

        await apiHelper.postEncryptionSetup({
            body: {
                verifier: verifier,
            },
        })

        const keys = await deriveKeys(data.password, serverSalt)
        await encryptionKeyStorage.upsert(keys)
        await setEncryptionPasswordMessage(
            data.password,
            serverSaltHex.serverSalt
        ).then(() => runSyncEngine())
        navigate('/encryption-confirmation')
    }

    const renderErrorMessage = () => {
        if (errors?.password?.type === 'minLength') {
            return (
                <span
                    role="alert"
                    className="block font-semibold text-red-800 selection:bg-none"
                >
                    You need a password of at least 32 characters.
                </span>
            )
        } else {
            return (
                <span
                    className={` ${
                        errors.password
                            ? 'text-red-800 font-semibold'
                            : 'text-default'
                    }`}
                >
                    Your password must be at least 32 characters. We recommend
                    including uppercase letters, numbers, and symbols.
                </span>
            )
        }
    }

    return (
        <div className="min-h-full">
            <div className="flex justify-between items-center">
                <BookmarkLlamaDesktopIcon />

                <a href="/" aria-label="Close page, go to home screen">
                    <XMarkIcon className="w-10 h-10 hover:bg-slate-100 rounded-full hover:border border-slate-700" />
                </a>
            </div>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="h-full w-full md:h-2/3 rounded md:w-4/5 xl:w-3/5 md:p-4 text-center mx-auto"
            >
                <h1 className="text-2xl lg:text-3xl font-semibold mt-14 ">
                    Set an encryption password for your organization.
                </h1>
                <Alert
                    showAlert
                    alertType={AlertType.warning}
                    alertText={
                        <>
                            <ul className="list-disc ml-6">
                                <li className="mt-2 decimal">
                                    Anyone invited to yours organization will
                                    need this password.{' '}
                                    <strong>
                                        Bookmark Llama cannot retrieve or reset
                                        this password, so keep it in a safe
                                        place.
                                    </strong>
                                </li>
                                <li className="mt-2 ">
                                    Once enabled, all existing unencrypted
                                    folders will stop sharing and will need to
                                    be reshared.
                                </li>
                            </ul>
                        </>
                    }
                    className="mt-6 text-left"
                />

                <div className="flex flex-col w-full h-full mt-16">
                    <InputPassword
                        autoComplete="off"
                        labelText="Encryption password*"
                        register={{
                            ...register('password', {
                                required: true,
                                minLength: 32,
                            }),
                        }}
                        minLength={null}
                        showPasswordIconClass=" right-[2%] top-4"
                        containerClass={'w-full text-left'}
                        errors={errors.password}
                        isLoading={isLoading}
                        inputType="encryption-password"
                        customElement={
                            <div className="flex justify-between mt-1">
                                {renderErrorMessage()}
                            </div>
                        }
                    />
                    <Button
                        buttonText={'Next'}
                        disabled={isLoading}
                        buttonType={ButtonType.submit}
                        className="my-8 px-12 w-full sm:w-fit self-end"
                        buttonTheme={ButtonTheme.primary}
                    />
                </div>
            </form>
            <Modal
                open={openConfirmationModal}
                setOpen={setOpenConfirmationModal}
                modalTitle="You are setting an encryption password"
                children={
                    <>
                        <ul className="list-disc ml-6">
                            <li className="mt-2 decimal">
                                <strong>
                                    Bookmark Llama cannot retrieve your password
                                </strong>
                                ; it should be stored in a secure place.
                            </li>
                            <li className="mt-2 ">
                                Additionally, all existing unencrypted folders
                                will stop sharing and will need to be reshared.
                            </li>
                        </ul>
                        <span className="mt-2 block">
                            Do you want to continue?
                        </span>
                    </>
                }
                onSubmit={handleSubmit(onSubmitModal)}
                primaryButton={
                    <Button
                        buttonType={ButtonType.submit}
                        buttonText="Set password"
                        buttonTheme={ButtonTheme.primary}
                        className="px-8"
                        disabled={isLoading}
                    />
                }
                secondaryButton={
                    <Button
                        buttonType={ButtonType.button}
                        buttonText="Cancel"
                        buttonTheme={ButtonTheme.tertiary}
                        className="mt-2 px-8 sm:mr-2 sm:mt-0"
                        disabled={isLoading}
                        onClick={() => {
                            setOpenConfirmationModal(false)
                        }}
                    />
                }
            />
        </div>
    )
}
