/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostSamlDomainRequest
 */
export interface PostSamlDomainRequest {
    /**
     * Domain name for SAML authentication
     * @type {string}
     * @memberof PostSamlDomainRequest
     */
    domain: string;
}

/**
 * Check if a given object implements the PostSamlDomainRequest interface.
 */
export function instanceOfPostSamlDomainRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domain" in value;

    return isInstance;
}

export function PostSamlDomainRequestFromJSON(json: any): PostSamlDomainRequest {
    return PostSamlDomainRequestFromJSONTyped(json, false);
}

export function PostSamlDomainRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostSamlDomainRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': json['domain'],
    };
}

export function PostSamlDomainRequestToJSON(value?: PostSamlDomainRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': value.domain,
    };
}

