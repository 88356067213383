import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toastError } from '../helpers/commonComponents/toastHelper'

export default function SAMLSuccess() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [isProcessing, setIsProcessing] = useState(true)

    useEffect(() => {
        const token = searchParams.get('token')
        
        if (token) {
            // Store the session token in localStorage
            localStorage.setItem('sessionToken', token)
            
            // Navigate to the home page
            navigate('/')
        } else {
            // Handle the case where no token is provided
            toastError('No authentication token found. Please try again.')
            setIsProcessing(false)
            // Redirect to login after a short delay
            setTimeout(() => {
                navigate('/login')
            }, 2000)
        }
    }, [searchParams, navigate])

    // Simple loading state while processing the token
    return (
        <div className="flex h-screen w-full items-center justify-center">
            {isProcessing ? (
                <div className="text-center">
                    <div className="mb-4 text-2xl font-semibold text-primary">Processing your login...</div>
                    <div className="text-slate-600">Please wait while we authenticate your session.</div>
                </div>
            ) : (
                <div className="text-center">
                    <div className="mb-4 text-2xl font-semibold text-red-600">Authentication Error</div>
                    <div className="text-slate-600">Redirecting to login page...</div>
                </div>
            )}
        </div>
    )
}