import {
    Link,
    Outlet,
    useMatches,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom'
import { FormEvent, Fragment, useEffect, useMemo, useState } from 'react'
import {
    GetSharedFolderByIdRequest,
    Organisation,
    SharedFolder,
    User,
    GetSharedFolderAssignments200ResponseInner,
    GetSharedFolderAssignmentsRequest,
    PostSharedFolderDefaultPermissionRequestDefaultPermissionEnum as DefaultPermissionsEnum,
} from '../apiClient'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import {
    useCurrentFolder,
    useExtensionState,
    useGetSharedFolder,
    useOrganisation,
    useUser,
} from '../dashboard/Controller'
import apiHelper from '../apiClient/defaultApiClient'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import Modal from '../helpers/commonComponents/Modal'
import {
    ButtonTheme,
    SettingsMenuItem as MenuItem,
    SettingsMenuItem,
} from '../helpers/constants/enum'
import { extensionState } from '../extensionCommunication/messager'
import { Tooltip } from 'react-tooltip'

type ContextTypeUser = { user: User | null }
type ContextTypeCurrentSharedFolder = {
    currentFolder: SharedFolder | null
}
type ContextTypeFolderUsers = {
    folderUsers: GetSharedFolderAssignments200ResponseInner[] | []
}
type ContextTypeGetSharedFolderUsers = {
    getSharedFolderUsers: (
        request: GetSharedFolderAssignmentsRequest
    ) => GetSharedFolderAssignments200ResponseInner
}
type ContextTypeSetMenuItem = {
    setMenuItem: (menuItem: MenuItem) => void
}
type ContextTypeGetCurrentSharedFolder = {
    getSharedFolder: (folderId: GetSharedFolderByIdRequest) => SharedFolder
}
type ContextTypeOrganisation = { organisation: Organisation | null }

type ContextTypeCurrentExtensionState = {
    extensionState: extensionState | null | undefined
}

interface ModalProps {
    modalText: string
    modalTitle: string
}

export default function Settings() {
    const [menuItem, setMenuItem] = useState<MenuItem | null>(null)
    const [folderUsers, setFolderUsers] = useState<
        GetSharedFolderAssignments200ResponseInner[] | [] | null
    >([])
    const [modal, setModal] = useState<Partial<ModalProps>>({})
    const [open, setOpen] = useState<boolean>(false)

    const navigate = useNavigate()
    const { folderId } = useParams()

    const { getSharedFolder } = useGetSharedFolder()
    const { user } = useUser()
    const { currentFolder } = useCurrentFolder()
    const { organisation } = useOrganisation()
    const { extensionState } = useExtensionState()

    const matches = useMatches()
    const inviteMatch = matches.find((object) => object.id === 'invite')
    const stopSharingMatch = matches.find(
        (object) => object.id === 'stop-sharing'
    )
    const membersMatch = matches.find((object) => object.id === 'members')
    const permissionsMatch = matches.find(
        (object) => object.id === 'permissions'
    )
    const changeOwnerMatch = matches.find(
        (object) => object.id === 'change-owner'
    )
    const changeLogMatch = matches.find((object) => object.id === 'change-log')

    const getFolderCurrentUser = () => {
        return folderUsers?.find((folderUser) => folderUser.userId === user?.id)
    }

    const MenuItems = [
        { name: MenuItem.members, url: 'members' },
        { name: MenuItem.invite, url: 'invite' },
        { name: MenuItem.stopSharing, url: 'stop-sharing' },
        { name: MenuItem.permissions, url: 'permissions' },
        { name: MenuItem.changeOwner, url: 'change-owner' },
        { name: MenuItem.changeLog, url: 'change-log' },
    ]

    function classNames(...classes: any) {
        return classes.filter(Boolean).join(' ')
    }

    const getSharedFolderUsers = async (
        folderId: GetSharedFolderAssignmentsRequest
    ) => {
        let response = await apiHelper
            .getSharedFolderAssignments(folderId)
            .catch(() => {
                setFolderUsers(null)
            })
        const isUserInFolder = response?.find((id) => id.userId === user?.id)
        if (!isUserInFolder) {
            navigate('/')
        }
        if (response) setFolderUsers(response)
    }

    useEffect(() => {
        if (inviteMatch) {
            setMenuItem(MenuItem.invite)
        } else if (stopSharingMatch) {
            setMenuItem(MenuItem.stopSharing)
        } else if (membersMatch) {
            setMenuItem(MenuItem.members)
        } else if (permissionsMatch) {
            setMenuItem(MenuItem.permissions)
        } else if (changeOwnerMatch) {
            setMenuItem(MenuItem.changeOwner)
        } else if (changeLogMatch) {
            setMenuItem(MenuItem.changeLog)
        } else {
            setMenuItem(MenuItem.members)
        }
    }, [])

    useEffect(() => {
        const sharedFolderId = Number(folderId)
        getSharedFolderUsers({ sharedFolderId: sharedFolderId })
    }, [])

    // User permissions logic, display permissions page or not,
    // do not display if user does not have access or if default permissions
    // do not allow AND user has no explicit permissions setting
    const currentUserPermissions = getFolderCurrentUser()?.permission
    const isPermissionsPageDisabled =
        currentUserPermissions === DefaultPermissionsEnum.Read ||
        (!currentUserPermissions &&
            currentFolder?.defaultPermission === DefaultPermissionsEnum.Read)

    return (
        <>
            <div className="mt-6 flex flex-col items-center md:mt-20 md:flex-row md:items-start">
                <Menu
                    as="div"
                    className="relative z-50 inline-block w-full rounded text-left md:hidden"
                >
                    <div className="w-full sm:flex sm:justify-center">
                        <Menu.Button className="text-semibold inline-flex h-12 w-full items-center justify-center rounded border border-primary px-4 py-2 text-lg font-semibold text-primary hover:border-primary-hover hover:bg-indigo-50 hover:bg-opacity-25 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary">
                            {menuItem}
                            <ChevronDownIcon
                                className="-mr-1 ml-2 h-5 w-5 "
                                aria-hidden="true"
                            />
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <div className="absolute w-full sm:flex sm:justify-center">
                            <Menu.Items className="mt-2 w-full rounded-md  bg-white font-medium shadow focus:outline-none">
                                <div>
                                    {MenuItems.map((item) => {
                                        const isPermissions =
                                            item.name ===
                                            SettingsMenuItem.permissions
                                        const isInvite =
                                            item.name ===
                                            SettingsMenuItem.invite
                                        const isStopSharing =
                                            item.name ===
                                            SettingsMenuItem.stopSharing

                                        const isChangeLog =
                                            item.name ===
                                            SettingsMenuItem.changeLog

                                        const featuresToDisable =
                                            isInvite ||
                                            isStopSharing ||
                                            isPermissions ||
                                            isChangeLog

                                        if (
                                            item.name ===
                                                SettingsMenuItem.changeLog &&
                                            extensionState?.hasEncryptionKeys
                                        ) {
                                            return null
                                        }
                                        // Hide menu items on mobile if permissions are not granted
                                        if (
                                            featuresToDisable &&
                                            isPermissionsPageDisabled
                                        )
                                            return
                                        else
                                            return (
                                                <Menu.Item
                                                    key={`settings-menu-item-${item.name}`}
                                                >
                                                    {({ active }) => (
                                                        <Link
                                                            to={item.url}
                                                            className={classNames(
                                                                active &&
                                                                    'bg-slate-100 text-slate-900',
                                                                'flex items-center px-4 py-2'
                                                            )}
                                                            onClick={() => {
                                                                setMenuItem(
                                                                    item.name
                                                                )
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                            )
                                    })}
                                </div>
                            </Menu.Items>
                        </div>
                    </Transition>
                </Menu>
                {/* <nav className="hidden w-48 flex-col border-r border-slate-900 md:flex">
                    {MenuItems.map((item) => {
                        const isPermissions =
                            item.name === SettingsMenuItem.permissions
                        const isInvite = item.name === SettingsMenuItem.invite
                        const isStopSharing =
                            item.name === SettingsMenuItem.stopSharing
                        const isChangeLog =
                            item.name === SettingsMenuItem.changeLog

                        const featuresToDisable =
                            isInvite ||
                            isStopSharing ||
                            isPermissions ||
                            isChangeLog

                        // disable features if user only has read-only permissions
                        return isPermissionsPageDisabled &&
                            featuresToDisable ? (
                            <div
                                className="flex items-center w-full pb-6"
                                key={`settings-menu-item-${item.name}`}
                            >
                                <p
                                    className={`${
                                        featuresToDisable &&
                                        'text-slate-500 cursor-not-allowed select-none font-semibold'
                                    } text-lg`}
                                    onClick={() => setMenuItem(item.name)}
                                >
                                    {item.name}
                                </p>
                                {featuresToDisable && (
                                    <a
                                        className="mx-3 z-40 w-6 h-6 border-2 border-primary rounded-full flex justify-center items-center text-primary font-semibold text-lg cursor-pointer"
                                        data-tooltip-id="access-denied-tooltip"
                                        data-tooltip-place="top"
                                        data-tooltip-content="Sorry, you don't have permission to access this page."
                                    >
                                        ?<Tooltip id="access-denied-tooltip" />
                                    </a>
                                )}
                            </div>
                        ) : (
                            <Link
                                key={`settings-menu-item-${item.name}`}
                                to={item.url}
                                className={`${
                                    menuItem === item.name
                                        ? ' font-semibold text-primary'
                                        : 'font-semibold text-default hover:text-primary-hover'
                                } pb-6 pr-2 text-lg `}
                                onClick={() => setMenuItem(item.name)}
                            >
                                {item.name}
                            </Link>
                        )
                    })}
                </nav> */}

                <nav className="hidden w-48 flex-col border-r border-slate-900 md:flex">
                    {MenuItems.map((item) => {
                        // Do not render "changeLog" if extensionState?.hasEncryptionKeys is true
                        if (
                            item.name === SettingsMenuItem.changeLog &&
                            extensionState?.hasEncryptionKeys
                        ) {
                            return null
                        }

                        const isPermissions =
                            item.name === SettingsMenuItem.permissions
                        const isInvite = item.name === SettingsMenuItem.invite
                        const isStopSharing =
                            item.name === SettingsMenuItem.stopSharing
                        const isChangeLog =
                            item.name === SettingsMenuItem.changeLog

                        const featuresToDisable =
                            isInvite ||
                            isStopSharing ||
                            isPermissions ||
                            isChangeLog

                        // disable features if user only has read-only permissions
                        return isPermissionsPageDisabled &&
                            featuresToDisable ? (
                            <div
                                className="flex items-center w-full pb-6"
                                key={`settings-menu-item-${item.name}`}
                            >
                                <p
                                    className={`${
                                        featuresToDisable &&
                                        'text-slate-500 cursor-not-allowed select-none font-semibold'
                                    } text-lg`}
                                    onClick={() => setMenuItem(item.name)}
                                >
                                    {item.name}
                                </p>
                                {featuresToDisable && (
                                    <a
                                        className="mx-3 z-40 w-6 h-6 border-2 border-primary rounded-full flex justify-center items-center text-primary font-semibold text-lg cursor-pointer"
                                        data-tooltip-id="access-denied-tooltip"
                                        data-tooltip-place="top"
                                        data-tooltip-content="Sorry, you don't have permission to access this page."
                                    >
                                        ?<Tooltip id="access-denied-tooltip" />
                                    </a>
                                )}
                            </div>
                        ) : (
                            <Link
                                key={`settings-menu-item-${item.name}`}
                                to={item.url}
                                className={`${
                                    menuItem === item.name
                                        ? ' font-semibold text-primary'
                                        : 'font-semibold text-default hover:text-primary-hover'
                                } pb-6 pr-2 text-lg `}
                                onClick={() => setMenuItem(item.name)}
                            >
                                {item.name}
                            </Link>
                        )
                    })}
                </nav>

                <Outlet
                    context={{
                        user,
                        currentFolder,
                        folderUsers,
                        getSharedFolderUsers,
                        setMenuItem,
                        getSharedFolder,
                        organisation,
                        extensionState,
                    }}
                />
            </div>
            <Modal
                modalTitle={modal?.modalTitle}
                modalText={modal?.modalText}
                open={open}
                setOpen={setOpen}
                onSubmit={(e: FormEvent<HTMLFormElement>) => {
                    e.preventDefault()
                    setOpen(!open)
                }}
                primaryButton={
                    <Button
                        buttonText="Got it"
                        buttonTheme={ButtonTheme.primary}
                        className="w-full sm:max-w-max sm:px-8"
                        buttonType={ButtonType.submit}
                    />
                }
            />
        </>
    )
}

export function useCurrentUser() {
    return useOutletContext<ContextTypeUser>()
}

export function useCurrentOrganisation() {
    return useOutletContext<ContextTypeOrganisation>()
}

export function useCurrentSharedFolder() {
    return useOutletContext<ContextTypeCurrentSharedFolder>()
}

export function useFolderUsers() {
    return useOutletContext<ContextTypeFolderUsers>()
}

export function useGetSharedFolderUsers() {
    return useOutletContext<ContextTypeGetSharedFolderUsers>()
}

export function useSetMenuItem() {
    return useOutletContext<ContextTypeSetMenuItem>()
}

export function useGetCurrentSharedFolder() {
    return useOutletContext<ContextTypeGetCurrentSharedFolder>()
}

export function useCurrentExtensionState() {
    return useOutletContext<ContextTypeCurrentExtensionState>()
}
