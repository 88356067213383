import { ChangeEvent, SVGProps, useEffect, useState } from 'react'
import apiHelper from '../apiClient/defaultApiClient'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import { ButtonTheme } from '../helpers/constants/enum'
import { handleOpenLiveChat } from '../helpers/helperFunctions'
import { toastError } from '../helpers/commonComponents/toastHelper'
import { useNavigate } from 'react-router-dom'
import Modal from '../helpers/commonComponents/Modal'
import SpinnerSvgIcon from '../helpers/icons/SpinnerSvgIcon'

type Plan = {
    title: string
    teamSize: string
    description: string
    pricing?: {
        monthly: number
        yearly: number
    }
    features: Array<string> | string
    buttonText: string
    icon: SVGProps<SVGSVGElement>
    mostPopular: boolean
}

const planTypes = ['pod', 'team', 'company', 'enterprise'] as const
type PlanType = (typeof planTypes)[number]

type billingFrequency = 'monthly' | 'yearly'

const plans: Record<PlanType, Plan> = {
    pod: {
        title: 'Pod Plan',
        description: 'Perfect for small teams looking to share resources.',
        teamSize: '5 people',
        pricing: {
            monthly: 20,
            yearly: 220,
        },
        features: ['Unlimited shared folders'],
        buttonText: 'Buy now',
        mostPopular: false,
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 stroke-primary m-1"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                ></path>
            </svg>
        ),
    },
    team: {
        title: 'Team Plan',
        description: 'Ideal for large teams sharing company-wide resources.',
        teamSize: '15 people',
        pricing: {
            monthly: 45,
            yearly: 495,
        },
        features: ['Unlimited shared folders', 'Permissions'],
        buttonText: 'Buy now',
        mostPopular: true,
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-8 stroke-primary m-1"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
                ></path>
            </svg>
        ),
    },
    company: {
        title: 'Company Plan',
        description:
            'Perfect for companies focused on efficient resource sharing.',
        teamSize: '30 people',
        pricing: {
            monthly: 75,
            yearly: 825,
        },
        features: ['Unlimited shared folders', 'Permissions'],
        buttonText: 'Buy now',
        mostPopular: false,
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-8 stroke-primary m-1"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                ></path>
            </svg>
        ),
    },
    enterprise: {
        title: 'Enterprise Plan',
        description: 'More than 30 people?',
        teamSize: '30 people +',
        features: [
            'Unlimited shared folders',
            'Permissions',
            'End-to-end encryption',
            'Pay by invoice',
        ],
        buttonText: 'Contact us',
        mostPopular: false,
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-8 stroke-primary m-1"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                ></path>
            </svg>
        ),
    },
}

export function PlanSelectorPage() {
    const [selectedPlan, setSelectedPlan] = useState<PlanType>('team')
    const [selectedBillingFrequency, setSelectedBillingFrequency] =
        useState<billingFrequency>('monthly')
    const navigate = useNavigate()
    const pollingInterval = 5000
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedPlan(event.target.value as PlanType)
    }

    const invertBillingFrequency = () => {
        setSelectedBillingFrequency(
            selectedBillingFrequency === 'monthly' ? 'yearly' : 'monthly'
        )
    }

    const [paymentSuccessModal, setPaymentSuccessModal] =
        useState<boolean>(false)

    const checkProfile = async (
        intervalId?: ReturnType<typeof setInterval>
    ) => {
        try {
            const profile = await apiHelper.getProfile()
            // Check if profile.org_ids contains at least one ID
            if (profile.orgIds && profile.orgIds.length > 0) {
                // Stop polling
                if (intervalId) {
                    clearInterval(intervalId)
                }
                // Redirect to home page
                navigate('/')
            } else return
        } catch (error) {
            console.error('Error fetching profile:', error)
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const paymentStatus = params.get('payment_status')

        if (paymentStatus === 'cancel') {
            toastError('Your payment request was cancelled')
            params.delete('payment_status')
            const newSearch = params.toString()
            navigate(
                {
                    pathname: location.pathname,
                    search: newSearch ? `?${newSearch}` : '',
                },
                { replace: true }
            )
        } else if (paymentStatus === 'success') {
            setPaymentSuccessModal(true)

            let intervalId: ReturnType<typeof setInterval>

            // Set up polling
            intervalId = setInterval(
                () => checkProfile(intervalId),
                pollingInterval
            )

            // Cleanup interval on component unmount
            return () => clearInterval(intervalId)
        } else {
            //Upfront check if this user is on a free trial, if no query params are set
            //if no this call will redirect them to the home page
            checkProfile().catch((error) => {
                console.error('Error checking profile:', error)
            })
        }
    }, [])

    const onSubmit = async () => {
        if (selectedPlan === 'enterprise') {
            handleOpenLiveChat()
            return
        }

        setIsLoading(true)

        const url = await apiHelper.postPaymentUrl({
            body: {
                planName: selectedPlan,
                paymentFrequency: selectedBillingFrequency,
            },
        })
        window.location.href = url.checkoutUrl
    }

    const activeToggleClasses = 'font-semibold text-indigo-600'

    const toggleIndicatorClasses = `bg-primary-light h-16 w-1/2 rounded-full absolute transition-all border border-primary ${
        selectedBillingFrequency === 'yearly' ? 'left-1/2' : 'left-0'
    }`

    return (
        <>
            <header className="text-center mt-8 mb-12">
                <h1 className=" text-4xl sm:text-5xl mb-4 font-light ">
                    Pricing plans
                </h1>
            </header>
            <div className="flex flex-col items-center mt-2 toggle">
                <button
                    id="pricingToggle"
                    className="cursor-pointer bg-secondary/60 h-16 w-72 sm:w-80 md:w-96 lg:w-80 xl:w-96 rounded-full flex items-center justify-between select-none group text-xl relative shadow"
                    onClick={() => invertBillingFrequency()}
                >
                    <div
                        id="toggleIndicator"
                        className={toggleIndicatorClasses}
                    ></div>
                    <span
                        id="monthlyText"
                        className={`z-10 w-1/2 text-center ${
                            selectedBillingFrequency === 'monthly'
                                ? activeToggleClasses
                                : ''
                        }`}
                    >
                        Monthly
                    </span>
                    <span
                        id="yearlyText"
                        className={`z-10 w-1/2 text-center ${
                            selectedBillingFrequency === 'yearly'
                                ? activeToggleClasses
                                : ''
                        }`}
                    >
                        Yearly
                        <span
                            className={`block absolute top-[-10px] right-[15px] text-sm bg-primary text-white px-2 rounded font-semibold`}
                        >
                            1 free month
                        </span>
                    </span>
                </button>
            </div>

            <section className="flex flex-col h-fit items-center justify-start lg:flex-row lg:items-start lg:justify-center text-xl mb-24 sm:mb-20 lg:mb-16 lg:h-[550px] mx-4 px-4 mt-10">
                <form
                    id="radioForm"
                    className="flex flex-col mb-4 lg:mb-0"
                    onSelect={(e) => console.log(e)}
                >
                    {Object.entries(plans).map(([key, plan]) => (
                        <div className="mb-2 lg:mb-4" key={key}>
                            <input
                                type="radio"
                                id={key}
                                name="plan"
                                value={key}
                                className="hidden peer"
                                onChange={handleChange}
                                checked={selectedPlan === key}
                            />
                            <label
                                htmlFor={key}
                                className={`flex flex-row justify-between p-2 lg:p-4 border border-slate-300 rounded-lg cursor-pointer w-72 sm:w-80 md:w-96 lg:w-80 pod peer-checked:border-primary peer-checked:bg-primary-light/50 peer-checked:text-primary hover:border-primary transition-all duration-150 ease-in ${
                                    plan.mostPopular && 'relative'
                                }`}
                            >
                                {plan.mostPopular && (
                                    <span className="md:block absolute top-[-0px] right-[-2px] lg:top-[-6px] lg:right-[10px] text-sm bg-primary text-white px-2 rounded font-semibold">
                                        Most popular
                                    </span>
                                )}
                                <div className="flex flex-row items-center">
                                    <>{plan.icon}</>
                                    <div className="peer-checked:text-primary">
                                        <p className="ml-2 font-semibold">
                                            {plan.title}
                                        </p>
                                        <p className="ml-2 text-lg font-light peer-checked:text-primary">
                                            {plan.teamSize}
                                        </p>
                                    </div>
                                </div>
                            </label>
                        </div>
                    ))}
                </form>

                <PlanInfo
                    plan={plans[selectedPlan]}
                    selectedBillingFrequency={selectedBillingFrequency}
                    onSubmit={onSubmit}
                    isLoading={isLoading}
                />
                <section className="mt-8 lg:mt-0 w-72 sm:w-80 md:w-96 lg:w-80 p-4 bg-blue-50 text-blue-800 rounded h-fit">
                    Questions about our pricing?{' '}
                    <button
                        id="openChat"
                        className="font-bold underline mt-2 hover:no-underline"
                        onClick={handleOpenLiveChat}
                    >
                        Start a live chat
                    </button>
                </section>
            </section>

            <Modal
                open={paymentSuccessModal}
                setOpen={setPaymentSuccessModal}
                modalTitle="We're processing your payment!"
                modalText="Please wait a moment while the transfer is completed."
                children={
                    <SpinnerSvgIcon
                        className="mx-auto mt-6 mb-2"
                        width="40"
                        height="40"
                    />
                }
            />
        </>
    )
}

const PlanInfo = (props: {
    plan: Plan
    selectedBillingFrequency: billingFrequency
    onSubmit: () => void
    isLoading: boolean
}) => {
    const monthlyPriceWithoutDiscount = (props.plan.pricing?.monthly ?? 0) * 12

    return (
        <section
            key={props.plan.title}
            className="w-72 sm:w-80 md:w-96 lg:w-80 xl:w-96 xl:mx-16 p-4 sm:p-8 border border-slate-300 rounded mx-4 h-fit pod-pricing"
        >
            <h2 className="font-semibold">{props.plan.title}</h2>
            {props.plan.pricing ? (
                <>
                    {props.selectedBillingFrequency === 'monthly' ? (
                        <p className="text-3xl text-primary mt-4 monthly-pricing font-medium">
                            {`$${props.plan.pricing?.monthly}`}
                            <span className="text-xl text-default font-light">
                                {' '}
                                / month
                            </span>
                        </p>
                    ) : (
                        <p className="text-3xl text-primary mt-4 yearly-pricing font-semibold">
                            <span className="line-through text-indigo-400/60">
                                ${monthlyPriceWithoutDiscount}
                            </span>{' '}
                            {`$${props.plan.pricing?.yearly}`}
                            <span className="text-xl text-default font-light">
                                {' '}
                                / year
                            </span>
                        </p>
                    )}
                    <p className="font-light mt-1">
                        for up to{' '}
                        <span className="font-semibold">
                            {props.plan.teamSize}{' '}
                        </span>{' '}
                    </p>
                </>
            ) : (
                <p className="text-xl mt-4">Per seat pricing</p>
            )}

            <div className="border-y border-slate-300 my-4 py-4">
                <ul className="font-light">
                    {Array.isArray(props.plan.features) &&
                        props.plan.features.map((item: string, index) => (
                            <li className="mb-1.5 flex items-start" key={index}>
                                <div className="min-w-6 min-h-6 mr-2 mt-1">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="m4.5 12.75 6 6 9-13.5"
                                        />
                                    </svg>
                                </div>
                                {item}
                            </li>
                        ))}
                </ul>
            </div>
            <Button
                className="w-full text-xl"
                onClick={() => props.onSubmit()}
                buttonType={ButtonType.button}
                buttonTheme={ButtonTheme.primary}
                buttonText={props.plan.buttonText}
                disabled={props.isLoading}
            />
        </section>
    )
}
