import { useState } from 'react'
import ShowPasswordIcon from './ShowPasswordIcon'
import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
interface InputProps {
    isLoading: boolean
    register?: UseFormRegisterReturn
    errors: FieldError | undefined
    labelText?: string
    inputType: string
    maxLength?: number
    inputClass?: string
    customElement?: JSX.Element
    showPasswordIconClass?: string
    placeholder?: string
    containerClass?: string
    autoComplete?: string
    minLength?: number | null
}

export default function Input(props: InputProps) {
    const {
        isLoading,
        register,
        errors,
        inputType,
        labelText,
        maxLength,
        inputClass,
        customElement,
        showPasswordIconClass,
        placeholder,
        containerClass,
        autoComplete,
        minLength,
    } = props

    const [showPassword, setShowPassword] = useState<boolean>(false)

    return (
        <div
            className={`flex flex-col self-center relative  ${containerClass}`}
        >
            {labelText && (
                <label
                    className={`mb-1 text-lg font-semibold cursor-pointer`}
                    htmlFor={inputType}
                >
                    {labelText}
                </label>
            )}
            <input
                id={inputType}
                minLength={minLength || 6}
                maxLength={maxLength}
                placeholder={placeholder}
                type={showPassword ? 'text' : 'password'}
                aria-invalid={errors ? 'true' : 'false'}
                disabled={isLoading}
                autoComplete={autoComplete || 'on'}
                className={`h-12 rounded-t border-b-2 border-default bg-background px-2 invalid:border-red-800 invalid:text-red-800 focus-visible:border-primary focus-visible:bg-primary-light focus-visible:outline-none focus-visible:invalid:border-red-800 focus-visible:invalid:bg-red-50 pr-12  ${
                    errors &&
                    'border-red-800 text-red-800 focus-visible:border-red-800 focus-visible:bg-red-50 focus-visible:outline-none'
                } ${inputClass}`}
                {...register}
            />

            {customElement && customElement}

            <ShowPasswordIcon
                show={showPassword}
                showPassword={() => setShowPassword(!showPassword)}
                className={`absolute m-0 h-8 w-8 translate-x-1/2 transform disabled:cursor-not-allowed ${showPasswordIconClass}`}
                disabled={isLoading}
            />
        </div>
    )
}
