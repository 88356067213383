import { TagType } from '../constants/enum'

interface TagProps {
    className?: string
    tagText: string | JSX.Element
    tagType: TagType
}

export default function Tag(props: TagProps) {
    const { tagText, className, tagType } = props

    return (
        <aside
            role={'alert'}
            className={`${
                tagType === TagType.created && 'bg-green-50 text-green-700'
            } ${tagType === TagType.deleted && 'bg-red-50 text-red-700'}
                ${tagType === TagType.moved && 'bg-blue-50 text-blue-700'}
                ${
                    tagType === TagType.updated &&
                    'bg-yellow-50 text-yellow-700'
                } 
                 ${
                     tagType === TagType.generic &&
                     'bg-slate-100 text-slate-700'
                 } 
                  ${
                      tagType === TagType.primary &&
                      'bg-primary-light text-primary'
                  } 
             select-none items-center rounded px-2 py-0.5 font-bold text-sm uppercase w-fit ${className}`}
        >
            <p>{tagText}</p>
        </aside>
    )
}
