import { useState, useEffect, FormEvent } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Section from '../helpers/commonComponents/Section'
import { AlertType, ButtonTheme } from '../helpers/constants/enum'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import Alert from '../helpers/customComponents/Alert'
import Input from '../helpers/customComponents/Input'
import { useForm, useFieldArray } from 'react-hook-form'
import { Switch } from '@headlessui/react'
import {
    PlusIcon,
    TrashIcon,
    CheckCircleIcon,
    DocumentDuplicateIcon,
    XCircleIcon,
} from '@heroicons/react/24/outline'
import {
    toastSuccess,
    toastError,
} from '../helpers/commonComponents/toastHelper'
import Modal from '../helpers/commonComponents/Modal'
import apiHelper from '../apiClient/defaultApiClient'
import { SamlDomain } from '../apiClient'

interface SAMLConfigFormData {
    metadataUrl: string
    enabled: boolean
    domains: SamlDomain[]
}

interface SAMLEditConfigProps {
    isNew?: boolean
}

export default function SAMLEditConfig({ isNew = false }: SAMLEditConfigProps) {
    const { configId, organisationId } = useParams<{
        configId: string
        organisationId: string
    }>()
    // Ensure these values are always strings for TypeScript
    const safeConfigId = configId || ''
    const safeOrganisationId = organisationId || ''
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [addDomainModalOpen, setAddDomainModalOpen] = useState(false)
    const [verificationModalOpen, setVerificationModalOpen] = useState(false)
    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false)
    const [domainToDelete, setDomainToDelete] = useState<number | null>(null)
    const [domainToVerify, setDomainToVerify] = useState<number | null>(null)
    const [newDomain, setNewDomain] = useState('')
    const [verificationRecord, setVerificationRecord] = useState('')
    const [newDomainError, setNewDomainError] = useState(false)

    // Form setup with react-hook-form
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        reset,
    } = useForm<SAMLConfigFormData>({
        defaultValues: {
            metadataUrl: '',
            enabled: true,
            domains: [],
        },
    })

    // Field array for managing domains
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'domains',
    })

    // Watch the form values
    const formValues = watch()

    useEffect(() => {
        // Check if this is a new configuration based on the prop
        if (isNew) {
            setIsLoading(false)
            // Initialize with empty values
            reset({
                metadataUrl: '',
                enabled: true,
                domains: [],
            })
        } else {
            setIsLoading(true)

            // Fetch the SAML configuration by ID
            apiHelper
                .getSamlConfig({ id: parseInt(safeConfigId) })
                .then((config) => {
                    // Fetch the domains for this configuration
                    return apiHelper
                        .getSamlDomains({ configId: config.id })
                        .then((domains) => {
                            // Reset the form with the fetched data
                            reset({
                                metadataUrl: config.metadataUrl,
                                enabled: config.enabled,
                                domains: domains,
                            })

                            setIsLoading(false)
                        })
                })
                .catch((error) => {
                    console.error('Error fetching SAML configuration:', error)
                    toastError(
                        'Failed to load SAML configuration. Please try again.'
                    )
                    setIsLoading(false)

                    // Set error state to show error message
                    setError(
                        'Failed to load SAML configuration. Please try again or go back to the listing page.'
                    )
                    // Initialize with empty values on error
                    reset({
                        metadataUrl: '',
                        enabled: false,
                        domains: [],
                    })
                })
        }
    }, [configId, organisationId, reset])

    const onSubmit = (data: SAMLConfigFormData) => {
        setIsLoading(true)

        if (isNew) {
            // Create a new SAML configuration
            const createRequest = {
                metadataUrl: data.metadataUrl,
                enabled: data.enabled,
                organisationId: parseInt(safeOrganisationId),
            }

            apiHelper
                .postSamlConfig({ body: createRequest })
                .then((response) => {
                    toastSuccess('SAML configuration created successfully')
                    // Navigate to the edit page for the newly created config
                    navigate(
                        `/organisation/${organisationId}/saml/${response.id}`
                    )
                })
                .catch((error) => {
                    console.error('Error creating SAML configuration:', error)
                    setIsLoading(false)
                    toastError(
                        'Failed to create SAML configuration. Please try again.'
                    )
                })
        } else {
            // Update an existing SAML configuration
            const updateRequest = {
                metadataUrl: data.metadataUrl,
                enabled: data.enabled,
            }

            apiHelper
                .putSamlConfig({
                    id: parseInt(safeConfigId),
                    body: updateRequest,
                })
                .then((response) => {
                    setIsLoading(false)
                    toastSuccess('SAML configuration updated successfully')
                })
                .catch((error) => {
                    console.error('Error updating SAML configuration:', error)
                    setIsLoading(false)
                    toastError(
                        'Failed to update SAML configuration. Please try again.'
                    )
                })
        }
    }

    const openAddDomainModal = () => {
        setNewDomain('')
        setNewDomainError(false)
        setAddDomainModalOpen(true)
    }

    // Function to clean domain names
    const cleanDomain = (
        domain: string
    ): { cleanedDomain: string; hadProtocol: boolean } => {
        // Trim and convert to lowercase
        let cleanedDomain = domain.trim().toLowerCase()

        // Check for any protocol pattern (scheme followed by colon and slashes)
        // This matches any scheme:// pattern where scheme is one or more letters, digits, plus, minus, or dots
        const protocolRegex = /^([a-z0-9+.-]+):\/\//i
        const hadProtocol = protocolRegex.test(cleanedDomain)

        // Remove any protocol by taking everything after the first occurrence of ://
        if (hadProtocol) {
            cleanedDomain = cleanedDomain.substring(
                cleanedDomain.indexOf('://') + 3
            )
        }

        // Remove any path, query parameters, or hash
        cleanedDomain = cleanedDomain.split('/')[0]
        cleanedDomain = cleanedDomain.split('?')[0]
        cleanedDomain = cleanedDomain.split('#')[0]

        return { cleanedDomain, hadProtocol }
    }

    const handleAddDomain = (e: FormEvent) => {
        e.preventDefault()
        if (!newDomain.trim()) {
            setNewDomainError(true)
            return
        }

        // Clean the domain
        const { cleanedDomain, hadProtocol } = cleanDomain(newDomain)

        if (!cleanedDomain.trim()) {
            setNewDomainError(true)
            return
        }

        // Update the domain with the cleaned version
        setNewDomain(cleanedDomain)

        // Notify user if protocol was removed
        if (hadProtocol) {
            toastSuccess(
                `Protocol removed from domain. Using: ${cleanedDomain}`
            )
        }

        setIsLoading(true)

        // Create the domain via API
        const createDomainRequest = {
            domain: cleanedDomain,
        }

        apiHelper
            .postSamlDomain({
                configId: parseInt(safeConfigId),
                body: createDomainRequest,
            })
            .then((response) => {
                setVerificationRecord(response.verificationCode)

                // Add the new domain to the local form state
                const newDomainObj = {
                    ...response,
                    domain: cleanedDomain,
                }

                // Update the form values with the new domain
                const updatedDomains = [...formValues.domains, newDomainObj]
                setValue('domains', updatedDomains)

                // Set domainToVerify to null to indicate we're verifying a newly added domain
                setDomainToVerify(null)

                // Close the add domain modal and open the verification modal
                setAddDomainModalOpen(false)
                setVerificationModalOpen(true)
                setIsLoading(false)
            })
            .catch((error) => {
                console.error('Error adding domain:', error)
                setIsLoading(false)
                toastError('Failed to add domain. Please try again.')
            })
    }

    const confirmAddDomain = async (domain: SamlDomain) => {
        try {
            setIsLoading(true)

            // First check the verification status of the specific domain
            const refreshedDomain = await apiHelper.getSamlDomain({
                configId: domain.samlConfigId,
                id: domain.id,
                forceVerify: true,
            })

            // Then fetch all domains to update the form state
            const allDomains = await apiHelper.getSamlDomains({
                configId: domain.samlConfigId,
            })

            // Update the form with the latest domains
            reset({
                ...formValues,
                domains: allDomains,
            })

            // Show appropriate message based on verification status
            const domainName = domain.domain

            // Close the verification modal
            setVerificationModalOpen(false)

            if (refreshedDomain.verified) {
                toastSuccess(`Domain ${domainName} verified.`)
            } else {
                toastSuccess(
                    `Domain verification pending. DNS propagation may take a few hours.`
                )
            }
        } catch (error) {
            console.error('Error refreshing domain information:', error)
            toastError('Failed to update domain information. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }

    const handleDeleteDomain = (index: number) => {
        setDomainToDelete(index)
        setDeleteConfirmModalOpen(true)
    }

    const showVerificationRecord = (index: number) => {
        const domain = formValues.domains[index]
        setDomainToVerify(index)

        setVerificationRecord(domain.verificationCode)
        setNewDomain(domain.domain)
        setVerificationModalOpen(true)
    }

    const confirmDeleteDomain = () => {
        if (domainToDelete !== null) {
            const domainToRemove = formValues.domains[domainToDelete]

            if (!isNew && domainToRemove && domainToRemove.id) {
                setIsLoading(true)

                // Delete the domain via API
                // Make sure we have a valid numeric ID before parsing
                const configIdNumber = Number(safeConfigId)
                if (isNaN(configIdNumber)) {
                    console.error('Invalid config ID:', safeConfigId)
                    toastError(
                        'Invalid configuration ID. Cannot delete domain.'
                    )
                    setIsLoading(false)
                    setDeleteConfirmModalOpen(false)
                    setDomainToDelete(null)
                    return
                }
                apiHelper
                    .deleteSamlDomain({
                        configId: configIdNumber,
                        id: domainToRemove.id,
                    })
                    .then(() => {
                        remove(domainToDelete)
                        setDeleteConfirmModalOpen(false)
                        setDomainToDelete(null)
                        toastSuccess('Domain deleted successfully')
                        setIsLoading(false)
                    })
                    .catch((error) => {
                        console.error('Error deleting domain:', error)
                        setDeleteConfirmModalOpen(false)
                        setDomainToDelete(null)
                        toastError('Failed to delete domain. Please try again.')
                        setIsLoading(false)
                    })
            } else {
                // For new domains that haven't been saved to the backend yet
                remove(domainToDelete)
                setDeleteConfirmModalOpen(false)
                setDomainToDelete(null)
                toastSuccess('Domain removed successfully')
            }
        }
    }

    // const handleLaterClick = () => {
    //     // Check if a new domain has been added that needs verification
    //     if (domainToVerify !== null) {
    //         // No new domain to verify, just close the modal
    //         setVerificationModalOpen(false)
    //         return
    //     }

    //     setIsLoading(true)

    //     apiHelper
    //         .getSamlDomains({ configId: parseInt(safeConfigId) })
    //         .then((domains) => {
    //             reset({ ...formValues, domains })
    //             setVerificationModalOpen(false)
    //             setIsLoading(false)
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching domains:', error)
    //             setVerificationModalOpen(false)
    //             setIsLoading(false)
    //         })
    // }

    if (error) {
        return (
            <div className="box-border flex w-full flex-col items-center p-0 pt-10 lg:px-40 lg:pt-14 xl:px-80">
                <Alert
                    showAlert
                    alertText={error}
                    alertType={AlertType.failure}
                />
            </div>
        )
    }

    const [isCopied, setIsCopied] = useState(false)

    return (
        <>
            <div className="box-border flex w-full flex-col items-center p-0 pt-8 lg:px-40 xl:px-80 text-lg mb-24">
                <Section
                    sectionTitle={
                        isNew
                            ? 'Add SAML configuration'
                            : 'Edit SAML configuration'
                    }
                >
                    <>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="w-full"
                        >
                            <div className="w-full">
                                <Input
                                    register={register('metadataUrl', {
                                        required: 'Metadata URL is required',
                                    })}
                                    labelText="Metadata URL*"
                                    errors={errors.metadataUrl}
                                    errorText={errors.metadataUrl?.message}
                                    isLoading={isLoading}
                                    inputType="text"
                                    className="w-full text-ellipsis truncate"
                                    containerClass="w-full"
                                    disabled={isLoading}
                                />
                            </div>

                            <div className="mb-4">
                                <h2 className="flex items-center mb-2 font-semibold">
                                    Enable SAML{' '}
                                    {formValues.enabled ? (
                                        <CheckCircleIcon className="ml-2 w-6 h-6 stroke-2 text-primary" />
                                    ) : (
                                        <XCircleIcon className="ml-2 w-6 h-6 stroke-2 text-red-500" />
                                    )}
                                </h2>
                                <div className="flex items-center">
                                    <Switch
                                        checked={formValues.enabled}
                                        onChange={(checked) =>
                                            setValue('enabled', checked)
                                        }
                                        className={`${
                                            formValues.enabled
                                                ? 'bg-primary'
                                                : 'bg-slate-200'
                                        }
                                        relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2`}
                                        disabled={isLoading}
                                    >
                                        <span className="sr-only">
                                            Enable SAML
                                        </span>
                                        <span
                                            className={`${
                                                formValues.enabled
                                                    ? 'translate-x-6'
                                                    : 'translate-x-1'
                                            }
                                            inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                                        />
                                    </Switch>
                                </div>
                            </div>

                            {!isNew && (
                                <div className="mt-8">
                                    <label className="font-semibold">
                                        Domains
                                    </label>
                                    <p className="font-light">
                                        Add domains that should be allowed to
                                        authenticate with this SAML
                                        configuration. Each domain must be
                                        verified before it can be used.
                                    </p>

                                    <div className="mt-4">
                                        {fields.map((field, index) => (
                                            <div
                                                key={field.id}
                                                className="flex items-center space-x-2 mb-4"
                                            >
                                                <div className="flex-grow">
                                                    <div className="relative h-14">
                                                        <Input
                                                            register={register(
                                                                `domains.${index}.domain` as const,
                                                                {
                                                                    required:
                                                                        'Domain is required',
                                                                }
                                                            )}
                                                            labelText=""
                                                            errors={
                                                                errors
                                                                    .domains?.[
                                                                    index
                                                                ]?.domain
                                                            }
                                                            errorText="Domain is required"
                                                            isLoading={
                                                                isLoading
                                                            }
                                                            inputType="text"
                                                            className="w-full bg-slate-100 text-ellipsis truncate"
                                                        />
                                                        {/* Transparent overlay to prevent editing */}
                                                        <div
                                                            className="absolute inset-0 cursor-not-allowed"
                                                            onClick={(e) =>
                                                                e.preventDefault()
                                                            }
                                                        ></div>
                                                    </div>
                                                </div>

                                                <div className="flex items-center space-x-2">
                                                    {formValues.domains[index]
                                                        .verified ? (
                                                        <div className="flex items-center text-green-600">
                                                            <CheckCircleIcon className="h-5 w-5 mr-1" />
                                                            <span className="text-sm">
                                                                Verified
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <Button
                                                            buttonText="Verify Domain"
                                                            buttonType={
                                                                ButtonType.button
                                                            }
                                                            buttonTheme={
                                                                ButtonTheme.tertiary
                                                            }
                                                            disabled={isLoading}
                                                            onClick={() =>
                                                                showVerificationRecord(
                                                                    index
                                                                )
                                                            }
                                                            className="px-2 py-1 text-sm"
                                                        />
                                                    )}

                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            handleDeleteDomain(
                                                                index
                                                            )
                                                        }
                                                        className="p-2 rounded-full hover:bg-red-100 text-red-500 border border-transparent active:border-red-500"
                                                        disabled={isLoading}
                                                    >
                                                        <TrashIcon className="h-5 w-5" />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <Button
                                        buttonText="Add Domain"
                                        buttonType={ButtonType.button}
                                        buttonTheme={ButtonTheme.tertiary}
                                        disabled={isLoading}
                                        onClick={openAddDomainModal}
                                        className="flex items-center px-2"
                                        buttonIcon={
                                            <PlusIcon className="h-5 w-5 mr-1 stroke-2" />
                                        }
                                    />
                                </div>
                            )}
                            {isNew && (
                                <div className="mb-4">
                                    <p className="font-light italic">
                                        You will be able to add domains after
                                        creating the configuration.
                                    </p>
                                </div>
                            )}

                            <div className="flex flex-col-reverse sm:flex-row justify-end mt-8">
                                <Button
                                    buttonText="Go back to all configurations"
                                    buttonType={ButtonType.button}
                                    buttonTheme={ButtonTheme.tertiary}
                                    disabled={isLoading}
                                    onClick={() =>
                                        navigate(
                                            `/organisation/${organisationId}/saml`
                                        )
                                    }
                                    className="px-6 mt-2 sm:mt-0 sm:mr-2"
                                />
                                <Button
                                    buttonText={
                                        isNew
                                            ? 'Create configuration'
                                            : 'Save configuration'
                                    }
                                    buttonType={ButtonType.submit}
                                    buttonTheme={ButtonTheme.primary}
                                    disabled={isLoading}
                                    className="px-6"
                                />
                            </div>
                        </form>
                    </>
                </Section>
            </div>

            {/* Add Domain Modal */}
            <Modal
                open={addDomainModalOpen}
                setOpen={setAddDomainModalOpen}
                modalTitle="Add Domain"
                modalText="Enter the domain you want to add to this SAML configuration."
                onSubmit={handleAddDomain}
                primaryButton={
                    <Button
                        buttonType={ButtonType.submit}
                        buttonText="Continue"
                        buttonTheme={ButtonTheme.primary}
                        disabled={isLoading}
                        className="px-6"
                    />
                }
                secondaryButton={
                    <Button
                        buttonType={ButtonType.button}
                        buttonText="Cancel"
                        buttonTheme={ButtonTheme.tertiary}
                        disabled={isLoading}
                        onClick={() => setAddDomainModalOpen(false)}
                        className="px-6 mt-2 sm:mt-0 mr-0 sm:mr-2"
                    />
                }
            >
                <div className="mt-4 text-left">
                    <label htmlFor="domain-input" className="text-lg mb-1">
                        Domain
                    </label>
                    <input
                        id="domain-input"
                        type="text"
                        value={newDomain}
                        onChange={(e) => {
                            setNewDomain(e.target.value)
                            if (newDomainError) setNewDomainError(false)
                        }}
                        placeholder="example.com"
                        className={`w-full h-12 rounded-t border-b-2 border-default text-lg px-2 focus-visible:border-primary focus-visible:bg-primary-light focus-visible:outline-none ${
                            newDomainError
                                ? 'border-red-800 text-red-800 focus-visible:border-red-800 focus-visible:bg-red-50'
                                : ''
                        }`}
                        disabled={isLoading}
                    />
                    {newDomainError && (
                        <span
                            role="alert"
                            className="font-semibold text-sm text-red-800 mt-1"
                        >
                            Please enter a valid domain name
                        </span>
                    )}
                    <div className="mt-3 text-sm font-light ">
                        <p className="mb-1">Guidelines for domain names:</p>
                        <ul className="list-disc pl-5 space-y-1">
                            <li>
                                Enter a domain without protocol (e.g.,
                                example.com, not http://example.com)
                            </li>
                            <li>
                                IP addresses (both IPv4 and IPv6) are not
                                allowed
                            </li>
                            <li>
                                Domain must have at least one dot (e.g.,
                                example.com)
                            </li>
                            <li>
                                Only alphanumeric characters, hyphens, and dots
                                are allowed
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal>

            {/* Verification Modal */}
            <Modal
                open={verificationModalOpen}
                setOpen={setVerificationModalOpen}
                modalTitle="Verify Domain Ownership"
                modalText={`To verify your ownership of ${newDomain}, please add the TXT record to your domain's DNS settings.`}
                primaryButton={
                    <Button
                        buttonType={ButtonType.button}
                        buttonText="Verify"
                        buttonTheme={ButtonTheme.primary}
                        disabled={isLoading}
                        onClick={() => {
                            // If we're verifying an existing domain
                            if (domainToVerify !== null) {
                                confirmAddDomain(
                                    formValues.domains[domainToVerify]
                                )
                            } else {
                                // For newly added domains
                                const lastDomainIndex =
                                    formValues.domains.length - 1
                                if (lastDomainIndex >= 0) {
                                    confirmAddDomain(
                                        formValues.domains[lastDomainIndex]
                                    )
                                } else {
                                    // This should not happen if form state is properly updated
                                    toastError(
                                        'Error: Could not find the newly added domain'
                                    )
                                    setVerificationModalOpen(false)
                                }
                            }
                        }}
                        className="px-6"
                    />
                }
                secondaryButton={
                    <Button
                        buttonType={ButtonType.button}
                        buttonText="Later"
                        buttonTheme={ButtonTheme.tertiary}
                        disabled={isLoading}
                        onClick={() => setVerificationModalOpen(false)}
                        className="px-6 mt-2 sm:mt-0 sm:mr-2"
                    />
                }
            >
                <div>
                    <div className="bg-slate-100 p-3 rounded-md mb-4 flex justify-between items-center">
                        <code className="text-sm font-mono">
                            {verificationRecord}
                        </code>
                        <button
                            type="button" // Explicitly set button type to prevent form submission
                            onClick={(e) => {
                                e.preventDefault() // Prevent any default actions
                                e.stopPropagation() // Stop event propagation
                                navigator.clipboard.writeText(
                                    verificationRecord
                                )
                                setIsCopied(true)

                                setTimeout(() => {
                                    setIsCopied(false)
                                }, 2000)
                            }}
                            disabled={isLoading}
                            className="ml-2 focus:outline-none"
                            title="Copy to clipboard"
                        >
                            <div className="h-7 flex items-center">
                                {isCopied ? (
                                    <span className="text-xs font-medium text-default">
                                        Copied!
                                    </span>
                                ) : (
                                    <DocumentDuplicateIcon className="h-5 w-5 text-slate-700 hover:text-default" />
                                )}
                            </div>
                        </button>
                    </div>
                    <p className="mt-2 text-sm font-light">
                        After adding the TXT record, click "Add Domain" to
                        continue. You can verify the domain later from the
                        domains list.
                    </p>
                </div>
            </Modal>

            {/* Delete Domain Confirmation Modal */}
            <Modal
                open={deleteConfirmModalOpen}
                setOpen={setDeleteConfirmModalOpen}
                modalTitle="Delete Domain"
                modalText={
                    domainToDelete !== null
                        ? `Are you sure you want to remove ${
                              formValues.domains[domainToDelete]?.domain ||
                              'this domain'
                          }?`
                        : 'Are you sure you want to remove this domain?'
                }
                primaryButton={
                    <Button
                        buttonType={ButtonType.button}
                        buttonText="Delete"
                        buttonTheme={ButtonTheme.errorPrimary}
                        disabled={isLoading}
                        onClick={confirmDeleteDomain}
                        className="px-6"
                    />
                }
                secondaryButton={
                    <Button
                        buttonType={ButtonType.button}
                        buttonText="Cancel"
                        buttonTheme={ButtonTheme.tertiary}
                        disabled={isLoading}
                        onClick={() => setDeleteConfirmModalOpen(false)}
                        className="px-6 mt-2 sm:mt-0 sm:mr-2"
                    />
                }
            />
        </>
    )
}
