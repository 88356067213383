/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EncryptedData } from './EncryptedData';
import {
    EncryptedDataFromJSON,
    EncryptedDataFromJSONTyped,
    EncryptedDataToJSON,
} from './EncryptedData';

/**
 * 
 * @export
 * @interface CreateBookmarkEncryptedData
 */
export interface CreateBookmarkEncryptedData {
    /**
     * 
     * @type {EncryptedData}
     * @memberof CreateBookmarkEncryptedData
     */
    url?: EncryptedData;
    /**
     * 
     * @type {EncryptedData}
     * @memberof CreateBookmarkEncryptedData
     */
    title: EncryptedData;
}

/**
 * Check if a given object implements the CreateBookmarkEncryptedData interface.
 */
export function instanceOfCreateBookmarkEncryptedData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function CreateBookmarkEncryptedDataFromJSON(json: any): CreateBookmarkEncryptedData {
    return CreateBookmarkEncryptedDataFromJSONTyped(json, false);
}

export function CreateBookmarkEncryptedDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBookmarkEncryptedData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': !exists(json, 'url') ? undefined : EncryptedDataFromJSON(json['url']),
        'title': EncryptedDataFromJSON(json['title']),
    };
}

export function CreateBookmarkEncryptedDataToJSON(value?: CreateBookmarkEncryptedData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': EncryptedDataToJSON(value.url),
        'title': EncryptedDataToJSON(value.title),
    };
}

