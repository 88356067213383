export const uiText = {
    SharedFolder: {
        missingExtensionAlertText:
            'To access your bookmarks inside Bookmark Llama you will first need to install our extension.',
    },
    Notifications: {
        error: {
            retrieveOrganisation:
                "Oops, something went wrong, and we couldn't retrieve your organisation",
            retrieveOrganisationMembers:
                "Oops, something went wrong, and we couldn't retrieve your organisation members",
            retrieveFolders:
                "Oops, something went wrong and we couldn't retrieve your folders",
            logOut: "Oops, something went wrong. We couldn't log you out.",
            addPaymentMethod:
                "Oops, something went wrong and we couldn't add your payment method.",
            resetPassword:
                "Oops, something went wrong, and we couldn't reset your password",
            removeUserFromOrganisation:
                "Oops, something went wrong, and we couldn't remove the user from your organisation",
            updatePassword:
                "Oops, something went wrong, and we couldn't update your password",
            unauthorizedProfile: "You've been logged out",
            emailToResetPassword:
                "Oops, something went wrong and we couldn't send an email to reset your password. Please try again later",
            emailOrPasswordIncorrect:
                'Oops, your email or password was incorrect.',
            emailAlreadyInUse: 'Oops, that email address is already in use',
            addUsersToFolder:
                "Oops, something went wrong. We couldn't add the users to your folder.",
            stopSharing:
                "Oops, something went wrong, and we couldn't stop sharing your folder. Please try again later.",
            removeUserFromFolder:
                "Oops, something went wrong, and we couldn't remove the user from your folder",
            shareFolder:
                'Oops, something went wrong. Please try to share your folder again later.',
            generic: 'Oops, something went wrong. Please try again later.',
            failedToRetrievePaymentMethod:
                "Oops, something went wrong and we couldn't retrieve your payment method.",
            changeOwnerFailedToSendRequest:
                "Oops, something went wrong and we couldn't send your request.",
            restoreVersionFailure:
                "Oops, something went wrong and we couldn't restore your folder version; please try again later.",
            retrieveChangeLogFailure:
                'Oops, something went wrong and we retrieve your change log; please try again later.',
            encryptionPasswordIncorrect: 'Invalid password, please try again',
        },
        success: {
            shareFolder: 'Folder successfully shared!',
            syncFolder: 'Folder successfully synced!',
            stopSharing: 'Sharing stopped successfully!',
            addPaymentMethod: 'Payment method added',
            removeUserFromOrganisation:
                'Successfully removed from organisation',
            updatePassword: 'Password successfully updated',
            resetPassword: 'You password has been successfully reset!',
            emailToResetPassword:
                "All set, we've sent you an email to reset your password.",
            addUsersToFolder: 'Added to folder',
            removeUserFromFolder: 'Successfully removed from folder',
            changeOwnerRequestSubmitted:
                'Your request was successfully submitted, please allow 24 hours for your request to be completed.',
            restoreChanges: 'Folder version was successfully restored!',
        },
    },
}
