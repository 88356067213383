import Section from '../helpers/commonComponents/Section'
import {
    toastError,
    toastSuccess,
} from '../helpers/commonComponents/toastHelper'
import { uiText } from '../uiText/uiText'
import { useCurrentSharedFolder, useCurrentUser } from './Settings'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import { ButtonTheme } from '../helpers/constants/enum'
import Modal from '../helpers/commonComponents/Modal'
import { FormEvent, useRef, useState } from 'react'

// Creator cannot modify permissions for themselves
// No one can modify permissions for creator or themselves
export default function ChangeOwnerForm() {
    const { user } = useCurrentUser()
    const { currentFolder } = useCurrentSharedFolder()
    const [open, setOpen] = useState<boolean>(false)

    // Values to append (we have the information, the user shouldn't have to input any more info)
    const userEmail = user ? user.email : ''
    const folderId = currentFolder ? currentFolder.id.toString() : ''
    const formRef = useRef<HTMLFormElement>(null)

    const checkFormValidityAndOpenModal = () => {
        if (formRef.current?.checkValidity()) {
            setOpen(!open)
        } else {
            formRef.current?.reportValidity()
        }
    }

    const handleSubmit = (event: Event) => {
        event.preventDefault()

        const myForm = formRef.current
        const formData = new FormData(myForm ? myForm : undefined)

        // Convert FormData to a URLSearchParams object
        const formParams = new URLSearchParams()
        formData.forEach((value, key) => {
            formParams.append(key, value.toString())
        })

        // Append requested user email and folder id
        formParams.append('request-user-email', userEmail)
        formParams.append('folder-id', folderId)

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: formParams.toString(),
        })
            .then(() => {
                toastSuccess(
                    uiText.Notifications.success.changeOwnerRequestSubmitted
                )
                myForm?.reset()
            })
            .catch(() =>
                toastError(
                    uiText.Notifications.error.changeOwnerFailedToSendRequest
                )
            )
    }

    return (
        <Section className="box-border w-full md:mt-0 md:w-[80%] md:px-8 lg:ml-0 xl:px-32 text-lg">
            <form
                className="flex justify-between items-center text-lg w-full select-none"
                data-netlify="true"
                ref={formRef}
                method="POST"
                name="creator-bml"
                onSubmit={(e) => handleSubmit(e as any)}
            >
                <input type="hidden" name="form-name" value="creator-bml" />
                <section className=" text-default text-lg flex flex-col w-full">
                    <header className="w-full justify-between mb-10">
                        <h2 className="font-bold">Change folder owner</h2>
                        <p className="mt-2  font-light w-full">
                            The folder owner will always have edit rights to
                            this folder, unless a new owner is set.
                        </p>
                    </header>

                    <div>
                        <label className={`mb-1 text-lg cursor-pointer`}>
                            New Owner Email
                            <input
                                name="email"
                                required
                                placeholder="example@bookmarkllama.com"
                                type="email"
                                className={`mask-text h-12 w-full rounded-t border-b-2 border-slate-500 bg-background px-2  focus-visible:border-primary focus-visible:bg-primary-light focus-visible:outline-none`}
                            />
                        </label>
                    </div>

                    <Button
                        buttonText="Submit change"
                        buttonType={ButtonType.button}
                        buttonTheme={ButtonTheme.primary}
                        className="mt-8 self-end w-full sm:w-fit sm:px-8"
                        onClick={() => checkFormValidityAndOpenModal()}
                    />
                    <Modal
                        modalTitle={'Change folder owner request'}
                        modalText={
                            'This process is currently verified by a human; please allow up to 24hrs for the folder owner change.'
                        }
                        open={open}
                        setOpen={setOpen}
                        onSubmit={(e: FormEvent<HTMLFormElement>) => {
                            e.preventDefault()
                            setOpen(!open)
                        }}
                        primaryButton={
                            <Button
                                buttonText="Submit change"
                                buttonTheme={ButtonTheme.primary}
                                className="w-full sm:max-w-max sm:px-8"
                                buttonType={ButtonType.submit}
                            />
                        }
                        secondaryButton={
                            <Button
                                buttonText="Cancel"
                                buttonTheme={ButtonTheme.secondary}
                                className="w-full sm:max-w-max mt-4 sm:mt-0 sm:mx-4 sm:px-8"
                                buttonType={ButtonType.button}
                                onClick={() => setOpen(!open)}
                            />
                        }
                    />
                </section>
            </form>
        </Section>
    )
}
