/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SharedFolderVersionChangeLogCreationsInner } from './SharedFolderVersionChangeLogCreationsInner';
import {
    SharedFolderVersionChangeLogCreationsInnerFromJSON,
    SharedFolderVersionChangeLogCreationsInnerFromJSONTyped,
    SharedFolderVersionChangeLogCreationsInnerToJSON,
} from './SharedFolderVersionChangeLogCreationsInner';
import type { SharedFolderVersionChangeLogMovesInner } from './SharedFolderVersionChangeLogMovesInner';
import {
    SharedFolderVersionChangeLogMovesInnerFromJSON,
    SharedFolderVersionChangeLogMovesInnerFromJSONTyped,
    SharedFolderVersionChangeLogMovesInnerToJSON,
} from './SharedFolderVersionChangeLogMovesInner';
import type { SharedFolderVersionChangeLogUpdatesInner } from './SharedFolderVersionChangeLogUpdatesInner';
import {
    SharedFolderVersionChangeLogUpdatesInnerFromJSON,
    SharedFolderVersionChangeLogUpdatesInnerFromJSONTyped,
    SharedFolderVersionChangeLogUpdatesInnerToJSON,
} from './SharedFolderVersionChangeLogUpdatesInner';

/**
 * 
 * @export
 * @interface SharedFolderVersionChangeLog
 */
export interface SharedFolderVersionChangeLog {
    /**
     * 
     * @type {number}
     * @memberof SharedFolderVersionChangeLog
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof SharedFolderVersionChangeLog
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof SharedFolderVersionChangeLog
     */
    createdByEmail: string;
    /**
     * 
     * @type {Date}
     * @memberof SharedFolderVersionChangeLog
     */
    created: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SharedFolderVersionChangeLog
     */
    latestVersion: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SharedFolderVersionChangeLog
     */
    firstVersion: boolean;
    /**
     * 
     * @type {Array<SharedFolderVersionChangeLogCreationsInner>}
     * @memberof SharedFolderVersionChangeLog
     */
    creations: Array<SharedFolderVersionChangeLogCreationsInner>;
    /**
     * 
     * @type {Array<SharedFolderVersionChangeLogCreationsInner>}
     * @memberof SharedFolderVersionChangeLog
     */
    removals: Array<SharedFolderVersionChangeLogCreationsInner>;
    /**
     * 
     * @type {Array<SharedFolderVersionChangeLogUpdatesInner>}
     * @memberof SharedFolderVersionChangeLog
     */
    updates: Array<SharedFolderVersionChangeLogUpdatesInner>;
    /**
     * 
     * @type {Array<SharedFolderVersionChangeLogMovesInner>}
     * @memberof SharedFolderVersionChangeLog
     */
    moves: Array<SharedFolderVersionChangeLogMovesInner>;
}

/**
 * Check if a given object implements the SharedFolderVersionChangeLog interface.
 */
export function instanceOfSharedFolderVersionChangeLog(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "createdByEmail" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "latestVersion" in value;
    isInstance = isInstance && "firstVersion" in value;
    isInstance = isInstance && "creations" in value;
    isInstance = isInstance && "removals" in value;
    isInstance = isInstance && "updates" in value;
    isInstance = isInstance && "moves" in value;

    return isInstance;
}

export function SharedFolderVersionChangeLogFromJSON(json: any): SharedFolderVersionChangeLog {
    return SharedFolderVersionChangeLogFromJSONTyped(json, false);
}

export function SharedFolderVersionChangeLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): SharedFolderVersionChangeLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'],
        'createdByEmail': json['created_by_email'],
        'created': (new Date(json['created'])),
        'latestVersion': json['latest_version'],
        'firstVersion': json['first_version'],
        'creations': ((json['creations'] as Array<any>).map(SharedFolderVersionChangeLogCreationsInnerFromJSON)),
        'removals': ((json['removals'] as Array<any>).map(SharedFolderVersionChangeLogCreationsInnerFromJSON)),
        'updates': ((json['updates'] as Array<any>).map(SharedFolderVersionChangeLogUpdatesInnerFromJSON)),
        'moves': ((json['moves'] as Array<any>).map(SharedFolderVersionChangeLogMovesInnerFromJSON)),
    };
}

export function SharedFolderVersionChangeLogToJSON(value?: SharedFolderVersionChangeLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'version': value.version,
        'created_by_email': value.createdByEmail,
        'created': (value.created.toISOString()),
        'latest_version': value.latestVersion,
        'first_version': value.firstVersion,
        'creations': ((value.creations as Array<any>).map(SharedFolderVersionChangeLogCreationsInnerToJSON)),
        'removals': ((value.removals as Array<any>).map(SharedFolderVersionChangeLogCreationsInnerToJSON)),
        'updates': ((value.updates as Array<any>).map(SharedFolderVersionChangeLogUpdatesInnerToJSON)),
        'moves': ((value.moves as Array<any>).map(SharedFolderVersionChangeLogMovesInnerToJSON)),
    };
}

