import apiHelper from '../apiClient/defaultApiClient'
import {
    GetSharedFolderByIdRequest,
    Organisation,
    SharedFolder,
    User,
    UserProfile,
} from '../apiClient'
import { FormEvent, Fragment, useEffect, useState } from 'react'
import {
    Link,
    Outlet,
    useLocation,
    useMatches,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom'
import LoadingPage from '../helpers/commonComponents/LoadingPage'
import ErrorPage from '../helpers/commonComponents/ErrorPage'
import {
    NOT_FOUND_ERROR,
    UNAUTHORIZED_ERROR,
    UPLIGHT_ORGANISATION_ID,
    BLACKCROW_ORGANISATION_ID,
} from '../helpers/constants/constants'
import { Menu, Transition } from '@headlessui/react'
import {
    getExtensionState,
    extensionState,
    authenticateExtension,
    logoutExtension,
    runSyncEngine,
} from '../extensionCommunication/messager'
import Navigation from '../helpers/commonComponents/Navigation'
import Modal from '../helpers/commonComponents/Modal'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import { ButtonTheme, NotificationType } from '../helpers/constants/enum'
import {
    ArrowTopRightOnSquareIcon,
    UserCircleIcon,
    UserGroupIcon,
} from '@heroicons/react/24/outline'
import posthog from 'posthog-js'
import { posthogEnabled } from '../helpers/posthog'
import { uiText } from '../uiText/uiText'
import { toastError } from '../helpers/commonComponents/toastHelper'
import LinkCTAReactRouter from '../helpers/customComponents/LinkReactRouter'

type ContextTypeUser = { user: UserProfile | null }
type ContextTypeExtensionState = { extensionState: extensionState | null }
type ContextTypeCurrentFolder = { currentFolder: SharedFolder | null }
type ContextTypeGetSharedFolder = {
    getSharedFolder: (folderId: GetSharedFolderByIdRequest) => SharedFolder
}
type ContextTypeOrganisation = { organisation: Organisation | null }

// TODO: update user at the level of the controller when a free trial is enabled

export default function Controller() {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [extensionState, setExtensionState] = useState<
        extensionState | null | undefined
    >(undefined)
    const [user, setUser] = useState<UserProfile | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [currentFolder, setCurrentFolder] = useState<SharedFolder | null>(
        null
    )
    const location = useLocation()
    const [organisation, setOrganisation] = useState<Organisation | null>(null)
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const { folderId } = useParams()
    const matches = useMatches()
    const profileMatch = matches.find((object) => object.id === 'profile')
    const settingsMatch = matches.find((object) => object.id === 'settings')
    const dashboardMatch = matches.find((object) => object.id === 'dashboard')
    const shareMatch = matches.find((object) => object.id === 'share')
    const wizardMatch = matches.find((object) => object.id === 'wizard')
    const manageOrgMatch = matches.find(
        (object) =>
            object.id === 'organisation' ||
            object.id === 'saml-management' ||
            object.id === 'saml-edit-config' ||
            object.id === 'saml-new-config'
    )

    const { search } = useLocation()
    const fetchProfile = async () => {
        let response = await apiHelper.getProfile().catch(async (e) => {
            if (e.response && e.response.status === UNAUTHORIZED_ERROR) {
                await removeSessionToken()
                return navigate(`/login${search}`, {
                    state: {
                        notification: NotificationType.unAuthorizedProfile,
                    },
                })
            } else {
                setError(
                    `Oops, something went wrong, and we couldn't retrieve your profile`
                )
            }
        })
        if (response) {
            setUser(response)
            if (posthogEnabled) {
                posthog.identify(`user_${response.id}`)
            }
            await getOrganisation(response)
        }
    }

    const authExtension = async (): Promise<number | null> => {
        const sessionToken = getSessionToken()
        if (!sessionToken) return null
        const authRes = await authenticateExtension(sessionToken)
        if (authRes === null) {
            //Since we use same session token for frontend and extension, if the token was valid
            //for the frontend it should always be valid for the extension. If it's not, then
            //something has gone wrong.
            throw new Error(
                `failed to authenticate extension for userId ${user?.id}`
            )
        }
        await runSyncEngine()
        return authRes
    }

    const getSharedFolder = async (folderId: GetSharedFolderByIdRequest) => {
        let response = await apiHelper
            .getSharedFolderById(folderId)
            .catch((e: any) => {
                if (
                    e.response.status === UNAUTHORIZED_ERROR ||
                    e.response.status === NOT_FOUND_ERROR
                ) {
                    navigate('/')
                }
            })

        if (response) {
            setCurrentFolder(response)
        }
    }

    const getOrganisation = async (user: User) => {
        if (user && user.orgIds && user.orgIds.length > 0) {
            let response = await apiHelper
                .getOrganisation({ organisationId: user.orgIds[0] })
                .catch(() => {
                    toastError(uiText.Notifications.error.retrieveOrganisation)
                })
            if (response) setOrganisation(response)
        }
    }

    const refreshExtensionState = async () => {
        getExtensionState()
            .then(async (response) => {
                let forceReauthentication = false
                if (
                    response &&
                    response.isInstalled &&
                    user != null &&
                    user.id !== response.authenticatedUserId
                ) {
                    //If this occurs then the extension is not authenticated as the same user that is logged in
                    //to the webapp
                    forceReauthentication = true
                }
                let hasChanged =
                    !extensionState ||
                    response.isInstalled !== extensionState.isInstalled ||
                    response.authenticatedUserId !==
                        extensionState?.authenticatedUserId
                if (
                    response.isInstalled &&
                    (!response.authenticatedUserId || forceReauthentication)
                ) {
                    const authRes = await authExtension()
                    response.authenticatedUserId = authRes
                    hasChanged = response.authenticatedUserId
                        ? true
                        : hasChanged
                }

                if (!hasChanged || forceReauthentication) return
                setExtensionState(response)
            })
            .catch((e: any) => {
                if (e.message.includes('unsupported browser')) return
                else throw new Error(e)
            })
    }

    useEffect(() => {
        if (!extensionState) return
        const timer = setInterval(refreshExtensionState, 5000)
        window.addEventListener('focus', refreshExtensionState)
        return () => {
            clearInterval(timer)
            window.removeEventListener('focus', refreshExtensionState)
        }
    }, [extensionState])

    useEffect(() => {
        const sessionToken = getSessionToken()

        if (!sessionToken) {
            navigate(`/login${search}`)
            return
        }

        Promise.all([fetchProfile(), refreshExtensionState()])
            .then(async () => {
                setIsLoading(false)
                await refreshExtensionState()
            })
            .catch(() => setIsLoading(false))
    }, [])

    useEffect(() => {
        if (settingsMatch) {
            const sharedFolderId = Number(folderId)
            if (currentFolder?.id !== sharedFolderId) {
                getSharedFolder({ folderID: sharedFolderId })
            }
        } else {
            setCurrentFolder(null)
        }
    }, [settingsMatch])

    const getSessionToken = () => {
        return localStorage.getItem('sessionToken')
    }

    const removeSessionToken = () => {
        const sessionToken = getSessionToken() || null
        if (sessionToken) {
            localStorage.removeItem('sessionToken')
        }
    }

    function classNames(...classes: any) {
        return classes.filter(Boolean).join(' ')
    }

    const logout = async (event: FormEvent<HTMLFormElement>) => {
        event?.preventDefault()
        setIsLoading(true)
        try {
            await apiHelper.postLogout().then(async () => {
                if (posthogEnabled) {
                    posthog.reset()
                }
                if (extensionState?.isInstalled) await logoutExtension()
                removeSessionToken()
                navigate('/login')
            })
        } catch (error: any) {
            toastError(uiText.Notifications.error.logOut)
            setIsLoading(false)
        }
    }

    const getHeader = () => {
        if (dashboardMatch)
            return (
                <Navigation pageTitle="Dashboard" data-testid="dashboard">
                    <Menu
                        as="div"
                        className="relative inline-block rounded text-left"
                    >
                        <div>
                            <Menu.Button className="mask-text peer h-10 w-10 rounded-full bg-primary text-2xl font-semibold text-white sm:h-12 sm:w-12">
                                {user?.name.charAt(0).toUpperCase()}
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div>
                                    <Menu.Item>
                                        {() => (
                                            <aside
                                                className={classNames(
                                                    'flex select-none flex-col border-b px-4 py-6 text-base'
                                                )}
                                            >
                                                <p className="mask-text truncate text-ellipsis text-lg font-semibold">
                                                    {user?.name}
                                                </p>
                                                <p className="mask-text truncate text-ellipsis text-slate-500">
                                                    {user?.email}
                                                </p>
                                            </aside>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <Link
                                                to="profile"
                                                className={classNames(
                                                    active &&
                                                        'bg-slate-100 text-slate-900',
                                                    'flex border-b px-4 py-4'
                                                )}
                                            >
                                                <UserCircleIcon
                                                    className="mr-2 h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                                Profile
                                            </Link>
                                        )}
                                    </Menu.Item>

                                    {/* Only display if user has an organisation */}
                                    {organisation && (
                                        <>
                                            <Menu.Item>
                                                {() => (
                                                    <aside
                                                        className={classNames(
                                                            'flex select-none flex-col border-b px-4 py-6 text-base'
                                                        )}
                                                    >
                                                        <p className="mask-text truncate text-ellipsis text-lg font-semibold">
                                                            {organisation?.name}
                                                        </p>
                                                    </aside>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to={`organisation/${organisation.id}`}
                                                        className={classNames(
                                                            active &&
                                                                'bg-slate-100 text-slate-900',
                                                            'flex border-b px-4 py-4'
                                                        )}
                                                    >
                                                        <UserGroupIcon
                                                            className="mr-2 h-6 w-6"
                                                            aria-hidden="true"
                                                        />
                                                        Manage
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        </>
                                    )}

                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                type="button"
                                                className={classNames(
                                                    active &&
                                                        'bg-slate-100 text-slate-900',
                                                    'flex w-full  px-4 py-4'
                                                )}
                                                onClick={() => setOpen(true)}
                                            >
                                                Log out
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </Navigation>
            )
        if (profileMatch)
            return (
                <Navigation pageTitle="Profile" showLink data-testid="profile">
                    <button
                        type="button"
                        className="flex w-max items-center pr-4 text-lg font-semibold text-primary lg:pr-8"
                        onClick={() => setOpen(!open)}
                    >
                        Log out
                        <ArrowTopRightOnSquareIcon
                            className="ml-1 hidden h-6 w-6 stroke-primary sm:flex"
                            aria-hidden="true"
                        />
                    </button>
                </Navigation>
            )
        if (shareMatch) return <Navigation showLink data-testid="share" />
        if (settingsMatch) {
            return (
                <Navigation
                    pageTitle={`${
                        currentFolder ? `${currentFolder.name} Settings` : ''
                    }`}
                    showLink
                    data-testid="settings"
                />
            )
        }
        if (manageOrgMatch) {
            return (
                <Navigation
                    pageTitle={organisation?.name || ''}
                    showLink
                    data-testid="organisation"
                />
            )
        }
        if (wizardMatch) {
            return <Navigation showLink data-testid="wizard" />
        }
    }

    if (isLoading) return <LoadingPage />
    if ((!user || error) && !isLoading) {
        return <ErrorPage errorText={error} />
    }
    return (
        <main className="box-border h-full p-4 sm:px-14 sm:pt-14 sm:pb-8  flex flex-col justify-between 2xl:w-[1700px] mx-auto">
            <section>
                {getHeader()}
                <Outlet
                    context={{
                        user,
                        extensionState,
                        currentFolder,
                        getSharedFolder,
                        organisation,
                    }}
                />
            </section>
            <Modal
                open={open}
                modalTitle="Are you sure you want to log out?"
                setOpen={setOpen}
                onSubmit={(e: FormEvent<HTMLFormElement>) => logout(e)}
                primaryButton={
                    <Button
                        buttonType={ButtonType.submit}
                        buttonText="Log out"
                        buttonTheme={ButtonTheme.errorPrimary}
                        disabled={isLoading}
                        className="w-full sm:max-w-max sm:px-8"
                    />
                }
                secondaryButton={
                    <Button
                        buttonType={ButtonType.button}
                        buttonText="Cancel"
                        buttonTheme={ButtonTheme.tertiary}
                        disabled={isLoading}
                        className="mt-2 px-8 sm:mr-4 sm:mt-0"
                        onClick={() => setOpen(!open)}
                    />
                }
            />
            {/* FREE TRIAL EXPIRY MODAL */}
            <Modal
                open={
                    location.pathname !== '/plan-selector' &&
                    user?.freeTrialStatus === 'downgraded'
                }
                setOpen={(_: boolean) => {}}
                modalTitle={'Free Trial Ended'}
                modalText={`Your free trial has ended, and any folders you shared are no longer accessible to others. To keep using Bookmark Llama, please upgrade to a paid plan.`}
                primaryButton={
                    <LinkCTAReactRouter
                        buttonText={'Upgrade'}
                        buttonTheme={ButtonTheme.primary}
                        className="px-8"
                        path={
                            '/plan-selector?utm_source=free_trial_expired_modal'
                        }
                    />
                }
            />

            {/* ACCOUNT DISABLED DUE TO PAYMENT ISSUES MODAL*/}
            <Modal
                open={[UPLIGHT_ORGANISATION_ID].includes(organisation?.id ?? 0)}
                setOpen={(_: boolean) => {}}
                modalTitle={'Your account has been disabled.'}
                modalText={`There was a problem processing your payment, and your account has been temporarily disabled. Please contact us to restore access.`}
                primaryButton={
                    <LinkCTAReactRouter
                        buttonText={'Contact us'}
                        buttonTheme={ButtonTheme.primary}
                        className="px-8"
                        path={'https://www.bookmarkllama.com/contact'}
                    />
                }
            />
        </main>
    )
}

export function useUser() {
    return useOutletContext<ContextTypeUser>()
}

export function useExtensionState() {
    return useOutletContext<ContextTypeExtensionState>()
}

export function useCurrentFolder() {
    return useOutletContext<ContextTypeCurrentFolder>()
}

export function useGetSharedFolder() {
    return useOutletContext<ContextTypeGetSharedFolder>()
}

export function useOrganisation() {
    return useOutletContext<ContextTypeOrganisation>()
}
