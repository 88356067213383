import { useEffect, useState } from 'react'
import { BookmarkNodeV2 } from '../extensionCommunication/messager'
import { NodeRendererProps, Tree } from 'react-arborist'
import {
    ChevronRightIcon,
    FolderIcon,
    LinkIcon,
} from '@heroicons/react/24/outline'
import { extensionState } from '../extensionCommunication/messager'
import Section from '../helpers/commonComponents/Section'
import { AlertType, ButtonTheme } from '../helpers/constants/enum'
import Alert from '../helpers/customComponents/Alert'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import {
    ChromeSvgIcon,
    EdgeSvgIcon,
    FirefoxSvgIcon,
} from '../helpers/icons/BrowserSvgIcons'
import {
    CHROME_EXTENSION_URL,
    EDGE_EXTENSION_URL,
    FIREFOX_EXTENSION_URL,
} from '../helpers/constants/constants'
import { setExtensionInstallOpenedParam } from '../helpers/helperFunctions'
import SpinnerSvgIcon from '../helpers/icons/SpinnerSvgIcon'
import { useSearchParams } from 'react-router-dom'
import { uiText } from '../uiText/uiText'
import { Tooltip } from 'react-tooltip'
import { RefreshBookmarkStatusBar } from '../components/RefreshBookmarksStatusBar/RefreshBookmarksStatusBar'
import { FreeTrialBanner } from '../dashboard/FreeTrialBanner'
import { User } from '../apiClient'
import {
    getBookmarksV216Version,
    isVersionLower,
} from '../helpers/versionChecks'

interface BookmarksSectionProps {
    formErrorsLocalFolderId: string | undefined
    bookmarks: null | BookmarkNodeV2[]
    extensionState: extensionState
    isLoading: boolean
    localFolderId: string
    handleBookmarkFolderSelect: (localFolderId: string) => void
    sectionTitle?: string
    className?: string
    onRefresh?: any
    user: User | undefined
}

export default function BookmarksSection(props: BookmarksSectionProps) {
    const {
        formErrorsLocalFolderId,
        bookmarks,
        extensionState,
        isLoading,
        localFolderId,
        handleBookmarkFolderSelect,
        sectionTitle,
        className,
        user,
    } = props
    let [_, setSearchParams] = useSearchParams()
    const [showExtensionPollingSpinner, setShowExtensionPollingSpinner] =
        useState<boolean>(false)

    function getInitialOpenState(
        bookmarks: BookmarkNodeV2[] | null
    ): Record<string, boolean> {
        // Return an empty object if bookmarks is null or empty
        if (!bookmarks || bookmarks.length === 0) {
            return {}
        }

        const initialOpenState: Record<string, boolean> = {}

        // Root nodes are those in the top-level array
        for (const bookmark of bookmarks) {
            initialOpenState[bookmark.id] = true // Open all root nodes
        }

        return initialOpenState
    }

    const initialOpenState = getInitialOpenState(bookmarks)

    let hasNoNestedFolders = bookmarks?.every(
        (bookmark) => bookmark.children.length === 0
    )

    if (import.meta.env.MODE === 'e2e') {
        hasNoNestedFolders = false
    }

    // Poll to refresh and set the modal
    useEffect(() => {
        if (hasNoNestedFolders) {
            const intervalId = setInterval(() => {
                props.onRefresh()
            }, 1000)

            return () => clearInterval(intervalId)
        }
    }, [bookmarks])

    function NodeV2({ node, style }: NodeRendererProps<any>) {
        const showTooltip = node.level === 0 && node.data.children.length === 0
        /* This node instance can do many things. See the API reference. */
        return (
            <div
                style={style}
                onClick={() => {
                    node.toggle()
                    if (node.level > 0) handleBookmarkFolderSelect(node.data.id)
                }}
                className={`flex items-center select-none border-b border-slate-300 h-full   ${
                    node.data.id === localFolderId
                        ? 'bg-indigo-100 hover:bg-indigo-100 font-semibold'
                        : 'hover:bg-indigo-50 '
                } ${
                    showTooltip
                        ? 'hover:cursor-not-allowed text-slate-500'
                        : 'hover:cursor-pointer'
                }`}
            >
                {node.data.children.length > 0 ? (
                    <ChevronRightIcon className="h-6 w-6 stroke-2 mr-4" />
                ) : (
                    <FolderIcon className="h-6 w-6 mr-4 stroke-2" />
                )}
                <p className="mask-text">{node.data.name}</p>
                {showTooltip && (
                    <div className="">
                        <a
                            className="z-40 w-6 h-6 border-2 border-primary rounded-full flex justify-center items-center text-primary font-semibold text-lg cursor-pointer ml-4 "
                            data-tooltip-id="empty-high-level-folder"
                            data-tooltip-place="bottom"
                            data-tooltip-content="Built-in Bookmark Folders are not shareable"
                        >
                            ?
                            <Tooltip id="empty-high-level-folder" />
                        </a>
                    </div>
                )}
            </div>
        )
    }

    function NodeV3({ node, style }: NodeRendererProps<any>) {
        const isLevelZeroAndEmpty =
            node.level === 0 && node.data.children.length === 0
        const isURLOrLevelTwo = node.data.url || node.level === 2
        const isShareableFolder =
            node.level === 1 && !node.data.url && !node.data.remoteData

        const handleNodeClick = () => {
            if (isShareableFolder) {
                handleBookmarkFolderSelect(node.data.id)
            }
        }

        const ChevronIcon = ({ rotate }: { rotate: boolean }) => (
            <button
                onClick={() => {
                    node.toggle()
                }}
            >
                <ChevronRightIcon
                    className={`h-6 w-6 stroke-2 mr-4 hover:border border-slate-400 rounded transition-all ease-in-out duration-200 ${
                        rotate ? 'rotate-0' : 'rotate-90'
                    }`}
                />
            </button>
        )

        const Icon = () =>
            node.data.url ? (
                <LinkIcon className="h-6 w-6 stroke-1.5 mr-4" />
            ) : (
                <FolderIcon className="h-6 w-6 stroke-1.5 mr-4" />
            )

        const RadioButton = () =>
            isShareableFolder && (
                <div
                    className={`mr-10 w-5 h-5 border-2 rounded-full ${
                        node.data.id === localFolderId
                            ? 'border-4 border-primary'
                            : 'hover:bg-indigo-50 border-slate-300'
                    }`}
                ></div>
            )

        const NodeContent = () => (
            <div
                className={`w-full flex items-center h-full ${
                    isURLOrLevelTwo ||
                    node.data.remoteData ||
                    isLevelZeroAndEmpty
                        ? 'hover:cursor-not-allowed text-slate-500'
                        : 'hover:cursor-pointer'
                }`}
            >
                <div className="flex">
                    {node.data.children.length > 0 && (
                        <ChevronIcon rotate={!node.isOpen} />
                    )}
                    <Icon />
                </div>

                {isShareableFolder ? (
                    <button
                        className="h-full items-center w-full flex justify-between"
                        onClick={handleNodeClick}
                    >
                        <p className="truncate text-ellipsis w-[90%] text-left font-bold mask-text">
                            {node.data.name}
                        </p>
                        <RadioButton />
                    </button>
                ) : (
                    <div className="flex items-center w-full">
                        <p
                            className={`max-w-[90%] truncate text-ellipsis text-left ${
                                node.level > 0 ? 'mask-text' : ''
                            }`}
                        >
                            {node.data.name}
                        </p>
                    </div>
                )}
            </div>
        )

        return (
            <div
                style={style}
                className={`flex items-center justify-between select-none border-b border-slate-300 h-full ${
                    node.data.id === localFolderId
                        ? 'bg-indigo-100 hover:bg-indigo-100 font-semibold'
                        : 'hover:bg-indigo-50'
                }`}
            >
                {/* Shared folder tooltip */}
                {node.data.remoteData && (
                    <div
                        className="h-full w-full flex items-center hover:cursor-not-allowed transition-all ease-in-out duration-200"
                        data-tooltip-id="shared-folder"
                        data-tooltip-place="bottom"
                        data-tooltip-content="You've already shared this folder"
                    >
                        <Tooltip id="shared-folder" />
                        <NodeContent />
                    </div>
                )}

                {/* Link tooltip */}
                {node.data.url && (
                    <div
                        className="h-full w-full flex items-center hover:cursor-not-allowed transition-all ease-in-out duration-200"
                        data-tooltip-id="node-link"
                        data-tooltip-place="bottom"
                        data-tooltip-content="Links are not shareable"
                    >
                        <Tooltip id="node-link" />
                        <NodeContent />
                    </div>
                )}

                {/* Nested Folder tooltip, target folders and not urls */}
                {node.level === 2 && !node.data.url && (
                    <div
                        className="h-full w-full flex items-center hover:cursor-not-allowed transition-all ease-in-out duration-200"
                        data-tooltip-id="nested-folder"
                        data-tooltip-place="bottom"
                        data-tooltip-content="Nested folders are not shareable"
                    >
                        <Tooltip id="nested-folder" />
                        <NodeContent />
                    </div>
                )}

                {isLevelZeroAndEmpty && (
                    <div
                        className="h-full w-full flex items-center hover:cursor-not-allowed transition-all ease-in-out duration-200"
                        data-tooltip-id="empty-high-level-folder"
                        data-tooltip-place="bottom"
                        data-tooltip-content="Built-in Bookmark Folders are not shareable"
                    >
                        <Tooltip id="empty-high-level-folder" />
                        <NodeContent />
                    </div>
                )}

                {/* Remaining Node Content */}
                {!node.data.remoteData &&
                    !node.data.url &&
                    node.level !== 2 &&
                    !isLevelZeroAndEmpty && <NodeContent />}
            </div>
        )
    }

    const renderBookmarksSection = () => {
        if (!extensionState?.isInstalled) {
            return (
                <>
                    <Alert
                        alertType={AlertType.warning}
                        alertText={
                            uiText.SharedFolder.missingExtensionAlertText
                        }
                        showAlert
                    />
                    <div
                        className={`${
                            showExtensionPollingSpinner
                                ? 'visible'
                                : 'invisible'
                        } flex justify-center my-10`}
                    >
                        <SpinnerSvgIcon width="40" height="40" />
                    </div>
                    <div className="mt-6 flex flex-col justify-around sm:flex-row">
                        <Button
                            buttonType={ButtonType.button}
                            buttonText="Firefox"
                            disabled={isLoading}
                            className="w-full sm:w-[30%]"
                            buttonTheme={ButtonTheme.secondary}
                            buttonIcon={
                                <FirefoxSvgIcon
                                    height="32"
                                    width="32"
                                    className="mr-2"
                                />
                            }
                            onClick={() => {
                                window.open(FIREFOX_EXTENSION_URL)
                                setExtensionInstallOpenedParam(setSearchParams)
                                setShowExtensionPollingSpinner(true)
                            }}
                        />
                        <Button
                            buttonType={ButtonType.button}
                            buttonText="Chrome"
                            buttonTheme={ButtonTheme.secondary}
                            disabled={isLoading}
                            className="mt-2 w-full sm:mt-0 sm:w-[30%]"
                            buttonIcon={
                                <ChromeSvgIcon
                                    height="32"
                                    width="32"
                                    className="mr-2"
                                />
                            }
                            onClick={() => {
                                window.open(CHROME_EXTENSION_URL)
                                setExtensionInstallOpenedParam(setSearchParams)
                                setShowExtensionPollingSpinner(true)
                            }}
                        />
                        <Button
                            buttonType={ButtonType.button}
                            buttonText="Edge"
                            disabled={isLoading}
                            className="mt-2 w-full sm:mt-0 sm:w-[30%]"
                            buttonTheme={ButtonTheme.secondary}
                            buttonIcon={
                                <EdgeSvgIcon
                                    height="32"
                                    width="32"
                                    className="mr-2"
                                />
                            }
                            onClick={() => {
                                window.open(EDGE_EXTENSION_URL)
                                setExtensionInstallOpenedParam(setSearchParams)
                                setShowExtensionPollingSpinner(true)
                            }}
                        />
                    </div>
                </>
            )
        }

        if (!extensionState?.authenticatedUserId) {
            return (
                <div className="flex h-52 w-full items-center justify-center">
                    <SpinnerSvgIcon height="40" width="40" />
                </div>
            )
        }

        if (bookmarks?.length === 0) {
            return (
                <>
                    <RefreshBookmarkStatusBar
                        bookmarks={bookmarks}
                        onRefresh={props.onRefresh}
                    />
                    <Alert
                        alertType={AlertType.warning}
                        alertText={
                            <p>
                                We couldn't locate any bookmark folders on your
                                bookmark bar. Please enable the bookmark bar and
                                ensure the bookmarks you wish to share are
                                added.{' '}
                                <a
                                    href={
                                        'https://www.bookmarkllama.com/blog/show-bookmark-bar?utm_source=webapp_no_shared_folders'
                                    }
                                    target={'_blank'}
                                    className={
                                        'cursor-pointer font-bold underline'
                                    }
                                >
                                    Need help enabling the bookmark bar?
                                </a>
                            </p>
                        }
                        showAlert
                    />
                </>
            )
        }

        if (bookmarks)
            return (
                <>
                    {user?.freeTrial && <FreeTrialBanner user={user} />}

                    <div className="w-full flex-col justify-center items-center">
                        <div className="flex justify-between mb-2">
                            <h2 className="text-lg ml-2 mb-2 font-semibold">
                                Folders
                            </h2>

                            <RefreshBookmarkStatusBar
                                bookmarks={bookmarks}
                                onRefresh={props.onRefresh}
                            />
                        </div>
                        {bookmarks && (
                            <Tree
                                data={bookmarks}
                                openByDefault={false}
                                initialOpenState={initialOpenState}
                                width={'100%'}
                                height={360}
                                indent={40}
                                rowHeight={64}
                                className="text-lg w-full bookmark-scrollbar border-y border-slate-300"
                            >
                                {isVersionLower(
                                    extensionState?.version,
                                    getBookmarksV216Version
                                )
                                    ? NodeV2
                                    : NodeV3}
                            </Tree>
                        )}
                    </div>
                </>
            )
        return (
            <div>
                <Alert
                    showAlert
                    alertText="Oops, something went wrong!"
                    alertType={AlertType.failure}
                />
            </div>
        )
    }
    return (
        <div className={className}>
            <Section
                sectionTitle={sectionTitle}
                error={formErrorsLocalFolderId}
                emptySection={
                    bookmarks?.length === 0 || !extensionState?.isInstalled
                }
            >
                {renderBookmarksSection()}
            </Section>
        </div>
    )
}
