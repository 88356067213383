import {
    SharedFolderVersionChangeLog,
    SharedFolderVersionChangeLogCreationsInner,
    SharedFolderVersionChangeLogUpdatesInner,
    SharedFolderVersionChangeLogMovesInner,
} from '../apiClient'
import { SharedFolderVersionChangeLogRemovalsInner } from '../apiClient/models/SharedFolderVersionChangeLogRemovalsInner'
import Section from '../helpers/commonComponents/Section'
import { AlertType, ButtonTheme, TagType } from '../helpers/constants/enum'
import Tag from '../helpers/customComponents/Tag'
import { LinkIcon, FolderIcon } from '@heroicons/react/24/outline'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { formatDate } from '../helpers/helperFunctions'
import { useEffect, useState } from 'react'
import SpinnerSvgIcon from '../helpers/icons/SpinnerSvgIcon'
import apiHelper from '../apiClient/defaultApiClient'
import { useCurrentSharedFolder } from './Settings'
import Alert from '../helpers/customComponents/Alert'
import Modal from '../helpers/commonComponents/Modal'
import {
    toastError,
    toastSuccess,
} from '../helpers/commonComponents/toastHelper'
import { uiText } from '../uiText/uiText'

export default function ChangeLog() {
    const { currentFolder } = useCurrentSharedFolder()
    const [changeLogs, setChangeLogs] = useState<
        Array<SharedFolderVersionChangeLog>
    >([])

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [openRestoreModal, setOpenRestoreModal] = useState<boolean>(false)
    const [restoreVersion, setRestoreVersion] = useState<number | null>(null)

    const folderId = currentFolder?.id || null

    const getChangeLog = async (fromVersion?: number) => {
        if (folderId) {
            setIsLoading(true)

            let response = await apiHelper
                .getSharedFolderAuditLog({
                    sharedFolderId: folderId,
                    fromVersion: fromVersion,
                })
                .catch((err) => {
                    setIsLoading(false)
                    console.log(err)
                    toastError(
                        uiText.Notifications.error.retrieveChangeLogFailure
                    )
                })
            if (response && 'changeLogs' in response) {
                const logs =
                    response.changeLogs as Array<SharedFolderVersionChangeLog>
                if (changeLogs.length > 0 && fromVersion) {
                    //Existing logs
                    setChangeLogs([...changeLogs, ...logs])
                    setIsLoading(false)
                } else {
                    //No existing logs
                    setChangeLogs(logs)
                    setIsLoading(false)
                }
            } else setIsLoading(false)
        }
    }
    useEffect(() => {
        getChangeLog()
    }, [])

    const loadMore = () => {
        const lastVersion = changeLogs[changeLogs.length - 1].version
        getChangeLog(lastVersion)
    }

    const renderChanges = (
        item:
            | Array<SharedFolderVersionChangeLogRemovalsInner>
            | Array<SharedFolderVersionChangeLogCreationsInner>
            | Array<SharedFolderVersionChangeLogMovesInner>,
        tagType: TagType
    ) => {
        return (
            <div className="mt-3 flex items-start w-full">
                <Tag
                    tagText={`${tagType} (${item.length})`}
                    tagType={tagType}
                />
                <ul className="ml-3 w-[75%]">
                    {tagType === TagType.created &&
                        item.map((innerItem, index) => {
                            return innerItem.url ? (
                                <li
                                    className="flex items-center"
                                    key={`created-url-${index}-${innerItem.title}`}
                                >
                                    <LinkIcon className="w-5 mr-2 hidden md:block" />
                                    <p className="w-[85%] truncate text-ellipsis mask-text">
                                        {innerItem.title}
                                    </p>
                                </li>
                            ) : (
                                <li
                                    className="flex items-center "
                                    key={`created-folder-${index}-${innerItem.title}`}
                                >
                                    <FolderIcon className="w-5 mr-2 hidden md:block" />
                                    <p className="w-[85%] truncate text-ellipsis mask-text">
                                        {innerItem.title}
                                    </p>
                                </li>
                            )
                        })}

                    {tagType === TagType.deleted &&
                        item.map((innerItem, index) => {
                            return innerItem.url ? (
                                <li
                                    className="flex items-center "
                                    key={`deleted-url-${index}-${innerItem.title}`}
                                >
                                    <LinkIcon className="w-5 mr-2 hidden md:block" />
                                    <p className="w-[85%] truncate text-ellipsis mask-text">
                                        {innerItem.title}
                                    </p>
                                </li>
                            ) : (
                                <li
                                    className="flex items-center "
                                    key={`deleted-folder-${index}-${innerItem.title}`}
                                >
                                    <FolderIcon className="w-5 mr-2 hidden md:block" />
                                    <p className="w-[85%] truncate text-ellipsis mask-text">
                                        {innerItem.title}
                                    </p>
                                </li>
                            )
                        })}

                    {tagType === TagType.moved &&
                        item.map((innerItem, index) => {
                            return innerItem.url ? (
                                <li
                                    className="flex items-center"
                                    key={`moved-url-${index}-${innerItem.title}`}
                                >
                                    <LinkIcon className="w-5 mr-2 hidden md:block" />
                                    <p className="w-[85%] truncate text-ellipsis mask-text">
                                        {innerItem.title}
                                    </p>
                                </li>
                            ) : (
                                <li
                                    className="flex items-center"
                                    key={`moved-folder-${index}-${innerItem.title}`}
                                >
                                    <FolderIcon className="w-5 mr-2 hidden md:block" />
                                    <p className="w-[85%] truncate text-ellipsis mask-text">
                                        {innerItem.title}
                                    </p>
                                </li>
                            )
                        })}
                </ul>
            </div>
        )
    }

    const renderListItem = (
        index: number,
        label: string,
        oldValue: string | undefined,
        newValue: string | undefined,
        keySuffix: string,
        isLink?: boolean | undefined
    ) => {
        const Icon = isLink ? (
            <LinkIcon className="shrink-0 w-5 mr-2 hidden md:block" />
        ) : (
            <FolderIcon className="shrink-0 w-5 mr-2 hidden md:block" />
        )
        return (
            <li
                className="flex items-center flex-wrap w-full"
                key={`update-url-${index}-${oldValue}-${keySuffix}`}
            >
                <Tag
                    className="mr-3"
                    tagText={label}
                    tagType={TagType.generic}
                />
                <div className="flex items-center max-w-[45%]">
                    {Icon}
                    <p className="w-full truncate text-ellipsis mask-text">
                        {oldValue}
                    </p>
                </div>
                <ArrowRightIcon className="w-5 mx-2" />
                <div className="flex items-center max-w-[45%]">
                    {Icon}
                    <p className="w-full truncate text-ellipsis mask-text">
                        {newValue}
                    </p>
                </div>
            </li>
        )
    }

    const renderChangesUpdate = (
        item: Array<SharedFolderVersionChangeLogUpdatesInner>
    ) => {
        return (
            <div className="mt-3 flex items-start w-full">
                <Tag
                    tagText={`${TagType.updated} (${item.length})`}
                    tagType={TagType.updated}
                />
                <ul className="ml-3 w-[75%]">
                    {item.map((innerItem, index) => {
                        // If both title and URL have changed for link
                        const isLink = !!innerItem.oldUrl
                        if (innerItem.newUrl && innerItem.newTitle) {
                            return (
                                <>
                                    {/* Name Change */}
                                    {renderListItem(
                                        index,
                                        'name',
                                        innerItem.oldTitle,
                                        innerItem.newTitle,
                                        `${innerItem.oldTitle}-${innerItem.newTitle}`,
                                        isLink
                                    )}
                                    {/* URL Change */}
                                    {renderListItem(
                                        index,
                                        'url',
                                        innerItem.oldTitle,
                                        innerItem.newUrl,
                                        `${innerItem.oldTitle}-${innerItem.newUrl}`,
                                        isLink
                                    )}
                                </>
                            )
                        } else {
                            // Only title OR URL changed (URL or folder)
                            const label = innerItem.newUrl ? 'url' : 'name'
                            const newValue = innerItem.newUrl
                                ? innerItem.newUrl
                                : innerItem.newTitle
                            const keySuffix = newValue || ''

                            return renderListItem(
                                index,
                                label,
                                innerItem.oldTitle,
                                newValue,
                                keySuffix,
                                isLink
                            )
                        }
                    })}
                </ul>
            </div>
        )
    }

    return (
        <Section
            className="box-border w-full md:my-0 mb-20 md:w-[80%] md:px-8 lg:ml-0 xl:px-32 text-lg"
            hasScrollbar
        >
            {isLoading ? (
                <div className="flex w-full items-center justify-center h-48">
                    <SpinnerSvgIcon height="40" width="40" />
                </div>
            ) : (
                <div className="md:max-h-[600px] w-full  overflow-y-auto overflow-x-hidden dashboard-scrollbar sm:px-6 py-4">
                    <h2 className="font-bold mb-8">Change Log</h2>
                    {changeLogs.length === 0 && (
                        <Alert
                            alertText="Something went wrong and we couldn't retrieve your logs, please refresh the page and try again."
                            alertType={AlertType.failure}
                            showAlert={changeLogs.length === 0}
                        />
                    )}

                    {changeLogs.map((item, index) => {
                        return (
                            <section
                                className="mb-6 border-b pb-6 flex md:flex-col md:items-end lg:flex-row lg:items-start justify-between"
                                key={`log-${index}-${item.created}`}
                            >
                                <div className="flex items-start flex-col md:flex-row w-full lg:max-w-[80%]">
                                    <div className="mr-3 mb-2 md:mb-0 w-20">
                                        {item.latestVersion ? (
                                            <Tag
                                                tagText="current"
                                                tagType={TagType.primary}
                                            />
                                        ) : (
                                            <Tag
                                                tagText="previous"
                                                tagType={TagType.generic}
                                            />
                                        )}
                                    </div>

                                    <article className="flex flex-col items-start w-full md:w-[85%]">
                                        <p className="font-light w-[95%] truncate text-ellipsis mask-text">
                                            {formatDate(item.created)},{' '}
                                            {item.createdByEmail}
                                        </p>

                                        {item.firstVersion && (
                                            <div className="mt-3 flex items-center w-[90%]">
                                                <Tag
                                                    tagText="shared folder created"
                                                    tagType={TagType.primary}
                                                />
                                                <FolderIcon className="shrink-0 w-5 ml-3 mr-2 hidden md:block" />

                                                <p className="ml-3 md:ml-0 w-[40%] truncate text-ellipsis mask-text">
                                                    {currentFolder?.name}
                                                </p>
                                            </div>
                                        )}

                                        {item.creations.length > 0 &&
                                            !item.firstVersion &&
                                            renderChanges(
                                                item.creations,
                                                TagType.created
                                            )}

                                        {item.removals.length > 0 &&
                                            !item.firstVersion &&
                                            renderChanges(
                                                item.removals,
                                                TagType.deleted
                                            )}

                                        {item.moves.length > 0 &&
                                            !item.firstVersion &&
                                            renderChanges(
                                                item.moves,
                                                TagType.moved
                                            )}

                                        {item.updates.length > 0 &&
                                            !item.firstVersion &&
                                            renderChangesUpdate(item.updates)}

                                        {item.updates.length === 0 &&
                                            item.removals.length === 0 &&
                                            item.moves.length === 0 &&
                                            item.creations.length === 0 &&
                                            !item.firstVersion && (
                                                <p className="font-light">
                                                    No additional changes
                                                    detected from last version.
                                                </p>
                                            )}
                                    </article>
                                </div>

                                {!item.latestVersion && (
                                    <div className="mt-2 md:mt-0">
                                        <Button
                                            buttonText="Restore version"
                                            buttonTheme={
                                                ButtonTheme.errorTertiary
                                            }
                                            className="!h-fit px-2 justify-self-end hidden md:block mt-4 lg:mt-0"
                                            onClick={() => {
                                                setRestoreVersion(item.version)
                                                setOpenRestoreModal(
                                                    !openRestoreModal
                                                )
                                            }}
                                        />
                                    </div>
                                )}
                            </section>
                        )
                    })}
                    {!isLoading &&
                        !changeLogs[changeLogs.length - 1].firstVersion && (
                            <Button
                                buttonText="Load more"
                                buttonTheme={ButtonTheme.secondary}
                                className="w-[98%] md:w-fit mx-auto md:mx-0 md:justify-self-end md:px-6"
                                onClick={() => {
                                    loadMore()
                                }}
                                disabled={isLoading}
                            />
                        )}

                    <Modal
                        open={openRestoreModal}
                        setOpen={setOpenRestoreModal}
                        modalTitle={'Restore this version'}
                        modalText={`Restoring your folder will overwrite its current contents with this previous version. Are you sure you want to proceed?`}
                        primaryButton={
                            <Button
                                buttonText="Restore"
                                buttonTheme={ButtonTheme.errorPrimary}
                                className="justify-self-end px-6"
                                buttonType={ButtonType.button}
                                onClick={async () => {
                                    if (folderId && restoreVersion) {
                                        await apiHelper
                                            .restoreSharedFolderVersion({
                                                sharedFolderId: folderId,

                                                body: {
                                                    version: restoreVersion,
                                                },
                                            })
                                            .then(() => {
                                                getChangeLog()
                                                setRestoreVersion(null)
                                                setOpenRestoreModal(
                                                    !openRestoreModal
                                                )
                                                toastSuccess(
                                                    uiText.Notifications.success
                                                        .restoreChanges
                                                )
                                            })
                                            .catch((err) => {
                                                console.log(err)
                                                toastError(
                                                    uiText.Notifications.error
                                                        .restoreVersionFailure
                                                )
                                            })
                                    } else {
                                        toastError(
                                            uiText.Notifications.error
                                                .restoreVersionFailure
                                        )
                                        setRestoreVersion(null)
                                        setOpenRestoreModal(!openRestoreModal)
                                    }
                                }}
                            />
                        }
                        secondaryButton={
                            <Button
                                buttonText="Cancel"
                                buttonType={ButtonType.button}
                                buttonTheme={ButtonTheme.secondaryDark}
                                className="justify-self-end px-6 mr-4"
                                onClick={() => {
                                    setRestoreVersion(null)
                                    setOpenRestoreModal(!openRestoreModal)
                                }}
                            />
                        }
                    />
                </div>
            )}
        </Section>
    )
}
