/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddSharedFolderAssignmentsRequest,
  ForgotPasswordRequest,
  GetDailyIdentifier200Response,
  GetEncryptionChallenge200Response,
  GetEncryptionSetup200Response,
  GetOrganisationMembers200ResponseInner,
  GetSamlLoginMethod200Response,
  GetSharedFolderAssignments200ResponseInner,
  GetSharedFolderAuditLog200Response,
  GetSharedFolderShouldDeleteLocally200Response,
  ModelError,
  Notification,
  Organisation,
  PostEncryptionChallengeRequest,
  PostEncryptionSetupRequest,
  PostLogin200Response,
  PostLoginGoogleRequest,
  PostLoginMicrosoftRequest,
  PostLoginRequest,
  PostNotificationFiredRequest,
  PostOnboarding401Response,
  PostPaymentUrl200Response,
  PostPaymentUrlRequest,
  PostSamlConfigRequest,
  PostSamlDomainRequest,
  PostSharedFolder200Response,
  PostSharedFolderBookmarksRequest,
  PostSharedFolderDefaultPermissionRequest,
  PostSharedFolderRequest,
  PostSharedFolderUserPermissionRequest,
  PostUninstallSurveyRequest,
  PutSamlConfigRequest,
  PutSharedFolderByIdRequest,
  PutUserByIdRequest,
  ResetPasswordRequest,
  RestoreSharedFolderVersionRequest,
  SamlConfig,
  SamlDomain,
  SharedFolder,
  SharedFolderChangelog,
  SignupRequest,
  UserProfile,
  V3PostSharedFolderRequest,
} from '../models';
import {
    AddSharedFolderAssignmentsRequestFromJSON,
    AddSharedFolderAssignmentsRequestToJSON,
    ForgotPasswordRequestFromJSON,
    ForgotPasswordRequestToJSON,
    GetDailyIdentifier200ResponseFromJSON,
    GetDailyIdentifier200ResponseToJSON,
    GetEncryptionChallenge200ResponseFromJSON,
    GetEncryptionChallenge200ResponseToJSON,
    GetEncryptionSetup200ResponseFromJSON,
    GetEncryptionSetup200ResponseToJSON,
    GetOrganisationMembers200ResponseInnerFromJSON,
    GetOrganisationMembers200ResponseInnerToJSON,
    GetSamlLoginMethod200ResponseFromJSON,
    GetSamlLoginMethod200ResponseToJSON,
    GetSharedFolderAssignments200ResponseInnerFromJSON,
    GetSharedFolderAssignments200ResponseInnerToJSON,
    GetSharedFolderAuditLog200ResponseFromJSON,
    GetSharedFolderAuditLog200ResponseToJSON,
    GetSharedFolderShouldDeleteLocally200ResponseFromJSON,
    GetSharedFolderShouldDeleteLocally200ResponseToJSON,
    ModelErrorFromJSON,
    ModelErrorToJSON,
    NotificationFromJSON,
    NotificationToJSON,
    OrganisationFromJSON,
    OrganisationToJSON,
    PostEncryptionChallengeRequestFromJSON,
    PostEncryptionChallengeRequestToJSON,
    PostEncryptionSetupRequestFromJSON,
    PostEncryptionSetupRequestToJSON,
    PostLogin200ResponseFromJSON,
    PostLogin200ResponseToJSON,
    PostLoginGoogleRequestFromJSON,
    PostLoginGoogleRequestToJSON,
    PostLoginMicrosoftRequestFromJSON,
    PostLoginMicrosoftRequestToJSON,
    PostLoginRequestFromJSON,
    PostLoginRequestToJSON,
    PostNotificationFiredRequestFromJSON,
    PostNotificationFiredRequestToJSON,
    PostOnboarding401ResponseFromJSON,
    PostOnboarding401ResponseToJSON,
    PostPaymentUrl200ResponseFromJSON,
    PostPaymentUrl200ResponseToJSON,
    PostPaymentUrlRequestFromJSON,
    PostPaymentUrlRequestToJSON,
    PostSamlConfigRequestFromJSON,
    PostSamlConfigRequestToJSON,
    PostSamlDomainRequestFromJSON,
    PostSamlDomainRequestToJSON,
    PostSharedFolder200ResponseFromJSON,
    PostSharedFolder200ResponseToJSON,
    PostSharedFolderBookmarksRequestFromJSON,
    PostSharedFolderBookmarksRequestToJSON,
    PostSharedFolderDefaultPermissionRequestFromJSON,
    PostSharedFolderDefaultPermissionRequestToJSON,
    PostSharedFolderRequestFromJSON,
    PostSharedFolderRequestToJSON,
    PostSharedFolderUserPermissionRequestFromJSON,
    PostSharedFolderUserPermissionRequestToJSON,
    PostUninstallSurveyRequestFromJSON,
    PostUninstallSurveyRequestToJSON,
    PutSamlConfigRequestFromJSON,
    PutSamlConfigRequestToJSON,
    PutSharedFolderByIdRequestFromJSON,
    PutSharedFolderByIdRequestToJSON,
    PutUserByIdRequestFromJSON,
    PutUserByIdRequestToJSON,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    RestoreSharedFolderVersionRequestFromJSON,
    RestoreSharedFolderVersionRequestToJSON,
    SamlConfigFromJSON,
    SamlConfigToJSON,
    SamlDomainFromJSON,
    SamlDomainToJSON,
    SharedFolderFromJSON,
    SharedFolderToJSON,
    SharedFolderChangelogFromJSON,
    SharedFolderChangelogToJSON,
    SignupRequestFromJSON,
    SignupRequestToJSON,
    UserProfileFromJSON,
    UserProfileToJSON,
    V3PostSharedFolderRequestFromJSON,
    V3PostSharedFolderRequestToJSON,
} from '../models';

export interface AddSharedFolderAssignmentsOperationRequest {
    sharedFolderId: number;
    body?: AddSharedFolderAssignmentsRequest;
}

export interface DeleteOrganisationMemberRequest {
    organisationId: number;
    userId: number;
}

export interface DeleteSamlConfigRequest {
    id: number;
}

export interface DeleteSamlDomainRequest {
    configId: number;
    id: number;
}

export interface DeleteSharedFolderAssignmentRequest {
    userId: number;
    sharedFolderId: number;
}

export interface DeleteSharedFolderByIdRequest {
    folderID: number;
}

export interface DeviceTrackingRequest {
    deviceId: string;
    event: string;
}

export interface ForgotPasswordOperationRequest {
    body?: ForgotPasswordRequest;
}

export interface GetAllSamlConfigsRequest {
    organisationId?: number;
}

export interface GetNotificationsRequest {
    deviceId: string;
}

export interface GetOrganisationRequest {
    organisationId: number;
}

export interface GetOrganisationMembersRequest {
    organisationId: number;
}

export interface GetSamlConfigRequest {
    id: number;
}

export interface GetSamlDomainRequest {
    configId: number;
    id: number;
    forceVerify?: boolean;
}

export interface GetSamlDomainsRequest {
    configId: number;
}

export interface GetSamlLoginMethodRequest {
    domain: string;
}

export interface GetSharedFolderAssignmentsRequest {
    sharedFolderId: number;
}

export interface GetSharedFolderAuditLogRequest {
    sharedFolderId: number;
    fromVersion?: number;
}

export interface GetSharedFolderBookmarkChangesRequest {
    folderId: number;
    currentVersion?: number;
    rawTreeHash?: string;
    fixedVersion?: number;
}

export interface GetSharedFolderByIdRequest {
    folderID: number;
}

export interface GetSharedFolderShouldDeleteLocallyRequest {
    sharedFolderId: number;
    deviceId: string;
}

export interface PostEncryptionChallengeOperationRequest {
    body?: PostEncryptionChallengeRequest;
}

export interface PostEncryptionSetupOperationRequest {
    body?: PostEncryptionSetupRequest;
}

export interface PostLoginOperationRequest {
    body?: PostLoginRequest;
}

export interface PostLoginGoogleOperationRequest {
    body?: PostLoginGoogleRequest;
}

export interface PostLoginMicrosoftOperationRequest {
    body?: PostLoginMicrosoftRequest;
}

export interface PostNotificationFiredOperationRequest {
    notificationId: number;
    body?: PostNotificationFiredRequest;
}

export interface PostOnboardingRequest {
    body?: object;
}

export interface PostPaymentUrlOperationRequest {
    body?: PostPaymentUrlRequest;
}

export interface PostSamlConfigOperationRequest {
    body: PostSamlConfigRequest;
}

export interface PostSamlDomainOperationRequest {
    configId: number;
    body: PostSamlDomainRequest;
}

export interface PostSharedFolderOperationRequest {
    body?: PostSharedFolderRequest;
}

export interface PostSharedFolderBookmarksOperationRequest {
    folderId: number;
    body?: PostSharedFolderBookmarksRequest;
}

export interface PostSharedFolderDefaultPermissionOperationRequest {
    folderId: number;
    body?: PostSharedFolderDefaultPermissionRequest;
}

export interface PostSharedFolderUserPermissionOperationRequest {
    folderId: number;
    userId: number;
    body?: PostSharedFolderUserPermissionRequest;
}

export interface PostUninstallSurveyOperationRequest {
    body?: PostUninstallSurveyRequest;
}

export interface PostV2UninstallSurveyRequest {
    body?: PostUninstallSurveyRequest;
}

export interface PutSamlConfigOperationRequest {
    id: number;
    body: PutSamlConfigRequest;
}

export interface PutSharedFolderByIdOperationRequest {
    folderID: number;
    body?: PutSharedFolderByIdRequest;
}

export interface PutUserByIdOperationRequest {
    userID: number;
    body?: PutUserByIdRequest;
}

export interface ResetPasswordOperationRequest {
    body?: ResetPasswordRequest;
}

export interface RestoreSharedFolderVersionOperationRequest {
    sharedFolderId: number;
    body?: RestoreSharedFolderVersionRequest;
}

export interface SignupOperationRequest {
    body?: SignupRequest;
}

export interface V3AddSharedFolderAssignmentsRequest {
    sharedFolderId: number;
    body?: AddSharedFolderAssignmentsRequest;
}

export interface V3DeleteOrganisationMemberRequest {
    organisationId: number;
    userId: number;
}

export interface V3DeleteSharedFolderAssignmentRequest {
    userId: number;
    sharedFolderId: number;
}

export interface V3DeviceTrackingRequest {
    deviceId: string;
    event: string;
}

export interface V3ForgotPasswordRequest {
    body?: ForgotPasswordRequest;
}

export interface V3GetOrganisationRequest {
    organisationId: number;
}

export interface V3GetOrganisationMembersRequest {
    organisationId: number;
}

export interface V3GetSharedFolderAssignmentsRequest {
    sharedFolderId: number;
}

export interface V3GetSharedFolderShouldDeleteLocallyRequest {
    sharedFolderId: number;
    deviceId: string;
}

export interface V3PostSharedFolderOperationRequest {
    body?: V3PostSharedFolderRequest;
}

export interface V3ResetPasswordRequest {
    body?: ResetPasswordRequest;
}

export interface V3SignupRequest {
    body?: SignupRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create Shared Folder
     */
    async addSharedFolderAssignmentsRaw(requestParameters: AddSharedFolderAssignmentsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling addSharedFolderAssignments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{shared_folder_id}/users`.replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddSharedFolderAssignmentsRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create Shared Folder
     */
    async addSharedFolderAssignments(requestParameters: AddSharedFolderAssignmentsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addSharedFolderAssignmentsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteOrganisationMemberRaw(requestParameters: DeleteOrganisationMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling deleteOrganisationMember.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteOrganisationMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/organisation/{organisation_id}/user/{user_id}`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisationId))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteOrganisationMember(requestParameters: DeleteOrganisationMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOrganisationMemberRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a SAML configuration
     */
    async deleteSamlConfigRaw(requestParameters: DeleteSamlConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSamlConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/saml/config/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a SAML configuration
     */
    async deleteSamlConfig(requestParameters: DeleteSamlConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSamlConfigRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a SAML domain
     */
    async deleteSamlDomainRaw(requestParameters: DeleteSamlDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling deleteSamlDomain.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSamlDomain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/saml/config/{config-id}/domain/{id}`.replace(`{${"config-id"}}`, encodeURIComponent(String(requestParameters.configId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a SAML domain
     */
    async deleteSamlDomain(requestParameters: DeleteSamlDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSamlDomainRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteSharedFolderAssignmentRaw(requestParameters: DeleteSharedFolderAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteSharedFolderAssignment.');
        }

        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling deleteSharedFolderAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{shared_folder_id}/user/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSharedFolderAssignment(requestParameters: DeleteSharedFolderAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSharedFolderAssignmentRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Shared Folder By ID
     */
    async deleteSharedFolderByIdRaw(requestParameters: DeleteSharedFolderByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.folderID === null || requestParameters.folderID === undefined) {
            throw new runtime.RequiredError('folderID','Required parameter requestParameters.folderID was null or undefined when calling deleteSharedFolderById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{folderID}`.replace(`{${"folderID"}}`, encodeURIComponent(String(requestParameters.folderID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Shared Folder By ID
     */
    async deleteSharedFolderById(requestParameters: DeleteSharedFolderByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSharedFolderByIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deviceTrackingRaw(requestParameters: DeviceTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling deviceTracking.');
        }

        if (requestParameters.event === null || requestParameters.event === undefined) {
            throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling deviceTracking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/device/{device_id}/{event}`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"event"}}`, encodeURIComponent(String(requestParameters.event))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deviceTracking(requestParameters: DeviceTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deviceTrackingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async forgotPasswordRaw(requestParameters: ForgotPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async forgotPassword(requestParameters: ForgotPasswordOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.forgotPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Get all SAML configurations
     */
    async getAllSamlConfigsRaw(requestParameters: GetAllSamlConfigsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SamlConfig>>> {
        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/saml/configs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SamlConfigFromJSON));
    }

    /**
     * Get all SAML configurations
     */
    async getAllSamlConfigs(requestParameters: GetAllSamlConfigsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SamlConfig>> {
        const response = await this.getAllSamlConfigsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Authenticated User Shared Folders, including those marked not to sync
     */
    async getAllSharedFoldersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SharedFolder>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/profile/all-shared-folders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SharedFolderFromJSON));
    }

    /**
     * Get Authenticated User Shared Folders, including those marked not to sync
     */
    async getAllSharedFolders(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SharedFolder>> {
        const response = await this.getAllSharedFoldersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getDailyIdentifierRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDailyIdentifier200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/daily-identifier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDailyIdentifier200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getDailyIdentifier(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDailyIdentifier200Response> {
        const response = await this.getDailyIdentifierRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getEncryptionChallengeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEncryptionChallenge200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/encryption/challenge`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEncryptionChallenge200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getEncryptionChallenge(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEncryptionChallenge200Response> {
        const response = await this.getEncryptionChallengeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getEncryptionSetupRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEncryptionSetup200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/encryption/setup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEncryptionSetup200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getEncryptionSetup(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEncryptionSetup200Response> {
        const response = await this.getEncryptionSetupRaw(initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getHealthRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/health`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Your GET endpoint
     */
    async getHealth(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getHealthRaw(initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getNotificationsRaw(requestParameters: GetNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Notification>>> {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling getNotifications.');
        }

        const queryParameters: any = {};

        if (requestParameters.deviceId !== undefined) {
            queryParameters['device-id'] = requestParameters.deviceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationFromJSON));
    }

    /**
     * Your GET endpoint
     */
    async getNotifications(requestParameters: GetNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Notification>> {
        const response = await this.getNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Organisation
     */
    async getOrganisationRaw(requestParameters: GetOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Organisation>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling getOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/organisation/{organisation_id}`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationFromJSON(jsonValue));
    }

    /**
     * Create Organisation
     */
    async getOrganisation(requestParameters: GetOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Organisation> {
        const response = await this.getOrganisationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Authenticated Users Organisation
     */
    async getOrganisationMembersRaw(requestParameters: GetOrganisationMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetOrganisationMembers200ResponseInner>>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling getOrganisationMembers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/organisation/{organisation_id}/users`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrganisationMembers200ResponseInnerFromJSON));
    }

    /**
     * Get Authenticated Users Organisation
     */
    async getOrganisationMembers(requestParameters: GetOrganisationMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetOrganisationMembers200ResponseInner>> {
        const response = await this.getOrganisationMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Authenticated User
     */
    async getProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileFromJSON(jsonValue));
    }

    /**
     * Get Authenticated User
     */
    async getProfile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfile> {
        const response = await this.getProfileRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a SAML configuration
     */
    async getSamlConfigRaw(requestParameters: GetSamlConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SamlConfig>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSamlConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/saml/config/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SamlConfigFromJSON(jsonValue));
    }

    /**
     * Get a SAML configuration
     */
    async getSamlConfig(requestParameters: GetSamlConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SamlConfig> {
        const response = await this.getSamlConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a SAML domain
     */
    async getSamlDomainRaw(requestParameters: GetSamlDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SamlDomain>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling getSamlDomain.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSamlDomain.');
        }

        const queryParameters: any = {};

        if (requestParameters.forceVerify !== undefined) {
            queryParameters['force_verify'] = requestParameters.forceVerify;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/saml/config/{config-id}/domain/{id}`.replace(`{${"config-id"}}`, encodeURIComponent(String(requestParameters.configId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SamlDomainFromJSON(jsonValue));
    }

    /**
     * Get a SAML domain
     */
    async getSamlDomain(requestParameters: GetSamlDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SamlDomain> {
        const response = await this.getSamlDomainRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List domains for a SAML configuration
     */
    async getSamlDomainsRaw(requestParameters: GetSamlDomainsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SamlDomain>>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling getSamlDomains.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/saml/config/{config-id}/domains`.replace(`{${"config-id"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SamlDomainFromJSON));
    }

    /**
     * List domains for a SAML configuration
     */
    async getSamlDomains(requestParameters: GetSamlDomainsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SamlDomain>> {
        const response = await this.getSamlDomainsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check if SAML login is available for a domain
     */
    async getSamlLoginMethodRaw(requestParameters: GetSamlLoginMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSamlLoginMethod200Response>> {
        if (requestParameters.domain === null || requestParameters.domain === undefined) {
            throw new runtime.RequiredError('domain','Required parameter requestParameters.domain was null or undefined when calling getSamlLoginMethod.');
        }

        const queryParameters: any = {};

        if (requestParameters.domain !== undefined) {
            queryParameters['domain'] = requestParameters.domain;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/saml/login-method`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSamlLoginMethod200ResponseFromJSON(jsonValue));
    }

    /**
     * Check if SAML login is available for a domain
     */
    async getSamlLoginMethod(requestParameters: GetSamlLoginMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSamlLoginMethod200Response> {
        const response = await this.getSamlLoginMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSharedFolderAssignmentsRaw(requestParameters: GetSharedFolderAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSharedFolderAssignments200ResponseInner>>> {
        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling getSharedFolderAssignments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{shared_folder_id}/users`.replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSharedFolderAssignments200ResponseInnerFromJSON));
    }

    /**
     */
    async getSharedFolderAssignments(requestParameters: GetSharedFolderAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSharedFolderAssignments200ResponseInner>> {
        const response = await this.getSharedFolderAssignmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Shared Folder
     */
    async getSharedFolderAuditLogRaw(requestParameters: GetSharedFolderAuditLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSharedFolderAuditLog200Response>> {
        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling getSharedFolderAuditLog.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromVersion !== undefined) {
            queryParameters['from_version'] = requestParameters.fromVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{shared_folder_id}/audit-log`.replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSharedFolderAuditLog200ResponseFromJSON(jsonValue));
    }

    /**
     * Create Shared Folder
     */
    async getSharedFolderAuditLog(requestParameters: GetSharedFolderAuditLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSharedFolderAuditLog200Response> {
        const response = await this.getSharedFolderAuditLogRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Shared Folder Bookmark changes
     */
    async getSharedFolderBookmarkChangesRaw(requestParameters: GetSharedFolderBookmarkChangesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SharedFolderChangelog>> {
        if (requestParameters.folderId === null || requestParameters.folderId === undefined) {
            throw new runtime.RequiredError('folderId','Required parameter requestParameters.folderId was null or undefined when calling getSharedFolderBookmarkChanges.');
        }

        const queryParameters: any = {};

        if (requestParameters.currentVersion !== undefined) {
            queryParameters['current-version'] = requestParameters.currentVersion;
        }

        if (requestParameters.rawTreeHash !== undefined) {
            queryParameters['raw-tree-hash'] = requestParameters.rawTreeHash;
        }

        if (requestParameters.fixedVersion !== undefined) {
            queryParameters['fixed-version'] = requestParameters.fixedVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{folder-id}/bookmarks`.replace(`{${"folder-id"}}`, encodeURIComponent(String(requestParameters.folderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SharedFolderChangelogFromJSON(jsonValue));
    }

    /**
     * Get Shared Folder Bookmark changes
     */
    async getSharedFolderBookmarkChanges(requestParameters: GetSharedFolderBookmarkChangesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SharedFolderChangelog> {
        const response = await this.getSharedFolderBookmarkChangesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Shared Folder By ID
     */
    async getSharedFolderByIdRaw(requestParameters: GetSharedFolderByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SharedFolder>> {
        if (requestParameters.folderID === null || requestParameters.folderID === undefined) {
            throw new runtime.RequiredError('folderID','Required parameter requestParameters.folderID was null or undefined when calling getSharedFolderById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{folderID}`.replace(`{${"folderID"}}`, encodeURIComponent(String(requestParameters.folderID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SharedFolderFromJSON(jsonValue));
    }

    /**
     * Get Shared Folder By ID
     */
    async getSharedFolderById(requestParameters: GetSharedFolderByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SharedFolder> {
        const response = await this.getSharedFolderByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSharedFolderShouldDeleteLocallyRaw(requestParameters: GetSharedFolderShouldDeleteLocallyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSharedFolderShouldDeleteLocally200Response>> {
        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling getSharedFolderShouldDeleteLocally.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling getSharedFolderShouldDeleteLocally.');
        }

        const queryParameters: any = {};

        if (requestParameters.deviceId !== undefined) {
            queryParameters['device_id'] = requestParameters.deviceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{shared_folder_id}/should-delete-locally`.replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSharedFolderShouldDeleteLocally200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getSharedFolderShouldDeleteLocally(requestParameters: GetSharedFolderShouldDeleteLocallyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSharedFolderShouldDeleteLocally200Response> {
        const response = await this.getSharedFolderShouldDeleteLocallyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Authenticated User Shared Folders
     */
    async getSharedFoldersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SharedFolder>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/profile/shared-folders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SharedFolderFromJSON));
    }

    /**
     * Get Authenticated User Shared Folders
     */
    async getSharedFolders(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SharedFolder>> {
        const response = await this.getSharedFoldersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async postEncryptionChallengeRaw(requestParameters: PostEncryptionChallengeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/encryption/challenge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostEncryptionChallengeRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postEncryptionChallenge(requestParameters: PostEncryptionChallengeOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postEncryptionChallengeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postEncryptionSetupRaw(requestParameters: PostEncryptionSetupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/encryption/setup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostEncryptionSetupRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postEncryptionSetup(requestParameters: PostEncryptionSetupOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postEncryptionSetupRaw(requestParameters, initOverrides);
    }

    /**
     * Login
     */
    async postLoginRaw(requestParameters: PostLoginOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostLogin200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostLoginRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostLogin200ResponseFromJSON(jsonValue));
    }

    /**
     * Login
     */
    async postLogin(requestParameters: PostLoginOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostLogin200Response> {
        const response = await this.postLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postLoginGoogleRaw(requestParameters: PostLoginGoogleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostLogin200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/login/google`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostLoginGoogleRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostLogin200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async postLoginGoogle(requestParameters: PostLoginGoogleOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostLogin200Response> {
        const response = await this.postLoginGoogleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postLoginMicrosoftRaw(requestParameters: PostLoginMicrosoftOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostLogin200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/login/microsoft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostLoginMicrosoftRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostLogin200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async postLoginMicrosoft(requestParameters: PostLoginMicrosoftOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostLogin200Response> {
        const response = await this.postLoginMicrosoftRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Logout
     */
    async postLogoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Logout
     */
    async postLogout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postLogoutRaw(initOverrides);
    }

    /**
     */
    async postNotificationFiredRaw(requestParameters: PostNotificationFiredOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling postNotificationFired.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/notification/{notification_id}`.replace(`{${"notification_id"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostNotificationFiredRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postNotificationFired(requestParameters: PostNotificationFiredOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postNotificationFiredRaw(requestParameters, initOverrides);
    }

    /**
     * onboarding
     */
    async postOnboardingRaw(requestParameters: PostOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/onboarding`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * onboarding
     */
    async postOnboarding(requestParameters: PostOnboardingRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postOnboardingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postPaymentUrlRaw(requestParameters: PostPaymentUrlOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostPaymentUrl200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/checkout_url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostPaymentUrlRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostPaymentUrl200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async postPaymentUrl(requestParameters: PostPaymentUrlOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostPaymentUrl200Response> {
        const response = await this.postPaymentUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new SAML configuration
     */
    async postSamlConfigRaw(requestParameters: PostSamlConfigOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SamlConfig>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling postSamlConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/saml/config`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostSamlConfigRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SamlConfigFromJSON(jsonValue));
    }

    /**
     * Create a new SAML configuration
     */
    async postSamlConfig(requestParameters: PostSamlConfigOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SamlConfig> {
        const response = await this.postSamlConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add a new domain to a SAML configuration
     */
    async postSamlDomainRaw(requestParameters: PostSamlDomainOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SamlDomain>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling postSamlDomain.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling postSamlDomain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/saml/config/{config-id}/domains`.replace(`{${"config-id"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostSamlDomainRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SamlDomainFromJSON(jsonValue));
    }

    /**
     * Add a new domain to a SAML configuration
     */
    async postSamlDomain(requestParameters: PostSamlDomainOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SamlDomain> {
        const response = await this.postSamlDomainRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Shared Folder
     */
    async postSharedFolderRaw(requestParameters: PostSharedFolderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostSharedFolder200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostSharedFolderRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostSharedFolder200ResponseFromJSON(jsonValue));
    }

    /**
     * Create Shared Folder
     */
    async postSharedFolder(requestParameters: PostSharedFolderOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostSharedFolder200Response> {
        const response = await this.postSharedFolderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Shared Folder Bookmarks
     */
    async postSharedFolderBookmarksRaw(requestParameters: PostSharedFolderBookmarksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.folderId === null || requestParameters.folderId === undefined) {
            throw new runtime.RequiredError('folderId','Required parameter requestParameters.folderId was null or undefined when calling postSharedFolderBookmarks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{folder-id}/bookmarks`.replace(`{${"folder-id"}}`, encodeURIComponent(String(requestParameters.folderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostSharedFolderBookmarksRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Shared Folder Bookmarks
     */
    async postSharedFolderBookmarks(requestParameters: PostSharedFolderBookmarksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSharedFolderBookmarksRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postSharedFolderDefaultPermissionRaw(requestParameters: PostSharedFolderDefaultPermissionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.folderId === null || requestParameters.folderId === undefined) {
            throw new runtime.RequiredError('folderId','Required parameter requestParameters.folderId was null or undefined when calling postSharedFolderDefaultPermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{folder_id}/default-permission`.replace(`{${"folder_id"}}`, encodeURIComponent(String(requestParameters.folderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostSharedFolderDefaultPermissionRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postSharedFolderDefaultPermission(requestParameters: PostSharedFolderDefaultPermissionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSharedFolderDefaultPermissionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postSharedFolderUserPermissionRaw(requestParameters: PostSharedFolderUserPermissionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.folderId === null || requestParameters.folderId === undefined) {
            throw new runtime.RequiredError('folderId','Required parameter requestParameters.folderId was null or undefined when calling postSharedFolderUserPermission.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling postSharedFolderUserPermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{folder_id}/permission/{user_id}`.replace(`{${"folder_id"}}`, encodeURIComponent(String(requestParameters.folderId))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostSharedFolderUserPermissionRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postSharedFolderUserPermission(requestParameters: PostSharedFolderUserPermissionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSharedFolderUserPermissionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postUninstallSurveyRaw(requestParameters: PostUninstallSurveyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/uninstall-survey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostUninstallSurveyRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postUninstallSurvey(requestParameters: PostUninstallSurveyOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUninstallSurveyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postV2UninstallSurveyRaw(requestParameters: PostV2UninstallSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v2/uninstall-survey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostUninstallSurveyRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postV2UninstallSurvey(requestParameters: PostV2UninstallSurveyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postV2UninstallSurveyRaw(requestParameters, initOverrides);
    }

    /**
     * This Endpoint is here simply as a stub, we don\'t attempt to define the structure of the request coming from postmark in the swagger.
     */
    async postWebhookPostmarkRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/webhook/postmark`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This Endpoint is here simply as a stub, we don\'t attempt to define the structure of the request coming from postmark in the swagger.
     */
    async postWebhookPostmark(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postWebhookPostmarkRaw(initOverrides);
    }

    /**
     * This Endpoint is here simply as a stub, we don\'t attempt to define the structure of the request coming from stripe in the swagger. 
     */
    async postWebhookStripeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/webhook/stripe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This Endpoint is here simply as a stub, we don\'t attempt to define the structure of the request coming from stripe in the swagger. 
     */
    async postWebhookStripe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postWebhookStripeRaw(initOverrides);
    }

    /**
     * Update a SAML configuration
     */
    async putSamlConfigRaw(requestParameters: PutSamlConfigOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putSamlConfig.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling putSamlConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/saml/config/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutSamlConfigRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a SAML configuration
     */
    async putSamlConfig(requestParameters: PutSamlConfigOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putSamlConfigRaw(requestParameters, initOverrides);
    }

    /**
     * Update Shared Folder By ID
     */
    async putSharedFolderByIdRaw(requestParameters: PutSharedFolderByIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.folderID === null || requestParameters.folderID === undefined) {
            throw new runtime.RequiredError('folderID','Required parameter requestParameters.folderID was null or undefined when calling putSharedFolderById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{folderID}`.replace(`{${"folderID"}}`, encodeURIComponent(String(requestParameters.folderID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutSharedFolderByIdRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Shared Folder By ID
     */
    async putSharedFolderById(requestParameters: PutSharedFolderByIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putSharedFolderByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Update User By ID
     */
    async putUserByIdRaw(requestParameters: PutUserByIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userID === null || requestParameters.userID === undefined) {
            throw new runtime.RequiredError('userID','Required parameter requestParameters.userID was null or undefined when calling putUserById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user/{userID}`.replace(`{${"userID"}}`, encodeURIComponent(String(requestParameters.userID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutUserByIdRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update User By ID
     */
    async putUserById(requestParameters: PutUserByIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putUserByIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async resetPasswordRaw(requestParameters: ResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resetPassword(requestParameters: ResetPasswordOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resetPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Create Shared Folder
     */
    async restoreSharedFolderVersionRaw(requestParameters: RestoreSharedFolderVersionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling restoreSharedFolderVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{shared_folder_id}/restore`.replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RestoreSharedFolderVersionRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create Shared Folder
     */
    async restoreSharedFolderVersion(requestParameters: RestoreSharedFolderVersionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.restoreSharedFolderVersionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async signupRaw(requestParameters: SignupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostLogin200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignupRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostLogin200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async signup(requestParameters: SignupOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostLogin200Response> {
        const response = await this.signupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Shared Folder
     */
    async v3AddSharedFolderAssignmentsRaw(requestParameters: V3AddSharedFolderAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling v3AddSharedFolderAssignments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/shared-folder/{shared_folder_id}/users`.replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddSharedFolderAssignmentsRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create Shared Folder
     */
    async v3AddSharedFolderAssignments(requestParameters: V3AddSharedFolderAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v3AddSharedFolderAssignmentsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v3DeleteOrganisationMemberRaw(requestParameters: V3DeleteOrganisationMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling v3DeleteOrganisationMember.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling v3DeleteOrganisationMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/organisation/{organisation_id}/user/{user_id}`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisationId))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v3DeleteOrganisationMember(requestParameters: V3DeleteOrganisationMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v3DeleteOrganisationMemberRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v3DeleteSharedFolderAssignmentRaw(requestParameters: V3DeleteSharedFolderAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling v3DeleteSharedFolderAssignment.');
        }

        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling v3DeleteSharedFolderAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/shared-folder/{shared_folder_id}/user/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v3DeleteSharedFolderAssignment(requestParameters: V3DeleteSharedFolderAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v3DeleteSharedFolderAssignmentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v3DeviceTrackingRaw(requestParameters: V3DeviceTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling v3DeviceTracking.');
        }

        if (requestParameters.event === null || requestParameters.event === undefined) {
            throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling v3DeviceTracking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v3/device/{device_id}/{event}`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"event"}}`, encodeURIComponent(String(requestParameters.event))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v3DeviceTracking(requestParameters: V3DeviceTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v3DeviceTrackingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v3ForgotPasswordRaw(requestParameters: V3ForgotPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v3ForgotPassword(requestParameters: V3ForgotPasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v3ForgotPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Create Organisation
     */
    async v3GetOrganisationRaw(requestParameters: V3GetOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Organisation>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling v3GetOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/organisation/{organisation_id}`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationFromJSON(jsonValue));
    }

    /**
     * Create Organisation
     */
    async v3GetOrganisation(requestParameters: V3GetOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Organisation> {
        const response = await this.v3GetOrganisationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Authenticated Users Organisation
     */
    async v3GetOrganisationMembersRaw(requestParameters: V3GetOrganisationMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetOrganisationMembers200ResponseInner>>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling v3GetOrganisationMembers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/organisation/{organisation_id}/users`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrganisationMembers200ResponseInnerFromJSON));
    }

    /**
     * Get Authenticated Users Organisation
     */
    async v3GetOrganisationMembers(requestParameters: V3GetOrganisationMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetOrganisationMembers200ResponseInner>> {
        const response = await this.v3GetOrganisationMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v3GetSharedFolderAssignmentsRaw(requestParameters: V3GetSharedFolderAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSharedFolderAssignments200ResponseInner>>> {
        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling v3GetSharedFolderAssignments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/shared-folder/{shared_folder_id}/users`.replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSharedFolderAssignments200ResponseInnerFromJSON));
    }

    /**
     */
    async v3GetSharedFolderAssignments(requestParameters: V3GetSharedFolderAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSharedFolderAssignments200ResponseInner>> {
        const response = await this.v3GetSharedFolderAssignmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v3GetSharedFolderShouldDeleteLocallyRaw(requestParameters: V3GetSharedFolderShouldDeleteLocallyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSharedFolderShouldDeleteLocally200Response>> {
        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling v3GetSharedFolderShouldDeleteLocally.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling v3GetSharedFolderShouldDeleteLocally.');
        }

        const queryParameters: any = {};

        if (requestParameters.deviceId !== undefined) {
            queryParameters['device_id'] = requestParameters.deviceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/shared-folder/{shared_folder_id}/should-delete-locally`.replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSharedFolderShouldDeleteLocally200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async v3GetSharedFolderShouldDeleteLocally(requestParameters: V3GetSharedFolderShouldDeleteLocallyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSharedFolderShouldDeleteLocally200Response> {
        const response = await this.v3GetSharedFolderShouldDeleteLocallyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Shared Folder
     */
    async v3PostSharedFolderRaw(requestParameters: V3PostSharedFolderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostSharedFolder200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/shared-folder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: V3PostSharedFolderRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostSharedFolder200ResponseFromJSON(jsonValue));
    }

    /**
     * Create Shared Folder
     */
    async v3PostSharedFolder(requestParameters: V3PostSharedFolderOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostSharedFolder200Response> {
        const response = await this.v3PostSharedFolderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v3ResetPasswordRaw(requestParameters: V3ResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v3ResetPassword(requestParameters: V3ResetPasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v3ResetPasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v3SignupRaw(requestParameters: V3SignupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostLogin200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignupRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostLogin200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async v3Signup(requestParameters: V3SignupRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostLogin200Response> {
        const response = await this.v3SignupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
