export interface EncryptionKeyPair {
    aes: CryptoKey
    hmac: CryptoKey
}

export namespace encryptionKeyStorage {
    const dbName = 'EncryptionKeyDB'
    const storeName = 'keys'

    // Open or create IndexedDB database
    const openDB = async (): Promise<IDBDatabase> => {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(dbName, 1)

            request.onupgradeneeded = (event) => {
                const db = (event.target as IDBOpenDBRequest).result
                if (!db.objectStoreNames.contains(storeName)) {
                    db.createObjectStore(storeName)
                }
            }

            request.onsuccess = () => resolve(request.result)
            request.onerror = () => reject(request.error)
        })
    }

    // upsert: Store encryption keys securely in IndexedDB
    export const upsert = async (
        encryptionKeys: EncryptionKeyPair
    ): Promise<void> => {
        const db = await openDB()
        const transaction = db.transaction(storeName, 'readwrite')
        const store = transaction.objectStore(storeName)
        store.put(encryptionKeys.aes, 'aes')
        store.put(encryptionKeys.hmac, 'hmac')
    }

    // remove: Delete encryption keys from IndexedDB
    export const remove = async (): Promise<void> => {
        const db = await openDB()
        const transaction = db.transaction(storeName, 'readwrite')
        const store = transaction.objectStore(storeName)
        store.delete('aes')
        store.delete('hmac')
    }

    // get: Retrieve encryption keys from IndexedDB
    export const get = async (): Promise<EncryptionKeyPair | undefined> => {
        const db = await openDB()
        const transaction = db.transaction(storeName, 'readonly')
        const store = transaction.objectStore(storeName)

        return new Promise((resolve) => {
            const aesRequest = store.get('aes')
            const hmacRequest = store.get('hmac')

            aesRequest.onsuccess = () => {
                hmacRequest.onsuccess = () => {
                    if (aesRequest.result && hmacRequest.result) {
                        resolve({
                            aes: aesRequest.result,
                            hmac: hmacRequest.result,
                        })
                    } else {
                        resolve(undefined)
                    }
                }
            }
        })
    }

    // hasKeys: Check if encryption keys exist in IndexedDB
    export const hasKeys = async (): Promise<boolean> => {
        const db = await openDB()
        const transaction = db.transaction(storeName, 'readonly')
        const store = transaction.objectStore(storeName)

        return new Promise((resolve) => {
            const aesRequest = store.get('aes')
            const hmacRequest = store.get('hmac')

            aesRequest.onsuccess = () => {
                hmacRequest.onsuccess = () => {
                    resolve(!!aesRequest.result && !!hmacRequest.result)
                }
            }
        })
    }
}
