/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetEncryptionChallenge200Response
 */
export interface GetEncryptionChallenge200Response {
    /**
     * 
     * @type {string}
     * @memberof GetEncryptionChallenge200Response
     */
    serverSalt: string;
    /**
     * 
     * @type {string}
     * @memberof GetEncryptionChallenge200Response
     */
    challenge: string;
}

/**
 * Check if a given object implements the GetEncryptionChallenge200Response interface.
 */
export function instanceOfGetEncryptionChallenge200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "serverSalt" in value;
    isInstance = isInstance && "challenge" in value;

    return isInstance;
}

export function GetEncryptionChallenge200ResponseFromJSON(json: any): GetEncryptionChallenge200Response {
    return GetEncryptionChallenge200ResponseFromJSONTyped(json, false);
}

export function GetEncryptionChallenge200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEncryptionChallenge200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serverSalt': json['server-salt'],
        'challenge': json['challenge'],
    };
}

export function GetEncryptionChallenge200ResponseToJSON(value?: GetEncryptionChallenge200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'server-salt': value.serverSalt,
        'challenge': value.challenge,
    };
}

