import {
    GetSharedFolderAssignments200ResponseInner,
    PostSharedFolderDefaultPermissionRequestDefaultPermissionEnum as DefaultPermissionsEnum,
} from '../apiClient'
import {
    useFolderUsers,
    useCurrentUser,
    useGetSharedFolderUsers,
    useSetMenuItem,
} from './Settings'
import SpinnerSvgIcon from '../helpers/icons/SpinnerSvgIcon'
import Alert from '../helpers/customComponents/Alert'
import { AlertType, ButtonTheme } from '../helpers/constants/enum'
import Section from '../helpers/commonComponents/Section'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { ClockIcon, XMarkIcon, EnvelopeIcon } from '@heroicons/react/24/outline'
import { ONE_USER_IN_FOLDER } from '../helpers/constants/constants'
import apiHelper from '../apiClient/defaultApiClient'
import { FormEvent, useState } from 'react'
import Modal from '../helpers/commonComponents/Modal'
import { SettingsMenuItem } from '../helpers/constants/enum'
import { uiText } from '../uiText/uiText'
import {
    toastError,
    toastSuccess,
} from '../helpers/commonComponents/toastHelper'
import {
    AcceptedUsers,
    PendingUsers,
} from '../helpers/commonComponents/Members'
import { useCurrentFolder } from '../dashboard/Controller'

interface ModalProps {
    modalText: string
    modalTitle: string
    userId: number
}

const ZERO_FOLDER_USERS = 0

export default function ViewMembers() {
    const { folderUsers } = useFolderUsers()
    const { user } = useCurrentUser()
    const currentUserId = user?.id
    const navigate = useNavigate()
    const { folderId } = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { getSharedFolderUsers } = useGetSharedFolderUsers()
    const [open, setOpen] = useState<boolean>(false)
    const [modal, setModal] = useState<Partial<ModalProps>>({})
    const { setMenuItem } = useSetMenuItem()
    const folderUsersAccepted = folderUsers.filter(
        (folderUser) => !folderUser.isPending
    )
    const folderUsersPending = folderUsers.filter(
        (folderUser) => folderUser.isPending
    )

    // Check permissions, if the user doesn't have permission, then they cannot delete a member
    const { currentFolder } = useCurrentFolder()
    const getFolderCurrentUser = () => {
        return folderUsers?.find((folderUser) => folderUser.userId === user?.id)
    }
    // User permissions logic, display permissions features,
    // do not display if user does not have access or if default permissions
    // do not allow AND user has no explicit permissions setting
    const currentUserPermissions = getFolderCurrentUser()?.permission
    const isPermissionsPageDisabled =
        currentUserPermissions === DefaultPermissionsEnum.Read ||
        (!currentUserPermissions &&
            currentFolder?.defaultPermission === DefaultPermissionsEnum.Read)

    const unassignFromFolder = async (
        userId: number,
        e: FormEvent<HTMLFormElement>
    ) => {
        e.preventDefault()

        setIsLoading(true)
        await apiHelper
            .deleteSharedFolderAssignment({
                userId: userId,
                sharedFolderId: Number(folderId),
            })
            .then(() => {
                getSharedFolderUsers({ sharedFolderId: Number(folderId) })
                setIsLoading(false)
                setOpen(!open)
                toastSuccess(uiText.Notifications.success.removeUserFromFolder)
            })
            .catch((e: any) => {
                setOpen(!open)
                toastError(uiText.Notifications.error.removeUserFromFolder)
                setIsLoading(false)
            })
    }

    const renderViewMembersSection = () => {
        if (folderUsers) {
            if (folderUsers.length > ZERO_FOLDER_USERS)
                return (
                    <ul className="box-border flex w-full select-text flex-col justify-center rounded-xl">
                        {folderUsersAccepted
                            .sort((a) => (a.userId !== currentUserId ? 1 : -1))
                            .map(
                                (
                                    user: GetSharedFolderAssignments200ResponseInner
                                ) => {
                                    const currentUserIsOnlyUserInFolder =
                                        user.userId === currentUserId &&
                                        folderUsers.length ===
                                            ONE_USER_IN_FOLDER
                                    const currentUserIsNotOnlyUserInFolder =
                                        user.userId === currentUserId &&
                                        folderUsers.length > ONE_USER_IN_FOLDER
                                    const isUserCreator =
                                        user.userId === currentFolder?.createdBy

                                    return (
                                        <li
                                            key={user.userId}
                                            // show border on current user's row if there are other users in the folder
                                            className={`${
                                                currentUserIsNotOnlyUserInFolder &&
                                                'border-b border-slate-300 pb-3'
                                            } 
                    
                    ${
                        // show border on last row if there are pending users in the folder
                        folderUsersAccepted.length > ONE_USER_IN_FOLDER &&
                        folderUsersPending.length > 0 &&
                        'last:border-b last:border-slate-300 last:pb-3'
                    }
                    ${
                        currentUserIsOnlyUserInFolder
                            ? 'mt-0 justify-center'
                            : 'my-2 justify-between'
                    } 
                   flex items-center`}
                                        >
                                            <div
                                                className={`flex items-center ${
                                                    currentUserIsOnlyUserInFolder
                                                        ? 'justify-center truncate'
                                                        : 'w-[85%] justify-start '
                                                }`}
                                            >
                                                <AcceptedUsers user={user} />
                                            </div>
                                            {/* Show owner */}
                                            {isUserCreator && (
                                                <p className="italic mr-2 lg:mr-4 text-lg truncate overflow-hidden w-fit">
                                                    Owner
                                                </p>
                                            )}
                                            {/* Do not let users delete themselves or the folder owner*/}
                                            {user.userId !== currentUserId &&
                                                !isUserCreator && (
                                                    <button
                                                        className="mask-text rounded-full stroke-1 hover:bg-slate-100 focus:bg-slate-100 focus:outline-none focus:ring-1 focus:ring-slate-500 lg:ml-4"
                                                        disabled={isLoading}
                                                        onClick={() => {
                                                            setOpen(!open)
                                                            setModal({
                                                                modalText: `Are you sure you want to remove ${
                                                                    user.name ||
                                                                    user.email
                                                                } from this folder?`,
                                                                modalTitle:
                                                                    'Remove from folder',
                                                                userId: user.userId,
                                                            })
                                                        }}
                                                    >
                                                        {!isPermissionsPageDisabled && (
                                                            <XMarkIcon
                                                                className="h-8 w-8 stroke-1 md:h-10 md:w-10"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                    </button>
                                                )}
                                        </li>
                                    )
                                }
                            )}
                        {folderUsersPending.map(
                            (
                                user: GetSharedFolderAssignments200ResponseInner
                            ) => {
                                return (
                                    <li
                                        key={user.userId}
                                        className="my-1 flex w-full items-center justify-between"
                                    >
                                        <div className="flex w-[90%] items-center justify-start md:w-[95%]">
                                            <PendingUsers user={user} />
                                        </div>

                                        <button
                                            className="rounded-full stroke-1 hover:bg-slate-100 focus:bg-slate-100 focus:outline-none focus:ring-1 focus:ring-slate-500 lg:ml-4"
                                            disabled={isLoading}
                                            onClick={() => {
                                                setOpen(!open)
                                                setModal({
                                                    modalText: `Are you sure you want to remove ${user.email} from this folder?`,
                                                    modalTitle:
                                                        'Remove from folder',
                                                    userId: user.userId,
                                                })
                                            }}
                                        >
                                            <XMarkIcon
                                                className="h-8 w-8 stroke-1 md:h-10 md:w-10"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </li>
                                )
                            }
                        )}
                    </ul>
                )

            if (folderUsers.length === ZERO_FOLDER_USERS) {
                return (
                    <div className="flex w-full items-center justify-center">
                        <SpinnerSvgIcon height="40" width="40" />
                    </div>
                )
            }
        }
        if (!folderUsers)
            return (
                <div>
                    <Alert
                        showAlert
                        alertText="Oops, something went wrong! Please try again later."
                        alertType={AlertType.failure}
                    />
                </div>
            )
    }

    return (
        <>
            {folderUsers.length === ONE_USER_IN_FOLDER ? (
                <div className="mt-8 box-border w-full md:mt-0 md:w-[70%] md:px-8 lg:ml-0 lg:w-[80%] xl:px-32">
                    {renderViewMembersSection()}
                    <Section
                        emptySection={folderUsers.length === 1}
                        className="mt-22"
                    >
                        <div className="flex w-full flex-col items-center rounded">
                            <p className="mb-4 text-center text-lg font-semibold">
                                There's no one else in your folder yet!
                            </p>
                            <Button
                                buttonText="Invite"
                                buttonType={ButtonType.button}
                                disabled={false}
                                buttonTheme={ButtonTheme.primary}
                                className="w-full sm:w-auto sm:min-w-min sm:px-32"
                                onClick={() => {
                                    setMenuItem(SettingsMenuItem.invite)
                                    navigate(`/settings/${folderId}/invite`)
                                }}
                            />
                        </div>
                    </Section>
                </div>
            ) : (
                <Section
                    className="mt-8 box-border w-full md:mt-0 md:w-[80%] md:px-8 lg:ml-0 xl:px-32"
                    emptySection={folderUsers.length === ONE_USER_IN_FOLDER}
                >
                    <>{renderViewMembersSection()}</>
                </Section>
            )}
            <Modal
                modalTitle={modal?.modalTitle}
                open={open}
                setOpen={setOpen}
                modalText={modal.modalText}
                onSubmit={(e: FormEvent<HTMLFormElement>) => {
                    modal?.userId ? unassignFromFolder(modal?.userId, e) : null
                }}
                primaryButton={
                    <Button
                        buttonText="Remove from folder"
                        buttonTheme={ButtonTheme.primary}
                        buttonType={ButtonType.submit}
                        className="w-full sm:max-w-max sm:px-8"
                        disabled={isLoading}
                    />
                }
                secondaryButton={
                    <Button
                        buttonText="Cancel"
                        buttonTheme={ButtonTheme.tertiary}
                        buttonType={ButtonType.button}
                        className="mt-2 w-full sm:mr-2 sm:mt-0 sm:max-w-max sm:px-8"
                        onClick={() => {
                            setOpen(!open)
                            setModal({})
                        }}
                        disabled={isLoading}
                    />
                }
            />
        </>
    )
}
