import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Section from '../helpers/commonComponents/Section'
import { AlertType, ButtonTheme } from '../helpers/constants/enum'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import Alert from '../helpers/customComponents/Alert'
import Modal from '../helpers/commonComponents/Modal'
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useOrganisation } from '../dashboard/Controller'
import apiHelper from '../apiClient/defaultApiClient'
import { SamlConfig, SamlDomain } from '../apiClient/models'

interface EnhancedSamlConfig extends SamlConfig {
    domains?: SamlDomain[]
}

interface ModalProps {
    modalText: string
    modalTitle: string
    configId: number
}

export default function SAMLManagement() {
    const { organisation } = useOrganisation()
    const navigate = useNavigate()
    const [samlConfigs, setSamlConfigs] = useState<EnhancedSamlConfig[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [modal, setModal] = useState<Partial<ModalProps>>({})
    const [error, setError] = useState(false)

    // Fetch SAML configurations when component mounts
    useEffect(() => {
        if (organisation) {
            fetchSamlConfigs()
        }
    }, [organisation])

    const fetchSamlConfigs = async () => {
        setIsLoading(true)
        setError(false)

        try {
            // Fetch all SAML configurations
            const configs = await apiHelper.getAllSamlConfigs()

            // Create enhanced configs with domains
            const enhancedConfigs: EnhancedSamlConfig[] = []

            // Fetch domains for each configuration
            for (const config of configs) {
                try {
                    const domains = await apiHelper.getSamlDomains({
                        configId: config.id,
                    })
                    enhancedConfigs.push({
                        ...config,
                        domains: domains,
                    })
                } catch (domainErr) {
                    console.error(
                        `Error fetching domains for config ${config.id}:`,
                        domainErr
                    )
                    enhancedConfigs.push({
                        ...config,
                        domains: [],
                    })
                }
            }

            setSamlConfigs(enhancedConfigs)
        } catch (err) {
            console.error('Error fetching SAML configurations:', err)
            setError(true)
        } finally {
            setIsLoading(false)
        }
    }

    const handleEdit = (configId: number) => {
        if (organisation) {
            navigate(`/organisation/${organisation.id}/saml/${configId}`)
        }
    }

    const handleDelete = (configId: number) => {
        setOpen(true)
        setModal({
            modalTitle: 'Delete SAML Configuration',
            modalText:
                'Are you sure you want to delete this SAML configuration? This action cannot be undone.',
            configId,
        })
    }

    const confirmDelete = async () => {
        if (modal.configId) {
            setIsLoading(true)
            try {
                // Call the API to delete the configuration
                await apiHelper.deleteSamlConfig({ id: modal.configId })

                // Update the state by removing the deleted config
                setSamlConfigs(
                    samlConfigs.filter((config) => config.id !== modal.configId)
                )
                setOpen(false)
            } catch (err) {
                console.error('Error deleting SAML configuration:', err)
                setError(true)
            } finally {
                setIsLoading(false)
            }
        }
    }

    const renderSamlConfigsList = () => {
        if (isLoading) {
            return (
                <div className="py-6 text-center">
                    <p className="text-lg text-default font-light">
                        Loading SAML configurations...
                    </p>
                </div>
            )
        }

        if (error) {
            return (
                <Alert
                    showAlert
                    alertText="Failed to load SAML configurations. Please try again later."
                    alertType={AlertType.failure}
                />
            )
        }

        if (samlConfigs.length === 0) {
            return (
                <div className="py-6 text-center">
                    <>
                        <p className="text-lg font-light">
                            No SAML configurations found.
                        </p>
                        <Button
                            buttonText="Add SAML Configuration"
                            buttonType={ButtonType.button}
                            buttonTheme={ButtonTheme.primary}
                            className="mt-4 mx-auto px-8"
                            disabled={isLoading}
                            onClick={() => {
                                if (organisation) {
                                    navigate(
                                        `/organisation/${organisation.id}/saml/new`
                                    )
                                }
                            }}
                        />
                    </>
                </div>
            )
        }

        return (
            <div className="w-full">
                <div className="flex flex-col sm:flex-row justify-start sm:justify-between sm:items-center mb-4 ml-2">
                    <h3 className="text-lg font-semibold">
                        SAML Configurations
                    </h3>

                    <Button
                        buttonType={ButtonType.button}
                        buttonTheme={ButtonTheme.tertiary}
                        buttonText="Add configurations"
                        disabled={isLoading}
                        onClick={() => {
                            if (organisation) {
                                navigate(
                                    `/organisation/${organisation.id}/saml/new`
                                )
                            }
                        }}
                        className="flex items-center w-fit sm:px-2"
                        buttonIcon={
                            <PlusIcon className="h-5 w-5 mr-1 stroke-2" />
                        }
                    />
                </div>

                <div className="overflow-hidden rounded-lg border border-slate-200">
                    {samlConfigs.map((config) => (
                        <div
                            key={config.id}
                            className="border-b border-slate-200 last:border-b-0"
                        >
                            <div className="p-4 flex flex-col sm:flex-row sm:items-center justify-between">
                                <div className="flex-grow">
                                    <div className="flex items-center">
                                        <div
                                            className={`h-3 w-3 rounded-full mr-2 ${
                                                config.enabled
                                                    ? 'bg-green-500'
                                                    : 'bg-red-500'
                                            }`}
                                        ></div>
                                        <h4
                                            className="font-medium text-lg break-all w-[90%]"
                                            title={config.metadataUrl}
                                        >
                                            {config.metadataUrl &&
                                            config.metadataUrl.length > 50
                                                ? `${config.metadataUrl.substring(
                                                      0,
                                                      47
                                                  )}...`
                                                : config.metadataUrl}
                                        </h4>
                                    </div>
                                </div>
                                <div className="flex space-x-2 mt-3 sm:mt-0">
                                    <button
                                        className="p-2 rounded-full hover:bg-slate-100 text-slate-900 border border-transparent active:border-slate-700"
                                        onClick={() => handleEdit(config.id)}
                                        disabled={isLoading}
                                        title="Edit configuration"
                                    >
                                        <PencilIcon className="h-5 w-5" />
                                    </button>
                                    <button
                                        className="p-2 rounded-full hover:bg-red-100 text-red-500 border border-transparent active:border-red-500"
                                        onClick={() => handleDelete(config.id)}
                                        disabled={isLoading}
                                        title="Delete configuration"
                                    >
                                        <TrashIcon className="h-5 w-5" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <div className="box-border flex w-full flex-col items-center p-0 pt-8 lg:px-40 xl:px-80 text-lg mb-24">
            <Section
                sectionTitle={
                    samlConfigs.length === 0 ? 'SAML single sign-on' : ''
                }
                emptySection={samlConfigs.length === 0}
            >
                <>{renderSamlConfigsList()}</>
            </Section>

            <Modal
                open={open}
                setOpen={setOpen}
                modalTitle={modal.modalTitle || ''}
                modalText={modal.modalText || ''}
                primaryButton={
                    <Button
                        buttonType={ButtonType.button}
                        buttonText="Delete"
                        className="px-8"
                        buttonTheme={ButtonTheme.errorPrimary}
                        disabled={isLoading}
                        onClick={confirmDelete}
                    />
                }
                secondaryButton={
                    <Button
                        buttonType={ButtonType.button}
                        buttonText="Cancel"
                        className="px-8 sm:mr-2 mt-3 sm:mt-0"
                        buttonTheme={ButtonTheme.tertiary}
                        disabled={isLoading}
                        onClick={() => setOpen(false)}
                    />
                }
            />
        </div>
    )
}
