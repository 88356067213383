/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SharedFolderVersionChangeLogUpdatesInner
 */
export interface SharedFolderVersionChangeLogUpdatesInner {
    /**
     * 
     * @type {string}
     * @memberof SharedFolderVersionChangeLogUpdatesInner
     */
    newTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedFolderVersionChangeLogUpdatesInner
     */
    oldTitle: string;
    /**
     * 
     * @type {string}
     * @memberof SharedFolderVersionChangeLogUpdatesInner
     */
    newUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedFolderVersionChangeLogUpdatesInner
     */
    oldUrl?: string;
}

/**
 * Check if a given object implements the SharedFolderVersionChangeLogUpdatesInner interface.
 */
export function instanceOfSharedFolderVersionChangeLogUpdatesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "oldTitle" in value;

    return isInstance;
}

export function SharedFolderVersionChangeLogUpdatesInnerFromJSON(json: any): SharedFolderVersionChangeLogUpdatesInner {
    return SharedFolderVersionChangeLogUpdatesInnerFromJSONTyped(json, false);
}

export function SharedFolderVersionChangeLogUpdatesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SharedFolderVersionChangeLogUpdatesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newTitle': !exists(json, 'new_title') ? undefined : json['new_title'],
        'oldTitle': json['old_title'],
        'newUrl': !exists(json, 'new_url') ? undefined : json['new_url'],
        'oldUrl': !exists(json, 'old_url') ? undefined : json['old_url'],
    };
}

export function SharedFolderVersionChangeLogUpdatesInnerToJSON(value?: SharedFolderVersionChangeLogUpdatesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'new_title': value.newTitle,
        'old_title': value.oldTitle,
        'new_url': value.newUrl,
        'old_url': value.oldUrl,
    };
}

