/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SamlDomain
 */
export interface SamlDomain {
    /**
     * Unique identifier for the SAML domain
     * @type {number}
     * @memberof SamlDomain
     */
    id: number;
    /**
     * ID of the organisation this domain belongs to
     * @type {number}
     * @memberof SamlDomain
     */
    organisationId: number;
    /**
     * ID of the SAML configuration this domain is associated with
     * @type {number}
     * @memberof SamlDomain
     */
    samlConfigId: number;
    /**
     * Domain name for SAML authentication
     * @type {string}
     * @memberof SamlDomain
     */
    domain: string;
    /**
     * Text string used as a TXT record to verify domain ownership
     * @type {string}
     * @memberof SamlDomain
     */
    verificationCode: string;
    /**
     * Whether this domain has been verified
     * @type {boolean}
     * @memberof SamlDomain
     */
    verified: boolean;
    /**
     * Timestamp when the domain was created
     * @type {Date}
     * @memberof SamlDomain
     */
    created: Date;
    /**
     * Timestamp when the domain was last updated
     * @type {Date}
     * @memberof SamlDomain
     */
    updated: Date;
}

/**
 * Check if a given object implements the SamlDomain interface.
 */
export function instanceOfSamlDomain(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "organisationId" in value;
    isInstance = isInstance && "samlConfigId" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "verificationCode" in value;
    isInstance = isInstance && "verified" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function SamlDomainFromJSON(json: any): SamlDomain {
    return SamlDomainFromJSONTyped(json, false);
}

export function SamlDomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): SamlDomain {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'organisationId': json['organisation_id'],
        'samlConfigId': json['saml_config_id'],
        'domain': json['domain'],
        'verificationCode': json['verification_code'],
        'verified': json['verified'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
    };
}

export function SamlDomainToJSON(value?: SamlDomain | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'organisation_id': value.organisationId,
        'saml_config_id': value.samlConfigId,
        'domain': value.domain,
        'verification_code': value.verificationCode,
        'verified': value.verified,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
    };
}

