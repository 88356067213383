interface SectionProps {
    error?: string
    sectionTitle?: string
    children: JSX.Element
    disabled?: boolean
    emptySection?: boolean
    className?: string
    loading?: boolean
    hasScrollbar?: boolean
}

export default function Section(props: SectionProps) {
    const {
        error,
        sectionTitle,
        children,
        disabled,
        emptySection,
        className,
        hasScrollbar,
    } = props
    return (
        <section className={`mt-6 box-border w-full select-none ${className} `}>
            {sectionTitle && (
                <h2 className=" mb-2 text-lg font-semibold md:px-6">
                    {sectionTitle}
                </h2>
            )}
            <div
                className={`w-full rounded   ${
                    disabled && 'bg-slate-400 bg-opacity-20 p-4 shadow-none'
                }
                ${hasScrollbar ? 'md:py-2' : 'md:p-6'}
        ${
            emptySection &&
            'border border-dashed border-slate-400 p-4 shadow-none'
        }
        ${!disabled && !emptySection && 'md:shadow'}`}
                aria-disabled={disabled}
            >
                {children}
            </div>
            {error && (
                <p className="m-2 h-4 font-semibold text-red-800">
                    {error ? error : ' '}
                </p>
            )}
        </section>
    )
}
