import { EncryptionKeyPair } from './encryptionKeyPair'
import { EncryptedData } from '../../apiClient'
import { bufferToHex, hexToBuffer } from './bufferHexHelpers'

export async function deriveKeys(
    password: string,
    serverSalt: ArrayBuffer
): Promise<EncryptionKeyPair> {
    const keyMaterial = await crypto.subtle.importKey(
        'raw',
        new TextEncoder().encode(password),
        { name: 'PBKDF2' },
        false,
        ['deriveKey']
    )

    // Derive the AES-GCM encryption key (for encryption/decryption)
    const encryptionKey = await crypto.subtle.deriveKey(
        {
            name: 'PBKDF2',
            salt: serverSalt,
            iterations: 500000,
            hash: 'SHA-256',
        },
        keyMaterial,
        { name: 'AES-GCM', length: 256 },
        false,
        ['encrypt', 'decrypt']
    )

    // Derive the HMAC key (for integrity verification)
    const hmacKey = await crypto.subtle.deriveKey(
        {
            name: 'PBKDF2',
            salt: serverSalt,
            iterations: 500000, // Lower iterations for HMAC (performance)
            hash: 'SHA-256',
        },
        keyMaterial,
        { name: 'HMAC', hash: 'SHA-512', length: 512 },
        false,
        ['sign', 'verify']
    )

    return { aes: encryptionKey, hmac: hmacKey }
}

/**
 * Computes HMAC-SHA-512 for a given value using the derived HMAC key.
 * @param value - The plaintext value
 * @param hmacKey - The derived HMAC key
 * @returns The computed HMAC hash as a hex string
 */
export async function computeHmac(
    value: string,
    hmacKey: CryptoKey
): Promise<string> {
    const valueBuffer = new TextEncoder().encode(value)
    const signature = await crypto.subtle.sign('HMAC', hmacKey, valueBuffer)
    return bufferToHex(signature)
}

export async function encryptData(
    plaintext: string,
    encryptionKey: CryptoKey
): Promise<EncryptedData> {
    const iv = crypto.getRandomValues(new Uint8Array(12)) // New IV for each encryption

    // Encrypt the plaintext using AES-GCM
    const encryptedBuffer = await crypto.subtle.encrypt(
        { name: 'AES-GCM', iv },
        encryptionKey,
        new TextEncoder().encode(plaintext)
    )

    // Convert encrypted buffer into a Uint8Array
    const encryptedArray = new Uint8Array(encryptedBuffer)

    // Extract the last 16 bytes as the authentication tag
    const ciphertext = encryptedArray.slice(0, -16) // Encrypted data without tag
    const tag = encryptedArray.slice(-16) // Last 16 bytes (authentication tag)

    return {
        cipherText: bufferToHex(ciphertext),
        iv: bufferToHex(iv),
        authTag: bufferToHex(tag), // Return authentication tag separately
    }
}

export async function decryptData(
    encryptedObject: EncryptedData,
    encryptionKey: CryptoKey
): Promise<string> {
    try {
        // Convert hex values to Uint8Array
        const ciphertextBuffer = new Uint8Array(
            hexToBuffer(encryptedObject.cipherText)
        )
        const tagBuffer = new Uint8Array(hexToBuffer(encryptedObject.authTag))
        const ivBuffer = new Uint8Array(hexToBuffer(encryptedObject.iv))

        // Recombine ciphertext and authentication tag
        const fullCiphertext = new Uint8Array(
            ciphertextBuffer.length + tagBuffer.length
        )
        fullCiphertext.set(ciphertextBuffer, 0)
        fullCiphertext.set(tagBuffer, ciphertextBuffer.length)

        // Attempt AES-GCM decryption
        const decryptedBuffer = await crypto.subtle.decrypt(
            { name: 'AES-GCM', iv: ivBuffer },
            encryptionKey,
            fullCiphertext
        )

        return new TextDecoder().decode(decryptedBuffer)
    } catch (error) {
        console.warn(
            'Warning: Decryption failed! Data may be corrupted or key is incorrect.'
        )
        throw error
    }
}

/**
 * Generates a password verifier using PBKDF2.
 * This function derives a cryptographic verifier from the user's password and a server-provided salt.
 * The verifier is later used to validate the password without storing the actual password.
 *
 * @param password - The plaintext user password.
 * @param serverSalt - The server-provided salt (ArrayBuffer), ensuring unique derivation per user.
 * @returns The derived password verifier as a hexadecimal string.
 */
export async function generatePasswordVerifier(
    password: string,
    serverSalt: ArrayBuffer
): Promise<string> {
    // Convert the password string into an ArrayBuffer format
    const keyMaterial = await crypto.subtle.importKey(
        'raw', // Raw password input
        new TextEncoder().encode(password), // Encode password as UTF-8 bytes
        { name: 'PBKDF2' }, // PBKDF2 is used for key derivation
        false, // Key is not extractable
        ['deriveBits'] // This key will be used to derive raw bits
    )

    // Use PBKDF2 to derive a cryptographic key (256 bits) from the password and server salt
    const derivedBits = await crypto.subtle.deriveBits(
        {
            name: 'PBKDF2',
            salt: serverSalt, // Salt ensures unique key derivation per user
            iterations: 500000, // High iteration count for security (mitigates brute-force attacks)
            hash: 'SHA-256', // SHA-256 used for hashing
        },
        keyMaterial, // Base key derived from the password
        256 // Output length: 256 bits (32 bytes)
    )

    // Convert the derived bits into a hexadecimal string representation
    return bufferToHex(derivedBits)
}

/**
 * Validates a given password by computing its verifier and signing a server challenge.
 * This function checks whether a user-entered password is correct by:
 * 1. Recomputing the password verifier.
 * 2. Signing a server-provided challenge using the derived verifier.
 * 3. Sending the signed challenge back to the server for validation.
 *
 * @param password - The user-entered password to validate.
 * @param serverSalt - The server-provided salt used in key derivation.
 * @param challenge - A cryptographic challenge provided by the server.
 * @returns The signed challenge as a hexadecimal string (to be sent to the server for validation).
 */
export async function validatePassword(
    password: string,
    serverSalt: ArrayBuffer,
    challenge: ArrayBuffer
): Promise<string> {
    // Step 1: Compute the verifier from the password and server salt
    const computedVerifier = await generatePasswordVerifier(
        password,
        serverSalt
    )

    // Step 2: Use the computed verifier to generate an HMAC signing key
    const key = await crypto.subtle.importKey(
        'raw', // Import the derived verifier as raw key material
        hexToBuffer(computedVerifier), // Convert the verifier (hex) to an ArrayBuffer
        { name: 'HMAC', hash: 'SHA-256' }, // Use HMAC with SHA-256 for signing
        false, // Key is not extractable
        ['sign'] // Key is only used for signing operations
    )

    // Step 3: Sign the server-provided challenge using the HMAC key
    const signature = await crypto.subtle.sign('HMAC', key, challenge)

    // Step 4: Convert the signed challenge to a hex string and return it
    return bufferToHex(signature)
}
