import React, { useEffect, useRef, useState } from 'react'
import { PaymentModal } from '../helpers/commonComponents/PaymentModal'
import { ButtonTheme, StripeRedirectResponse } from '../helpers/constants/enum'
import {
    useCurrentOrganisation,
    useCurrentSharedFolder,
    useFolderUsers,
    useGetCurrentSharedFolder,
    useGetSharedFolderUsers,
} from './Settings'
import Button from '../helpers/customComponents/Button'
import Section from '../helpers/commonComponents/Section'
import apiHelper from '../apiClient/defaultApiClient'
import { ZERO_USER_TO_ADD } from '../helpers/constants/constants'
import {
    GetOrganisationMembers200ResponseInner,
    User,
    PostSharedFolderDefaultPermissionRequestDefaultPermissionEnum as DefaultPermissionsEnum,
} from '../apiClient'
import SpinnerSvgIcon from '../helpers/icons/SpinnerSvgIcon'
import InviteInput from '../helpers/commonComponents/Invite'
import { uiText } from '../uiText/uiText'
import {
    toastError,
    toastSuccess,
} from '../helpers/commonComponents/toastHelper'
// import {
//     getNewUsers,
//     isBillingAction,
//     PaymentPlans,
//     PaymentPreviewDisplay,
// } from '../helpers/commonComponents/PaymentPreviewDisplay'
import { useNavigate } from 'react-router-dom'
import { FreeTrialBanner } from '../dashboard/FreeTrialBanner'

export default function Invite() {
    const [emailAddresses, setEmailAddresses] = useState<string[] | []>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] =
        useState<boolean>(false)
    const { currentFolder } = useCurrentSharedFolder()
    const { folderUsers } = useFolderUsers()
    const { getSharedFolderUsers } = useGetSharedFolderUsers()
    // const [paymentMethod, setPaymentMethod] = useState<
    //     GetUserProfilePaymentMethods200ResponseInner[] | []
    // >([])
    const { getSharedFolder } = useGetCurrentSharedFolder()
    const { organisation } = useCurrentOrganisation()
    const [organisationMembers, setOrganisationMembers] = useState<
        GetOrganisationMembers200ResponseInner[] | []
    >([])
    const navigate = useNavigate()

    const [user, setUser] = useState<User | null>(null)

    // const [displayPlanSelector, setDisplayPlanSelector] =
    //     useState<boolean>(false)

    // const paymentPreviewForceRerenderCount = useRef<number>(0)

    useEffect(() => {
        getUser().then(async (u) => {
            setUser(u)
        })
    }, [])

    const isEnterpriseFolder = !!currentFolder?.organisationId

    // const hasPaymentMethod = isEnterpriseFolder
    //     ? true
    //     : paymentMethod.length > 0

    // const requiresPayment = isBillingAction(
    //     user?.email ?? '',
    //     emailAddresses ?? [],
    //     isEnterpriseFolder
    //         ? organisationMembers.map((m) => m.email)
    //         : folderUsers.map((m) => m.email),
    //     isEnterpriseFolder
    // )

    // const requiresPaymentMethod =
    //     requiresPayment &&
    //     !isEnterpriseFolder &&
    //     !hasPaymentMethod &&
    //     !currentFolder?.isPremium &&
    //     !user?.freeTrial

    // const hasExistingFreeTrial = user?.freeTrial !== undefined
    // const userCreatedDate = user?.created || new Date()

    // const offerFreeTrial =
    //     !hasPaymentMethod &&
    //     !organisation &&
    //     !hasExistingFreeTrial &&
    //     requiresPayment &&
    //     userCreatedDate < onboardingCreatedDate

    const getUser = async () => {
        let response = await apiHelper.getProfile().catch(async (e) => {
            throw e
        })
        return response as User
    }

    // const getPaymentMethod = async () => {
    //     setIsLoading(true)
    //     const response = await apiHelper
    //         .getUserProfilePaymentMethods()
    //         .catch(() => {})
    //     if (response) {
    //         setPaymentMethod(response)
    //         setIsLoading(false)
    //     }
    // }

    const getOrganisationUsers = async (organisationId: number) => {
        const response = await apiHelper
            .getOrganisationMembers({ organisationId: organisationId })
            .catch(() => {
                toastError(
                    uiText.Notifications.error.retrieveOrganisationMembers
                )
            })
        if (response) setOrganisationMembers(response)
    }

    useEffect(() => {
        // todo: check if the folder is in an org
        if (organisation) {
            getOrganisationUsers(organisation.id)
        }
    }, [])

    const getFormValuesFromURL = () => {
        const queryParams = new URLSearchParams(window.location.search)
        const emailAddressesFromURL = queryParams.get('emails')
        const redirectStatus = queryParams.get('redirect_status')
        // Redirect status is set by Stripe
        if (redirectStatus === 'requires_payment_method') {
            toastError(uiText.Notifications.error.addPaymentMethod)
        }
        if (emailAddressesFromURL) {
            const decodedEmailAddresses = emailAddressesFromURL
                ? JSON.parse(decodeURIComponent(emailAddressesFromURL))
                : []
            setEmailAddresses(decodedEmailAddresses)
        }
    }

    // useEffect(() => {
    //     // Handle redirect from Stripe
    //     const queryParams = new URLSearchParams(window.location.search)
    //     const redirectStatus = queryParams.get('redirect_status')

    //     if (emailAddresses.length === ZERO_USER_TO_ADD) {
    //         getFormValuesFromURL()
    //         if (
    //             redirectStatus === StripeRedirectResponse.success &&
    //             hasPaymentMethod
    //         ) {
    //             setShowAddPaymentMethodModal(true)
    //         }
    //         setIsLoading(false)
    //     }
    //     if (!hasPaymentMethod && redirectStatus === null)
    //         getPaymentMethod().then(() => {
    //             setIsLoading(false)
    //         })
    // }, [hasPaymentMethod])

    const postInvites = async () => {
        if (currentFolder) {
            setIsLoading(true)
            apiHelper
                .addSharedFolderAssignments({
                    sharedFolderId: currentFolder.id,
                    body: { emailAddresses: emailAddresses },
                })
                .then(() => {
                    Promise.all([
                        organisation && getOrganisationUsers(organisation.id),
                        getSharedFolderUsers({
                            sharedFolderId: currentFolder.id,
                        }),
                        getSharedFolder({ folderID: currentFolder.id }),
                    ]).then(() => {
                        setIsLoading(false)
                    })
                    setEmailAddresses([])
                    toastSuccess(uiText.Notifications.success.addUsersToFolder)
                })
                .catch(() => {
                    toastError(uiText.Notifications.error.addUsersToFolder)

                    setIsLoading(false)
                })
        } else {
            toastError(uiText.Notifications.error.generic)
        }
    }

    const handleInvites = async () => {
        if (emailAddresses.length === ZERO_USER_TO_ADD) {
            setError("Oops, you'll need to add email addresses to continue")
            return
        } else {
            await postInvites()
        }
    }

    const handleInviteOnChange = (_emails: string[]) => {
        setError('')
        setEmailAddresses(_emails)
    }

    // let displayPaymentPreview = true

    // if (organisation) {
    //     displayPaymentPreview = !organisation.hasForcedPlan
    // } else {
    //     displayPaymentPreview = !user?.freeTrial
    // }

    return (
        <Section className="mt-8 box-border w-full md:mt-0 md:w-[80%] md:px-8 lg:ml-0 xl:px-32">
            <>
                {isLoading ? (
                    <div className="flex w-full items-center justify-center">
                        <SpinnerSvgIcon height="40" width="40" />
                    </div>
                ) : (
                    <div className="box-border w-full">
                        <div>
                            {user?.freeTrial && <FreeTrialBanner user={user} />}
                            <p className="mb-4 text-lg font-bold ">
                                Invite by email
                            </p>

                            <InviteInput
                                isLoading={isLoading}
                                handleInviteOnChange={handleInviteOnChange}
                                emailAddresses={emailAddresses}
                            />

                            <p className="ml-2 mt-1 h-2 font-medium text-red-800">
                                {error ? error : ''}
                            </p>

                            <div className="flex flex-col md:items-end">
                                <Button
                                    disabled={isLoading}
                                    buttonText={'Add to folder'}
                                    buttonTheme={ButtonTheme.primary}
                                    className="mt-20 px-8"
                                    onClick={async () => {
                                        await handleInvites()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </>
        </Section>
    )
}
