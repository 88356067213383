/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateBookmarkEncryptedData } from './CreateBookmarkEncryptedData';
import {
    CreateBookmarkEncryptedDataFromJSON,
    CreateBookmarkEncryptedDataFromJSONTyped,
    CreateBookmarkEncryptedDataToJSON,
} from './CreateBookmarkEncryptedData';

/**
 * 
 * @export
 * @interface CreateBookmark
 */
export interface CreateBookmark {
    /**
     * 
     * @type {string}
     * @memberof CreateBookmark
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBookmark
     */
    parentId?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateBookmark
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof CreateBookmark
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBookmark
     */
    title: string;
    /**
     * 
     * @type {CreateBookmarkEncryptedData}
     * @memberof CreateBookmark
     */
    encryptedData?: CreateBookmarkEncryptedData;
}

/**
 * Check if a given object implements the CreateBookmark interface.
 */
export function instanceOfCreateBookmark(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function CreateBookmarkFromJSON(json: any): CreateBookmark {
    return CreateBookmarkFromJSONTyped(json, false);
}

export function CreateBookmarkFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBookmark {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'parentId': !exists(json, 'parent_id') ? undefined : json['parent_id'],
        'position': json['position'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'title': json['title'],
        'encryptedData': !exists(json, 'encrypted_data') ? undefined : CreateBookmarkEncryptedDataFromJSON(json['encrypted_data']),
    };
}

export function CreateBookmarkToJSON(value?: CreateBookmark | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parent_id': value.parentId,
        'position': value.position,
        'url': value.url,
        'title': value.title,
        'encrypted_data': CreateBookmarkEncryptedDataToJSON(value.encryptedData),
    };
}

