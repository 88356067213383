export enum AlertType {
    success = 'SUCCESS',
    failure = 'FAILURE',
    info = 'INFO',
    warning = 'WARNING',
}

export enum TagType {
    moved = 'MOVED',
    created = 'CREATED',
    deleted = 'DELETED',
    updated = 'UPDATED',
    generic = 'GENERIC',
    primary = 'PRIMARY',
}

export enum NotificationType {
    sharedFolderSuccess = 'SHARED_FOLDER_SUCCESS',
    stopSharingFolderSuccess = 'STOP_SHARING_FOLDER_SUCCESS',
    syncedFolderSuccess = 'SYNC_FOLDER_SUCCESS',
    resetPasswordSuccess = 'RESET_PASSWORD_SUCCESS',
    unAuthorizedProfile = 'UNAUTHORIZED_PROFILE',
}

export enum ButtonTheme {
    primary = 'PRIMARY_BTN',
    secondary = 'SECONDARY_BTN',
    tertiary = 'TERTIARY_BTN',
    errorPrimary = 'ERROR_PRIMARY_BTN',
    errorSecondary = 'ERROR_SECONDARY_BTN',
    errorTertiary = 'TERTIARY',
    sharedDebug = 'SHARED_DEBUG',
    secondaryDark = 'SECONDARY_DARK_BTN',
    disabled = 'DISABLED',
}

export enum SettingsMenuItem {
    members = 'View Members',
    invite = 'Invite',
    stopSharing = 'Stop Sharing',
    permissions = 'Permissions',
    restore = 'Restore',
    changeOwner = 'Change Owner',
    changeLog = 'Change Log',
}

export enum FolderOwnershipValues {
    organisation = 'ORGANISATION',
    personal = 'PERSONAL',
}

export enum ShareType {
    share = 'SHARE_FOLDER',
    sync = 'SYNC_FOLDER',
    link = 'LINK_SHARE',
}

export enum StripeRedirectResponse {
    success = 'succeeded',
    requiresPaymentMethod = 'requires_payment_method',
}

export enum PillType {
    enterprise = 'ENTERPRISE',
    premium = 'PREMIUM',
    free = 'FREE',
    locked = 'LOCKED',
}

export enum AccessType {
    invite = 'EXPORT_INVITE',
    link = 'EXPORT_LINK',
}
