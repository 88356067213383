import { ButtonTheme } from '../constants/enum'
import SpinnerSvgIcon from '../icons/SpinnerSvgIcon'

export enum ButtonType {
    button = 'button',
    reset = 'reset',
    submit = 'submit',
}

interface ButtonProps {
    className?: string
    buttonText: string
    buttonType?: ButtonType
    disabled?: boolean
    onClick?: () => void
    onKeyDown?: (e: any) => void
    spinnerColor?: string
    buttonIcon?: JSX.Element
    buttonTheme: ButtonTheme
    mailTo?: string
}

export default function Button(props: ButtonProps) {
    const {
        buttonText,
        className,
        buttonType,
        disabled,
        onClick,
        spinnerColor,
        buttonIcon,
        buttonTheme,
        onKeyDown,
    } = props

    return (
        <button
            type={buttonType}
            className={`flex h-12 select-none items-center justify-center rounded-xl text-lg font-bold focus:outline-none focus-visible:ring-2 ${className} ${
                disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            }
      
      ${
          buttonTheme === ButtonTheme.primary &&
          'bg-primary  text-white ring-primary-dark hover:bg-primary-hover focus:ring-primary-dark active:bg-primary-active'
      }

      ${
          buttonTheme === ButtonTheme.secondary &&
          ' bg-background text-primary ring-1 ring-primary hover:bg-primary-light hover:bg-opacity-25 hover:ring-2  focus:ring-primary-dark active:text-primary-active active:ring-2'
      }

      ${
          buttonTheme === ButtonTheme.secondaryDark &&
          'bg-background text-default border-[0.5px] font-semibold border-default  hover:border-primary hover:text-primary hover:bg-opacity-25 hover:border focus:ring-1 focus:ring-primary focus:text-primary focus:border-none  focus:outline-none  active:ring-primary active:text-primary'
      }

      ${
          buttonTheme === ButtonTheme.errorSecondary &&
          'bg-background text-red-700 ring-2 ring-red-700 hover:bg-red-50 focus:ring-red-900 active:text-red-900 active:ring-red-900'
      }
      ${
          buttonTheme === ButtonTheme.errorTertiary &&
          'bg-background text-red-700 underline hover:bg-red-50 focus:ring-red-900 active:text-red-900 active:ring-red-900'
      }

      ${
          buttonTheme === ButtonTheme.errorPrimary &&
          'bg-red-700 text-white ring-2 ring-red-700 hover:bg-red-600 focus:ring-red-900 active:bg-red-900 active:ring-red-900'
      }

      ${
          buttonTheme === ButtonTheme.tertiary &&
          'bg-none text-primary hover:bg-indigo-50 active:text-primary-dark'
      }


      ${
          buttonTheme === ButtonTheme.sharedDebug &&
          'bg-slate-50 text-slate-500 ring-2 ring-slate-500 focus:ring-slate-500 cursor-not-allowed'
      }
      ${buttonTheme === ButtonTheme.disabled && 'bg-slate-200 text-default '}

        `}
            disabled={disabled}
            onClick={onClick}
            onKeyDown={onKeyDown}
        >
            {buttonIcon && buttonIcon}
            {disabled && buttonTheme === ButtonTheme.primary && !buttonIcon && (
                <SpinnerSvgIcon
                    width="24"
                    height="24"
                    spinnerColor={spinnerColor || '#85B1F9'}
                    className="m-2 animate-spin"
                />
            )}

            {buttonText}
        </button>
    )
}
