import { FormEvent, useEffect, useState } from 'react'
import { useOrganisation, useUser } from '../dashboard/Controller'
import Section from '../helpers/commonComponents/Section'
import {
    CONTACT_PAGE,
    MAIL_TO_EMAIL,
    ONE_USER_IN_FOLDER,
    ONE_USER_IN_ORGANISATION,
} from '../helpers/constants/constants'
import { AlertType, ButtonTheme } from '../helpers/constants/enum'
import LinkCTA from '../helpers/customComponents/Link'
import apiHelper from '../apiClient/defaultApiClient'
import { GetOrganisationMembers200ResponseInner } from '../apiClient'
import { ClockIcon, XMarkIcon } from '@heroicons/react/24/outline'
import SpinnerSvgIcon from '../helpers/icons/SpinnerSvgIcon'
import Alert from '../helpers/customComponents/Alert'
import Modal from '../helpers/commonComponents/Modal'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import { useNavigate } from 'react-router-dom'
import { uiText } from '../uiText/uiText'
import {
    toastError,
    toastSuccess,
} from '../helpers/commonComponents/toastHelper'

interface ModalProps {
    modalText: string
    modalTitle: string
    userId: number
}
export default function ManageOrganisation() {
    const { organisation } = useOrganisation()
    const [organisationMembers, setOrganisationMembers] = useState<
        GetOrganisationMembers200ResponseInner[] | []
    >([])
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [modal, setModal] = useState<Partial<ModalProps>>({})
    const { user } = useUser()
    const currentUserId = user?.id
    const [error, setError] = useState(false)
    const navigate = useNavigate()

    const organisationMembersAccepted = organisationMembers?.filter(
        (member) => !member.isPending
    )
    const organisationMembersPending = organisationMembers?.filter(
        (member) => member.isPending
    )

    const unassignFromOrganisation = async (
        userId: number,
        e: FormEvent<HTMLFormElement>
    ) => {
        e.preventDefault()
        setIsLoading(true)

        if (organisation)
            await apiHelper
                .deleteOrganisationMember({
                    organisationId: organisation.id,
                    userId: userId,
                })
                .then(() => {
                    getOrganisationMembers().then(() => {
                        setIsLoading(false)
                        setOpen(!open)
                        toastSuccess(
                            uiText.Notifications.success
                                .removeUserFromOrganisation
                        )
                    })
                })
                .catch(() => {
                    setOpen(!open)
                    toastError(
                        uiText.Notifications.error.removeUserFromOrganisation
                    )
                    setIsLoading(false)
                })
    }

    const getOrganisationMembers = async () => {
        if (organisation) {
            const response = await apiHelper
                .getOrganisationMembers({
                    organisationId: organisation?.id,
                })
                .catch(() => {
                    toastError(
                        uiText.Notifications.error.retrieveOrganisationMembers
                    )
                    setError(true)
                })

            if (response) {
                setOrganisationMembers(response)
            }
        }
    }

    useEffect(() => {
        const userOrgId = user?.orgIds && user?.orgIds[0]
        if (!userOrgId) {
            navigate('/')
        }
        setIsLoading(true)
        getOrganisationMembers().then(() => {
            setIsLoading(false)
        })
    }, [])

    const renderViewMembersSection = () => {
        if (organisationMembers) {
            if (organisationMembers.length > 0)
                return (
                    <ul className="box-border flex w-full select-text flex-col justify-center rounded-xl lg:px-14">
                        {organisationMembersAccepted
                            .sort((a) => (a.userId !== currentUserId ? 1 : -1))
                            .map(
                                (
                                    user: GetOrganisationMembers200ResponseInner
                                ) => {
                                    const currentUserIsOnlyUserInFolder =
                                        user.userId === currentUserId &&
                                        organisationMembers.length ===
                                            ONE_USER_IN_ORGANISATION
                                    const currentUserIsNotOnlyUserInFolder =
                                        user.userId === currentUserId &&
                                        organisationMembers.length >
                                            ONE_USER_IN_ORGANISATION

                                    return (
                                        <li
                                            key={user.userId}
                                            // show border on current user's row if there are other users in the folder
                                            className={`flex items-center justify-center ${
                                                currentUserIsNotOnlyUserInFolder &&
                                                ' border-b border-slate-300 pb-3'
                                            } 
                    
                    ${
                        // show border on last row if there are pending users in the folder
                        organisationMembersAccepted.length >
                            ONE_USER_IN_FOLDER &&
                        organisationMembersPending.length > 0 &&
                        'last:border-b last:border-slate-300 last:pb-3'
                    }
                    ${
                        currentUserIsOnlyUserInFolder
                            ? 'mt-0 justify-center'
                            : 'my-2 justify-between'
                    }`}
                                        >
                                            <div
                                                className={`flex items-center justify-start ${
                                                    currentUserIsOnlyUserInFolder
                                                        ? 'justify-center truncate'
                                                        : 'w-[75%]'
                                                } `}
                                            >
                                                {/* avatar icon */}
                                                <div className="h-10 w-10 sm:h-12 sm:w-12">
                                                    <div className="mask-text mx-1 flex h-10 w-10 items-center justify-center rounded-full bg-default text-2xl font-semibold text-white sm:h-12 sm:w-12">
                                                        {user.name
                                                            ? user.name[0].toUpperCase()
                                                            : user.email[0].toUpperCase()}
                                                    </div>
                                                </div>

                                                <div className="w-[90%] px-3 leading-5 sm:px-6 lg:text-lg">
                                                    <p className="mask-text text-ellipses truncate font-bold leading-5">
                                                        {user.name}
                                                    </p>
                                                    <p className="mask-text text-ellipses truncate">
                                                        {user.email}
                                                    </p>
                                                </div>
                                            </div>
                                            {/* Do not allow Tina's org to delete user, current user cannot delete themselves*/}
                                            {/* Do not allow current user to delete themselves  */}
                                            {organisation?.id !== 780 &&
                                                user.userId !==
                                                    currentUserId && (
                                                    <button
                                                        className="rounded-full stroke-1 hover:bg-slate-100 focus:bg-slate-100 focus:outline-none focus:ring-1 focus:ring-slate-500 lg:ml-4"
                                                        disabled={isLoading}
                                                        onClick={() => {
                                                            setOpen(!open)
                                                            setModal({
                                                                modalText: `Are you sure you want to remove ${
                                                                    user.email
                                                                } from ${
                                                                    organisation?.name ||
                                                                    'your organisation'
                                                                }?`,
                                                                modalTitle: `Remove from ${
                                                                    organisation?.name ||
                                                                    'your organisation'
                                                                }`,
                                                                userId: user.userId,
                                                            })
                                                        }}
                                                    >
                                                        <XMarkIcon
                                                            className="h-8 w-8 stroke-1 md:h-10 md:w-10"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                )}
                                        </li>
                                    )
                                }
                            )}
                        {organisationMembersPending.map(
                            (user: GetOrganisationMembers200ResponseInner) => {
                                return (
                                    <li
                                        key={user.userId}
                                        className="my-1 flex items-center justify-between"
                                    >
                                        <div className="flex w-[75%] items-center justify-start">
                                            <div className="flex h-12 w-12 items-center justify-start rounded-full text-2xl sm:h-14 sm:w-14">
                                                <ClockIcon
                                                    aria-hidden="true"
                                                    className="h-12 w-12 sm:h-14 sm:w-14"
                                                />
                                            </div>

                                            <div className="w-full pl-1 pr-3 leading-5 sm:pr-6 sm:pl-4 lg:text-lg">
                                                <p className="truncate text-ellipsis mask-text">
                                                    {user.email}
                                                </p>
                                            </div>
                                        </div>

                                        {/* Hack for Tina; do not show delete members option for their organisation */}
                                        {/* Do not allow current user to delete themselves  */}
                                        {organisation?.id !== 780 &&
                                            user.userId !== currentUserId && (
                                                <button
                                                    className="rounded-full stroke-1 hover:bg-slate-100 focus:bg-slate-100 focus:outline-none focus:ring-1 focus:ring-slate-500 lg:ml-4"
                                                    disabled={isLoading}
                                                    onClick={() => {
                                                        setOpen(!open)
                                                        setModal({
                                                            modalText: `Are you sure you want to remove ${
                                                                user.email
                                                            } from ${
                                                                organisation?.name ||
                                                                'your organisation'
                                                            }?`,
                                                            modalTitle: `Remove from ${
                                                                organisation?.name ||
                                                                'your organisation'
                                                            }`,
                                                            userId: user.userId,
                                                        })
                                                    }}
                                                >
                                                    <XMarkIcon
                                                        className="h-8 w-8 stroke-1 md:h-10 md:w-10"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            )}
                                    </li>
                                )
                            }
                        )}
                    </ul>
                )

            if (organisationMembers.length === 0) {
                return (
                    <div className="flex w-full items-center justify-center">
                        <SpinnerSvgIcon height="40" width="40" />
                    </div>
                )
            }
        }
        if (error)
            return (
                <div>
                    <Alert
                        showAlert
                        alertText="Oops, something went wrong! Try again later."
                        alertType={AlertType.failure}
                    />
                </div>
            )
    }

    return (
        <div className="box-border flex w-full flex-col items-center p-0 pt-8 lg:px-40 xl:px-80 text-lg mb-24">
            <Section>
                <>{renderViewMembersSection()}</>
            </Section>
            <Section>
                <>
                    <h2 className="font-bold text-center sm:text-left">
                        Single sign-on
                    </h2>
                    <p className="text-center text-lg md:text-left my-4">
                        Configure SAML Single Sign-On for your organisation to
                        streamline authentication.
                    </p>
                    <div className="flex justify-end">
                        <Button
                            buttonText="Manage SAML Configuration"
                            buttonTheme={ButtonTheme.primary}
                            buttonType={ButtonType.button}
                            className="mt-4 w-full md:max-w-max md:px-8"
                            onClick={() => {
                                if (organisation) {
                                    navigate(
                                        `/organisation/${organisation.id}/saml`
                                    )
                                }
                            }}
                        />
                    </div>
                </>
            </Section>
            <Section>
                <>
                    <p className="mb-4 text-center text-lg font-bold md:text-left">
                        Need support?
                    </p>
                    <p className="text-center text-lg md:text-left">
                        Send us an email! We're always happy to help, and we
                        typically respond within 24hrs.
                    </p>
                    <div className="flex justify-end">
                        <LinkCTA
                            buttonText="Contact support"
                            buttonTheme={ButtonTheme.primary}
                            url={CONTACT_PAGE}
                            className="mt-8 w-full md:max-w-max md:px-8"
                        />
                    </div>
                </>
            </Section>
            <Modal
                modalTitle={modal?.modalTitle}
                open={open}
                setOpen={setOpen}
                modalText={modal.modalText}
                onSubmit={(e: FormEvent<HTMLFormElement>) => {
                    modal?.userId
                        ? unassignFromOrganisation(modal?.userId, e)
                        : null
                }}
                primaryButton={
                    <Button
                        buttonText="Remove"
                        buttonTheme={ButtonTheme.primary}
                        buttonType={ButtonType.submit}
                        className="w-full sm:max-w-max sm:px-8"
                        disabled={isLoading}
                    />
                }
                secondaryButton={
                    <Button
                        buttonText="Cancel"
                        buttonTheme={ButtonTheme.tertiary}
                        buttonType={ButtonType.button}
                        className="mt-2 w-full sm:mr-2 sm:mt-0 sm:max-w-max sm:px-8"
                        onClick={() => {
                            setOpen(!open)
                            setModal({})
                        }}
                        disabled={isLoading}
                    />
                }
            />{' '}
        </div>
    )
}
