import { FieldError, UseFormRegisterReturn } from 'react-hook-form'

interface InputProps {
    isLoading: boolean
    register?: UseFormRegisterReturn
    errors?: FieldError | undefined
    labelText: string | JSX.Element
    inputType: string
    errorText?: string
    className?: string
    disabled?: boolean
    containerClass?: string
}

export default function Input(props: InputProps) {
    const {
        isLoading,
        register,
        errors,
        inputType,
        labelText,
        errorText,
        className,
        disabled,
        containerClass,
    } = props

    return (
        <div className={`flex flex-col self-center ${containerClass}`}>
            <label
                className={`mb-1 text-lg font-semibold cursor-pointer`}
                htmlFor={inputType}
            >
                {labelText}
            </label>
            <input
                id={inputType}
                type={inputType}
                aria-invalid={errors ? 'true' : 'false'}
                disabled={disabled || isLoading}
                className={`h-12 rounded-t border-b-2 border-default bg-background px-2 invalid:border-red-800 invalid:text-red-800 focus-visible:border-primary focus-visible:bg-primary-light focus-visible:outline-none focus-visible:invalid:border-red-800 focus-visible:invalid:bg-red-50 ${
                    errors &&
                    'border-red-800 text-red-800 focus-visible:border-red-800 focus-visible:bg-red-50 focus-visible:outline-none'
                } ${className}`}
                {...register}
            />

            {errors ? (
                <span role="alert" className="font-semibold text-red-800">
                    {errorText}
                </span>
            ) : (
                <span role="alert" className=" text-slate-900">
                    &nbsp;
                </span>
            )}
        </div>
    )
}
