import Section from '../helpers/commonComponents/Section'
import { CONTACT_PAGE } from '../helpers/constants/constants'
import { ButtonTheme } from '../helpers/constants/enum'
import LinkCTA from '../helpers/customComponents/Link'
import { FreeTrialBanner } from '../dashboard/FreeTrialBanner'
import { useUser } from '../dashboard/Controller'

export default function Billing() {
    const { user } = useUser()

    return (
        <>
            <Section className="box-border w-full sm:w-96 md:mt-0 md:w-[80%] md:px-8 lg:ml-0 xl:px-40">
                <>
                    {user?.freeTrial && <FreeTrialBanner user={user} />}
                    <div>
                        <p className="mb-4 text-center text-lg font-bold md:text-left">
                            Have a billing request?
                        </p>
                        <p className="text-center text-lg md:text-left">
                            Send us an email; we'll get back to you within 24hrs
                            regarding any billing requests or queries. You can
                            send an email to&nbsp;
                            <span className="select-text font-semibold">
                                support@bookmarkllama.com
                            </span>
                            .
                        </p>
                    </div>

                    <div className="flex justify-end">
                        <LinkCTA
                            buttonText="Send billing request"
                            buttonTheme={ButtonTheme.primary}
                            url={CONTACT_PAGE}
                            className="mt-20 w-full md:max-w-max md:px-8"
                        />
                    </div>
                </>
            </Section>
        </>
    )
}
