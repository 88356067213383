import { useState } from 'react'
import { useForm } from 'react-hook-form'
import apiHelper from '../../../apiClient/defaultApiClient'
import { deriveKeys, validatePassword } from '../encryption'
import { hexToBuffer } from '../bufferHexHelpers'
import { encryptionKeyStorage } from '../encryptionKeyPair'
import {
    extensionState,
    getExtensionState,
    setEncryptionPasswordMessage,
} from '../../../extensionCommunication/messager'
import Modal from '../../commonComponents/Modal'
import Button from '../../customComponents/Button'
import { ButtonTheme } from '../../constants/enum'
import InputPassword from '../../../helpers/customComponents/InputPassword'
import { toastError } from '../../commonComponents/toastHelper'
import { uiText } from '../../../uiText/uiText'
import { UserProfile } from '../../../apiClient'

export const shouldRenderDecryptionModal = async (
    user: UserProfile
): Promise<boolean> => {
    const extensionState = await getExtensionState()
    if (!user.isEncrypted || !extensionState.isInstalled) {
        return false
    }

    const frontendHasEncryptionKeys = await encryptionKeyStorage.hasKeys()
    return !frontendHasEncryptionKeys || !extensionState.hasEncryptionKeys
}

const checkEncryptionPassword = async (password: string) => {
    const validationData = await apiHelper.getEncryptionChallenge()
    const signedChallenge = await validatePassword(
        password,
        hexToBuffer(validationData.serverSalt),
        hexToBuffer(validationData.challenge)
    )
    await apiHelper
        .postEncryptionChallenge({
            body: {
                challenge: validationData.challenge,
                signedChallenge: signedChallenge,
            },
        })
        .catch((e) => {
            throw e
        })
    const encryptionKeys = await deriveKeys(
        password,
        hexToBuffer(validationData.serverSalt)
    )
    await encryptionKeyStorage.upsert(encryptionKeys)
    await setEncryptionPasswordMessage(password, validationData.serverSalt)
}

interface DecryptionModalProps {
    onSuccess: () => void
}

export default function DecryptionModal(props: DecryptionModalProps) {
    const [isLoading, setIsLoading] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<{ password: string }>()

    return (
        <Modal
            open={true}
            onSubmit={handleSubmit(async (data) => {
                setIsLoading(true)
                try {
                    await checkEncryptionPassword(data.password)
                    props.onSuccess()
                } catch (e) {
                    if ((e as any).response?.status === 401) {
                        toastError(
                            uiText.Notifications.error
                                .encryptionPasswordIncorrect
                        )
                    } else {
                        toastError(uiText.Notifications.error.generic)
                        throw e
                    }
                } finally {
                    setIsLoading(false)
                }
            })}
            modalTitle={'Your organisation is using encryption'}
            modalText={`Please input your organisation's encryption password to join.`}
            children={
                <InputPassword
                    autoComplete="off"
                    labelText="Encryption password*"
                    register={{
                        ...register('password', {
                            required: true,
                        }),
                    }}
                    minLength={1}
                    showPasswordIconClass="bottom-[35%] md:bottom-[67] right-[2%] mr-2"
                    containerClass={'w-full text-left mt-8'}
                    errors={errors.password}
                    isLoading={isLoading}
                    inputType="encryption-password"
                />
            }
            primaryButton={
                <Button
                    buttonText={'Continue'}
                    buttonTheme={ButtonTheme.primary}
                    className="px-8"
                    disabled={isLoading}
                />
            }
        />
    )
}
