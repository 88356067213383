import { useEffect, useState } from 'react'
import {
    BookmarkNodeV3,
    getBookmarksV3,
} from '../extensionCommunication/messager'

import { NodeRendererProps, Tree } from 'react-arborist'
import {
    ChevronRightIcon,
    FolderIcon,
    LinkIcon,
} from '@heroicons/react/24/outline'

import { AlertType } from '../helpers/constants/enum'
import Alert from '../helpers/customComponents/Alert'
import { Tooltip } from 'react-tooltip'

function arraysAreEqual(a: BookmarkNodeV3[], b: BookmarkNodeV3[]): boolean {
    if (a.length !== b.length) return false
    for (let i = 0; i < a.length; i++) {
        if (
            a[i].id !== b[i].id ||
            a[i].name !== b[i].name ||
            a[i].url !== b[i].url
        ) {
            return false
        }

        if (a[i].children.length !== b[i].children.length) return false

        if (!arraysAreEqual(a[i].children, b[i].children)) return false
    }
    return true
}

export function BookmarkSelectTesting() {
    const [bookmarks, setBookmarks] = useState<BookmarkNodeV3[]>([])
    const [lastChangeDetected, setLastChangeDetected] = useState<string | null>(
        null
    )

    const depth = 3

    useEffect(() => {
        getBookmarksV3(depth).then((b) => setBookmarks(b))
    }, [])

    const checkForChanges = async () => {
        const current = await getBookmarksV3(depth)
        if (!arraysAreEqual(current, bookmarks)) {
            setBookmarks(current)
            setLastChangeDetected(new Date().toLocaleString())
        }
    }

    useEffect(() => {
        const intervalId = setInterval(checkForChanges, 5000)
        return () => clearInterval(intervalId)
    }, [bookmarks])

    const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set())

    const handleToggle = (node: any) => {
        setExpandedNodes((prev) => {
            const newExpandedNodes = new Set(prev)
            if (newExpandedNodes.has(node.id)) {
                newExpandedNodes.delete(node.id)
            } else {
                newExpandedNodes.add(node.id)
            }
            return newExpandedNodes
        })
    }

    function Node({ node, style }: NodeRendererProps<any>) {
        const isLevelZeroAndEmpty =
            node.level === 0 && node.data.children.length === 0
        const isURLOrLevelTwo = node.data.url || node.level === 2
        const isExpanded = expandedNodes.has(node.id)
        const isShareableFolder =
            node.level === 1 && !node.data.url && !node.data.remoteData

        const handleNodeClick = () => {
            if (isShareableFolder) {
                // handleBookmarkFolderSelect(node.data.id)
            }
        }

        const ChevronIcon = ({ rotate }: { rotate: boolean }) => (
            <button
                onClick={() => {
                    node.toggle()
                    handleToggle(node)
                }}
            >
                <ChevronRightIcon
                    className={`h-6 w-6 stroke-2 mr-4 transition-all ease-in-out duration-200 ${
                        rotate ? 'rotate-0' : 'rotate-90'
                    }`}
                />
            </button>
        )

        const Icon = () =>
            node.data.url ? (
                <LinkIcon className="h-6 w-6 stroke-1.5 mr-4" />
            ) : (
                <FolderIcon className="h-6 w-6 stroke-1.5 mr-4" />
            )

        const RadioButton = () =>
            isShareableFolder && (
                <div
                    className={`mr-10 w-5 h-5 border-2 rounded-full ${
                        node.data.id === 1
                            ? 'border-4 border-primary'
                            : 'hover:bg-indigo-50 border-slate-300'
                    }`}
                ></div>
            )

        const NodeContent = () => (
            <div
                className={`w-full flex items-center h-full ${
                    isURLOrLevelTwo ||
                    node.data.remoteData ||
                    isLevelZeroAndEmpty
                        ? 'hover:cursor-not-allowed text-slate-500'
                        : 'hover:cursor-pointer'
                }`}
            >
                <div className="flex">
                    {node.data.children.length > 0 && (
                        <ChevronIcon rotate={!isExpanded} />
                    )}
                    <Icon />
                </div>

                {isShareableFolder ? (
                    <button
                        className="h-full items-center w-full flex justify-between"
                        onClick={handleNodeClick}
                    >
                        <p className="truncate text-ellipsis w-[90%] text-left font-bold">
                            {node.data.name}
                        </p>
                        <RadioButton />
                    </button>
                ) : (
                    <div className="flex items-center w-full">
                        <p className="max-w-[90%] truncate text-ellipsis text-left">
                            {node.data.name}
                        </p>

                        {isLevelZeroAndEmpty && (
                            <a
                                className="z-40 w-6 h-6 border-2 border-primary rounded-full flex justify-center items-center text-primary font-semibold text-lg cursor-pointer ml-4"
                                data-tooltip-id="empty-high-level-folder"
                                data-tooltip-place="bottom"
                                data-tooltip-content="Built-in Bookmark Folders are not shareable"
                            >
                                ?
                                <Tooltip id="empty-high-level-folder" />
                            </a>
                        )}
                    </div>
                )}
            </div>
        )

        return (
            <div
                style={style}
                className={`flex items-center justify-between select-none border-b border-slate-300 h-full ${
                    node.data.id === 1
                        ? 'bg-indigo-100 hover:bg-indigo-100 font-semibold'
                        : 'hover:bg-indigo-50'
                }`}
            >
                {/* Shared folder tooltip */}
                {node.data.remoteData && (
                    <div
                        className="h-full w-full flex items-center hover:cursor-not-allowed transition-all ease-in-out duration-200"
                        data-tooltip-id="shared-folder"
                        data-tooltip-place="bottom"
                        data-tooltip-content="You've already shared this folder"
                    >
                        <Tooltip id="shared-folder" />
                        <NodeContent />
                    </div>
                )}

                {/* Link tooltip */}
                {node.data.url && (
                    <div
                        className="h-full w-full flex items-center hover:cursor-not-allowed transition-all ease-in-out duration-200"
                        data-tooltip-id="node-link"
                        data-tooltip-place="bottom"
                        data-tooltip-content="Links are not shareable"
                    >
                        <Tooltip id="node-link" />
                        <NodeContent />
                    </div>
                )}

                {/* Nested Folder tooltip, target folders and not urls */}
                {node.level === 2 && !node.data.url && (
                    <div
                        className="h-full w-full flex items-center hover:cursor-not-allowed transition-all ease-in-out duration-200"
                        data-tooltip-id="nested-folder"
                        data-tooltip-place="bottom"
                        data-tooltip-content="Nested folders are not shareable"
                    >
                        <Tooltip id="nested-folder" />
                        <NodeContent />
                    </div>
                )}
                {/* Remaining Node Content */}
                {!node.data.remoteData &&
                    !node.data.url &&
                    node.level !== 2 && <NodeContent />}
            </div>
        )
    }

    const renderBookmarksSection = () => {
        if (bookmarks)
            return (
                <>
                    <div className="w-4/5 mx-auto flex-col justify-center items-center mt-16">
                        <div className="flex justify-between">
                            <h2 className="text-lg ml-2 mb-2 font-semibold">
                                Folders
                            </h2>
                        </div>
                        {bookmarks && (
                            <Tree
                                data={bookmarks}
                                openByDefault={true}
                                width={'100%'}
                                height={600}
                                indent={40}
                                rowHeight={64}
                                className="text-xl w-full bookmark-scrollbar border-y border-slate-300"
                            >
                                {Node}
                            </Tree>
                        )}
                    </div>
                </>
            )
        return (
            <div>
                <Alert
                    showAlert
                    alertText="Oops, something went wrong!"
                    alertType={AlertType.failure}
                />
            </div>
        )
    }

    return (
        <div>
            <h1>Bookmark Debug Page</h1>
            <button onClick={checkForChanges}>Check Changes</button>
            {lastChangeDetected ? (
                <div>Last change detected: {lastChangeDetected}</div>
            ) : (
                <div>No changes detected yet</div>
            )}
            {renderBookmarksSection()}
        </div>
    )
}
