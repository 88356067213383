import { useNavigate } from 'react-router-dom'
import {
    CheckCircleIcon,
    QuestionMarkCircleIcon,
    LightBulbIcon,
} from '@heroicons/react/24/outline'
import LinkCTA from '../helpers/customComponents/Link'
import { ButtonTheme } from '../helpers/constants/enum'
import { handleOpenLiveChat } from '../helpers/helperFunctions'
import { XMarkIcon } from '@heroicons/react/24/solid'
import BookmarkLlamaDesktopIcon from '../helpers/customComponents/BookmarkLlamaDesktopIcon'

export default function EncryptionOrganisationScreen(props: {}) {
    const navigate = useNavigate()

    return (
        <>
            <div className="flex justify-between items-center">
                <BookmarkLlamaDesktopIcon />

                <a href="/" aria-label="Close page, go to home screen">
                    <XMarkIcon className="w-10 h-10 hover:bg-slate-100 rounded-full hover:border border-slate-700" />
                </a>
            </div>
            <h1 className="text-3xl text-center mt-10 leading-normal ">
                You've successfully added encryption to your organization.
                <br />
                <span className="font-semibold truncate text-ellipsis max-w-[300px] lg:max-w-[600px] inline-block"></span>
                <CheckCircleIcon className="w-20 mx-auto mt-0.5 text-primary stroke-[0.75]" />
            </h1>

            <div className="flex flex-col md:flex-row justify-evenly text-xl mt-2">
                <section className="flex flex-col p-0 sm:p-4 lg:p-8 md:w-1/2 lg:w-3/5 xl:w-2/5 2xl:w-1/3 lg:h-full lg:block text-center md:text-left mt-8 md:mt-0">
                    <h2 className="font-bold mb-6 flex justify-center md:justify-start">
                        What happens next?
                        <QuestionMarkCircleIcon className="ml-3 w-8 text-primary" />
                    </h2>

                    <ol className="list-decimal list-outside w-full leading-7 text-left">
                        <li className="pb-4 ml-5">
                            <LinkCTA
                                buttonText="Share your folders"
                                buttonTheme={ButtonTheme.tertiary}
                                className="text-xl w-fit px-1.5"
                                url={`/share`}
                            />
                        </li>
                        <li className="ml-5">
                            Give your invitees this password to decrypt the
                            folders.
                        </li>
                    </ol>
                    <p className="pt-8">
                        Do you have any questions? Check out our documentation
                        or contact us via
                        <button
                            className="text-primary font-bold hover:cursor-pointer hover:bg-primary-light px-1.5 rounded"
                            onClick={() => handleOpenLiveChat()}
                        >
                            our live chat
                        </button>
                        .
                    </p>
                </section>
                <section className="flex flex-col p-0 sm:p-4 lg:p-8 md:w-1/2 lg:w-3/5 xl:w-2/5 2xl:w-1/3 lg:h-full lg:block text-center md:text-left mt-8 md:mt-0 mb-20 md:mb-0">
                    <h2 className="font-bold mb-6 flex justify-center md:justify-start">
                        How does it work?{' '}
                        <LightBulbIcon className="ml-3 w-8 text-primary" />
                    </h2>

                    <p className="w-full leading-7">
                        All folders in your organization are encrypted with your
                        password. Users will be prompted to enter the password
                        when logging in or joining your organization.
                    </p>
                </section>
            </div>
        </>
    )
}
